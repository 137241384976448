import React, { useEffect, useState } from 'react';
import { FaLeaf } from 'react-icons/fa';
import { GiLeafSkeleton } from "react-icons/gi";
import ReactEcharts from 'echarts-for-react';
import { firestore } from "firebase";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import { CardBody, CardHeader, CardTitle, CardSubtitle, CardFooter } from "reactstrap";
import * as moment from 'moment'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../../../assets/css/emptyView.css';
import excelIcon from "../../../../assets/img/excelIcon.png";
import {ExportToCSV} from "../../../components/ExportCSV.js";

class IndexChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }

    componentDidMount() {
        if (this.props.polygons.length > 0) {
            let polygons = this.props.polygons;
            let fieldId = this.props.fieldId;
            let fieldUrl = this.props.fieldsUrl;
            let vm = this;
            const fetchIndexData = async () => {
                var indexJson = {};
                for (let i = 0; i < polygons.length; i++) {
                    let polygon = polygons[i];
                    indexJson[polygon.name] = {}
                    await firestore()
                        .collection(fieldUrl + "/" + fieldId + "/polygons/" + polygon.id + "/logs")
                        .where("type", "==", "phenology")
                        .where("value", "==", "emergence")
                        .orderBy("date", "desc")
                        .limit(1)
                        .get()
                        .then(async function (phenologyRef) {
                            let phenologyData = phenologyRef.docs.length > 0 ? phenologyRef.docs[0].data() : {};
                            if (phenologyData && Object.keys(phenologyData).length > 0) {
                                indexJson[polygon.name]["emergenceDate"] = phenologyData["date"].seconds;
                            } else {
                                indexJson[polygon.name]["emergenceDate"] = null;
                            }
                            await firestore()
                                .collection(fieldUrl + "/" + fieldId + "/polygons/" + polygon.id + "/index_data")
                                .where("date", ">=", vm.props.zeroDate)
                                .where("date", "<=", vm.props.endDate)
                                .where("type", "in", ["NDVI", "CCCI"])
                                .orderBy("date", "desc")
                                .get()
                                .then(function (indexReference) {
                                    indexReference.docs.map(async (item, index) => {
                                        let indexData = item.data();
                                        let type = indexData.type;
                                        // Add index type key
                                        if (!indexJson[polygon.name].hasOwnProperty(type)) {
                                            indexJson[polygon.name][type] = {}
                                        }
                                        // Adding GraphPoints
                                        if (!indexJson[polygon.name][type].hasOwnProperty("graphPoints")) {
                                            indexJson[polygon.name][type]["graphPoints"] = []
                                        }
                                        // Manage GraphPoints
                                        if (indexData.type == "NDVI") {
                                            if (indexData.source == "PLA") {
                                                indexJson[polygon.name][type]["graphPoints"][indexData['date'].seconds] = indexData.value
                                            }
                                        } else {
                                            indexJson[polygon.name][type]["graphPoints"][indexData['date'].seconds] = indexData.value
                                        }
                                    })
                                    vm.setState({ data: indexJson });
                                });
                        })
                }
            };
            fetchIndexData();
        }
    }
    getOption(index) {
        function sortFunction(a, b) {
            return a - b
        }
        var polygonsJson = this.state.data;
        var dates = [];
        var values = [];
        var labels = [];
        let polygons = this.props.polygons;

        if (polygonsJson) {
            Object.keys(polygonsJson).map((polygonName, _) => {
                let polygonJson = polygonsJson[polygonName]
                if (polygonJson) {
                    let graphPointsJson = polygonJson[index]
                    if (graphPointsJson) {
                        let graphPointsAux = graphPointsJson["graphPoints"]
                        if (graphPointsAux.length > 0) {
                            dates.push(Object.keys(graphPointsAux))
                        }
                    }
                }
            })
            dates = Array.from(new Set([].concat.apply([], dates)));
            dates = dates.sort(sortFunction);

            for (var i = 0; i < polygons.length; i++) {
                let polygonName = polygons[i].name
                let polygonJson = polygonsJson[polygonName]
                if (polygonJson) {
                    let graphPointsJson = polygonJson[index]
                    if (graphPointsJson) {
                        let graphsPointsAux = graphPointsJson["graphPoints"]
                        if (graphsPointsAux.length > 0) {
                            labels.push(index + "_" + polygonName);
                            let valuesAux = dates.map((date, _) => graphsPointsAux[date])
                            values[i] = valuesAux
                        }
                    }
                }
            }

            if (dates.length > 0 && values.length > 0) {
                dates = dates.map((dateSeconds) => moment(new Date(dateSeconds * 1000)).format("DD/MM/YYYY"));
                var series = [];
                for (var i = 0; i < polygons.length; i++) {
                    var nombre = labels[i];
                    var datos = values[i];
                    series.push({
                        name: nombre,
                        type: 'line',
                        connectNulls: true,
                        smooth: true,
                        data: datos
                    });
                }
                let vm = this;
                return {
                    tooltip: {
                        trigger: 'axis',
                        formatter: function (params, ticket, callback) {
                            var res = "";
                            for (var i = 0, l = params.length; i < l; i++) {
                                if (params[i].value) {
                                    let graphPointData = params[i].seriesName.split("_");
                                    let polygonName = graphPointData[1]
                                    let polygonEmergenceDate = vm.state.data[polygonName]["emergenceDate"]
                                    if (polygonEmergenceDate) {
                                        let date = moment(params[i].name, "DD/MM/YYYY");
                                        let emergenceDate = moment(new Date(polygonEmergenceDate * 1000));
                                        let duration = date.diff(emergenceDate, 'days');
                                        let dpe = Math.round(duration)
                                        i == 0 ? res += 'DPE ' + dpe + ' ' + params[i].seriesName + ':' + params[i].value.toFixed(2) : 
                                                res += '<br/>' + 'DPE ' + dpe + ' ' + params[i].seriesName + ':' + params[i].value.toFixed(2) 
                                    } else {
                                        i == 0 ? res += params[i].seriesName + ': ' + params[i].value.toFixed(2) :
                                                res += '<br/>' + params[i].seriesName + ': ' + params[i].value.toFixed(2)
                                    }
                                };
                            }
                            setTimeout(function () {
                                callback(ticket, res);
                            }, 100)
                            return 'Calculando...';
                        }
                    },
                    legend: {
                        data: labels
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '20%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: { show: true, title: "Guardar como imágen" },
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            restore: { show: true, title: "Reset" },
                        }
                    },
                    dataZoom: [{
                        type: 'inside',
                        start: 0,
                        end: 1000
                    }, {
                        start: 0,
                        end: 100,
                        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                        handleSize: '80%',
                        handleStyle: {
                            color: '#fff',
                            shadowBlur: 3,
                            shadowColor: 'rgba(0, 0, 0, 0.6)',
                            shadowOffsetX: 2,
                            shadowOffsetY: 2
                        }
                    }],
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: dates,
                        name: "Fecha",
                        nameLocation: "center",
                        nameTextStyle: {
                            padding: [8, 8, 8, 8]
                        }
                    },
                    toolbox: {},
                    yAxis: {
                        type: 'value'
                    },
                    series: series
                };
            } else {
                return {}
            }
        } else {
            return {}
        }
    }


    exportNDVIToCSV() {
        var ndviJSON =  []
        let optionNDVI = this.getOption("NDVI")
        optionNDVI.series.forEach( serie => {
            let serieData = serie.data;
            var serieValues = []
            for(var i=0; i < serieData.length; i++) { 
                if (serieData[i]) {
                    serieValues.push({
                        date: optionNDVI.xAxis.data[i],
                        value: serieData[i]
                    })
                }
            };
            ndviJSON[`${serie.name}`] = serieValues
        });
        ExportToCSV.export(ndviJSON, [
            {
              date: "FECHA",
              value: "NDVI",
            }
          ], ["date", "value"], "NDVI", 90)
    }

    render() {
        let optionNDVI = this.getOption("NDVI")
        let optionCCCI = this.getOption("CCCI")
        let ndviEnabled = optionNDVI !== null && Object.keys(optionNDVI).length > 0
        let ccciEnabled = optionCCCI !== null && Object.keys(optionCCCI).length > 0
        return (
            <>
                {
                    ndviEnabled || ccciEnabled ?
                        <Card className="card-chart h-100">
                            <CardHeader>
                                <CardTitle></CardTitle>
                            </CardHeader>
                            <CardBody className="overflow-auto">
                                <Tabs>
                                    <TabList>
                                        {
                                            ndviEnabled && <Tab><h6><FaLeaf /> Indice Verde</h6></Tab>
                                        }
                                        {
                                            ccciEnabled && <Tab><h6><GiLeafSkeleton /> Clorofila</h6></Tab>
                                        }
                                    </TabList>
                                    {
                                        ndviEnabled && <TabPanel>
                                            <div>
                                            <button style= {{padding: '0px', border: 'none', marginRight: '0.5rem', outline: 'none', float: 'right', zIndex: '99', position: 'relative'}} onClick={() => this.exportNDVIToCSV()}>
                                                <img src={excelIcon}/>
                                            </button>
                                            <ReactEcharts
                                                option={optionNDVI}
                                            />
                                            </div>
                                        </TabPanel>
                                    }
                                    {
                                        ccciEnabled && <TabPanel>
                                            <ReactEcharts
                                                option={optionCCCI}
                                            />
                                        </TabPanel>
                                    }
                                </Tabs>

                            </CardBody>
                        </Card>

                        :
                        <div className="content">
                                <Row>
                                    <Col sm="12" md={{ size: 6, offset: 3 }}>

                                        <Card className="card-testimonial">
                                            <CardBody>
                                                <div className="icon icon-primary">
                                                    <i className="fa fa-laptop-code" />
                                                </div>
                                                <CardTitle tag="h6">
                                                    Índices
                                                </CardTitle>
                                                <CardSubtitle className="mb-2 text-muted">No hay datos para gráficar.</CardSubtitle>
                                                <div className="card-avatar" style={{ paddingTop: '1rem' }}>
                                                    <a>
                                                        <img
                                                            alt="..."
                                                            className="img"
                                                            src={require("../../../../assets/img/oryzativa_logo.png")}
                                                        />
                                                    </a>
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </div> 

                }
            </>

        )
    }


};

export default IndexChart;