import React from "react";
import firebase, { firestore } from "firebase";
import Spinner from "reactstrap/es/Spinner";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    CardSubtitle,
    Table,
    Row,
    Col,
} from "reactstrap";
import Modal from "reactstrap/es/Modal";
import Select from "react-select";
import '../assets/css/emptyView.css';
import '../assets/css/map.css';
import { NavLink } from "react-router-dom";
import { DataField, LabAnalytics, Application, Presence, Fertilization, Irrigation, Yield, Phenology, Seeeding } from '../Models/Logs.js'
import { LogTypeFilter } from '../Models/LogTypeFilter.js';
import { Log } from '../Models/Logs.js';
import CreateLogForm from './forms/CreateLog.js';
import ApplicationForm from './forms/Forms/ApplicationForm.js';
import FieldDataForm from './forms/Forms/FieldDataForm.js';
import FertilizationForm from './forms/Forms/FertilizationForm.js';
import GenericDateAndSelectForm from './forms/Forms/GenericDateAndSelectForm.js';
import CreateAndEditSeedForm from './forms/Forms/CreateAndEditSeedForm.js';
import YieldForm from './forms/Forms/YieldForm.js';
import {ExportToCSV} from "./components/ExportCSV.js";
import NotificationAlert from 'react-notification-alert';
import excelIcon from "assets/img/excelIcon.png";

class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedField: null,
            selectedPolygon: null,
            fields: [],
            isLoading: false,
            isLoadingLogs: false,
            logs: [],
            filters: LogTypeFilter.getFilters(),
            cropDict: {},
            showCreateLog: false,
            showApplicationModal: false,
            showFieldDataModal: false,
            showFertilizationModal: false,
            showSeedFormModal: false,
            showGenericModal: false,
            showYieldModal: false,
            showDeleteLogModal: false,
            selectedLog: null,
        };
        this.closeCreateLog = this.closeCreateLog.bind(this);
        this.showCreateLog = this.showCreateLog.bind(this);
        this.onCreateSuccess = this.onCreateSuccess.bind(this);
        this.closeEditLog = this.closeEditLog.bind(this);
        this.userId = firebase.auth().currentUser.uid;
    }

    componentDidMount() {
        this.fetchFields();
        this.fetchCrops();
    }

    fetchCrops = async () => {
        let cropsUrl = "crops/"
        const cropsData = await firestore().collection(cropsUrl).get();

        let dataInfo = {};

        cropsData.docs.map((item, index) => {
            if (item.exists === true) {
                let tempData = item.data();
                dataInfo[item.id] = tempData['name'];
            }
        });

        this.setState({
            cropDict: dataInfo
        });
    };

    fetchFields = async () => {
        let fieldsInfo = [];
        let vm = this;
        vm.setState({
            isLoading: true
        })
        await firestore()
            .collection("fields")
            .where("shared", "array-contains", this.userId)
            .orderBy("name")
            .get()
            .then(async function (fieldsReference) {
                if (fieldsReference.docs.length == 0) {
                    vm.setState({
                        fields: [],
                        isLoading: false,
                        selectedField: null,
                        selectedPolygon: null
                    })
                } else {
                    fieldsReference.docs.map(async (item, index) => {
                        if (item.exists === true) {
                            let tempData = item.data();
                            if (!tempData.deleted && tempData) {
                                let fieldData = {};
                                fieldData['value'] = item.id;
                                fieldData['label'] = tempData.name;
                                let url = "fields/" + item.id + '/polygons';
                                let polygons = [];
                                await firestore()
                                    .collection(url)
                                    .orderBy("name")
                                    .get()
                                    .then(async function (polygonsReference) {
                                        polygonsReference.docs.map(async (item) => {
                                            if (item.exists === true) {
                                                let tempData = item.data();
                                                if (!tempData.deleted && tempData) {
                                                    let polygonData = {};
                                                    polygonData["value"] = item.id;
                                                    polygonData["label"] = tempData.name;
                                                    polygons.push(polygonData);
                                                }
                                            }
                                        })
                                        fieldData["polygons"] = polygons
                                        fieldsInfo.push(fieldData);
                                        vm.setState({
                                            fields: fieldsInfo,
                                            isLoading: false,
                                            selectedField: fieldsInfo[0]
                                        })
                                    })
                            }
                        }
                    });
                }
            })
            .catch((_) => {
                vm.setState({
                    fields: [],
                    isLoading: false,
                    selectedField: null
                })
            })
    }

    fetchFieldsCSV() {
        var polygonsLogsCSV = [];
        let vm = this;
        if (this.state.fields && this.state.selectedField && this.state.selectedField.value != "" ) {
            this.state.selectedField.polygons.map((polygon, index) => {
                let lastOne = index == vm.state.selectedField.polygons.length - 1
                this.fetchLogsToExcel(vm.state.selectedField.value, polygon.value, polygon.label, lastOne, polygonsLogsCSV)
            })
        }
    }

    exportAllFieldsToCSV(logsInfo) {
        let emptyLogs = Object.values(logsInfo).every( array  => array.length == 0);
        if (!emptyLogs) {
            ExportToCSV.export(logsInfo, Log.getCSVHeading(), ["id", "date", "type", "value", "text"], "all_fields_logs", 80)
        } else {
            this.notify("No hay registros para exportar");
        }
    }

    async fetchLogsToExcel(fieldId, polygonId, polygonName, lastPolygon, logsInfo) {
            let vm = this;
            let logUrl = `fields/${fieldId}/polygons/${polygonId}/logs`
            let activeFilters = vm.state.filters
                .filter((logFilter) => logFilter.active)
                .map((logFilterActive) => logFilterActive.types)
                .flat();
            if (activeFilters.length > 10) {
                await firestore()
                    .collection(logUrl)
                    .orderBy("date", "desc")
                    .where("type", "in", activeFilters.slice(0, 9))
                    .get()
                    .then(async function (logsReference) {
                        let logsInfoAux = []
                        logsInfo[`${polygonName}`] = logsInfoAux
                        vm.manageLogs(logsReference, logsInfoAux, vm)
                        await firestore()
                            .collection(logUrl)
                            .orderBy("date", "desc")
                            .where("type", "in", activeFilters.slice(9, activeFilters.length))
                            .get()
                            .then(function (logsReferenceAux) {
                                if (logsReferenceAux.docs.length == 0) {
                                    let sortedLogs = logsInfoAux.sort((a, b) => b.date - a.date);
                                    logsInfo[`${polygonName}`] = sortedLogs.map((log) => {
                                        return log.getCSVData();
                                    })
                                    if (lastPolygon) {
                                        vm.exportAllFieldsToCSV(logsInfo)
                                    }
                                } else {
                                    vm.manageLogs(logsReferenceAux, logsInfoAux, vm)
                                    let sortedLogs = logsInfoAux.sort((a, b) => b.date - a.date);
                                    logsInfo[`${polygonName}`] = sortedLogs.map((log) => {
                                        return log.getCSVData();
                                    })
                                    if (lastPolygon) {
                                        vm.exportAllFieldsToCSV(logsInfo)
                                    }
                                }
                            })
                            .catch((_) => {
                                let sortedLogs = logsInfoAux.sort((a, b) => b.date - a.date);
                                logsInfo[`${polygonName}`] = sortedLogs.map((log) => {
                                    return log.getCSVData();
                                })
                                if (lastPolygon) {
                                    vm.exportAllFieldsToCSV(logsInfo) 
                                }
                            })
                    })
                    .catch((_) => {
                        if (lastPolygon) {
                            vm.exportAllFieldsToCSV(logsInfo) 
                        }
                    })
            } else {
                if (activeFilters.length > 0) {
                    await firestore()
                        .collection(logUrl)
                        .orderBy("date", "desc")
                        .where("type", "in", activeFilters)
                        .get()
                        .then(function (logsReference) {
                            if (logsReference.docs.length == 0) {
                                if (lastPolygon) {
                                    vm.exportAllFieldsToCSV(logsInfo) 
                                }
                            } else {
                                let logsInfoAux = []
                                logsInfo[`${polygonName}`] = logsInfoAux
                                vm.manageLogs(logsReference, logsInfoAux, vm)
                                let sortedLogs = logsInfoAux.sort((a, b) => b.date - a.date);
                                logsInfo[`${polygonName}`] = sortedLogs.map((log) => {
                                    return log.getCSVData();
                                })
                                if (lastPolygon) {
                                    vm.exportAllFieldsToCSV(logsInfo) 
                                }
                            }
                        })
                        .catch((_) => {
                            if (lastPolygon) {
                                vm.exportAllFieldsToCSV(logsInfo) 
                            }
                        })
                } else {
                    await firestore()
                        .collection(logUrl)
                        .orderBy("date", "desc")
                        .get()
                        .then(function (logsReference) {
                            if (logsReference.docs.length == 0) {
                                if (lastPolygon) {
                                    vm.exportAllFieldsToCSV(logsInfo) 
                                }
                            } else {
                                let logsInfoAux = []
                                logsInfo[`${polygonName}`] = logsInfoAux
                                vm.manageLogs(logsReference, logsInfoAux, vm)
                                let sortedLogs = logsInfoAux.sort((a, b) => b.date - a.date);
                                logsInfo[`${polygonName}`] = sortedLogs.map((log) => {
                                    return log.getCSVData();
                                })
                                if (lastPolygon) {
                                    vm.exportAllFieldsToCSV(logsInfo) 
                                }
                            }
                        })
                        .catch((_) => {
                            if (lastPolygon) {
                                vm.exportAllFieldsToCSV(logsInfo) 
                            }
                        })
                }

            }
    }

    manageLogs(logsReference, logsInfo, vm) {
        logsReference.docs.map((item, _) => {
            if (item.exists === true) {
                let logData = item.data();
                if (!logData.deleted && logData) {
                    let type = logData["type"];
                    let id = item.id;
                    let date = new Date(logData["date"].seconds * 1000);
                    switch (type) {
                        case "seeding":
                            let cropName = vm.state.cropDict[logData["crop"][0]] ? vm.state.cropDict[logData["crop"][0]] : logData["crop"][0]
                            let cropVar = logData["crop"][1]
                            logsInfo.push(new Seeeding(type, date, id, cropName, cropVar))
                            break
                        case "phenology":
                            logsInfo.push(new Phenology(type, date, id, logData["value"]))
                            break
                        case "water":
                            logsInfo.push(new Irrigation(type, date, id, logData["value"], logData["obs"]))
                            break
                        case "yield":
                            var yieldValue = logData["value"] ? logData["value"] : 0
                            logsInfo.push(new Yield(type, date, id, yieldValue))
                            break
                        case "fertilization":
                            let value = logData["value"] ? logData["value"] : 0
                            let n = logData["n"] ? logData["n"] : 0
                            let p = logData["p"] ? logData["p"] : 0
                            let k = logData["k"] ? logData["k"] : 0
                            let other = logData["other"] ? logData["other"] : 0
                            let otherName = logData["other_name"]
                            let detail = logData["detail"]
                            logsInfo.push(new Fertilization(type, date, id, n, p, k, other, value, otherName, detail))
                            break
                        case "weed_presence":
                        case "disease_presence":
                        case "bug_presence":
                            let values = logData["value"];
                            logsInfo.push(new Presence(type, date, id, values))
                            break
                        case "herbicide":
                        case "insecticide":
                        case "fungicide":
                            let herb = logData["value"]
                            let trigger = logData["trigger"]
                            logsInfo.push(new Application(type, date, id, herb, trigger))
                            break
                        case "lab_analytic":
                            let data = logData["data"]
                            logsInfo.push(new LabAnalytics(type, date, id, data))
                            break
                        case "data_campo":
                            let variable = logData["variable"]
                            let valueData = logData["value"] ? logData["value"] : 0
                            let unit = logData["unit"]
                            logsInfo.push(new DataField(type, date, id, variable, valueData, unit))
                            break
                        default:
                            break;
                    }
                }
            }
        })
    }

    fetchLogs = async () => {
        if (this.state.selectedPolygon && this.state.selectedField && this.state.selectedField.value != "" && this.state.selectedPolygon.value != "") {
            this.setState({
                isLoadingLogs: true,
                logs: []
            })
            let logsInfo = [];
            let vm = this;
            let logUrl = `fields/${this.state.selectedField.value}/polygons/${this.state.selectedPolygon.value}/logs`
            let activeFilters = vm.state.filters
                .filter((logFilter) => logFilter.active)
                .map((logFilterActive) => logFilterActive.types)
                .flat();
            if (activeFilters.length > 10) {
                await firestore()
                    .collection(logUrl)
                    .orderBy("date", "desc")
                    .where("type", "in", activeFilters.slice(0, 9))
                    .get()
                    .then(async function (logsReference) {
                        vm.manageLogs(logsReference, logsInfo, vm)
                        await firestore()
                            .collection(logUrl)
                            .orderBy("date", "desc")
                            .where("type", "in", activeFilters.slice(9, activeFilters.length))
                            .get()
                            .then(function (logsReferenceAux) {
                                if (logsReferenceAux.docs.length == 0) {
                                    let sortedLogs = logsInfo.sort((a, b) => b.date - a.date);
                                    vm.setState({
                                        logs: sortedLogs,
                                        isLoadingLogs: false
                                    })
                                } else {
                                    vm.manageLogs(logsReferenceAux, logsInfo, vm)
                                    let sortedLogs = logsInfo.sort((a, b) => b.date - a.date);
                                    vm.setState({
                                        logs: sortedLogs,
                                        isLoadingLogs: false
                                    })
                                }
                            })
                            .catch((error) => {
                                let sortedLogs = logsInfo.sort((a, b) => b.date - a.date);
                                vm.setState({
                                    logs: sortedLogs,
                                    isLoadingLogs: false
                                })
                            })
                    })
                    .catch((_) => {
                        vm.setState({
                            logs: [],
                            isLoadingLogs: false
                        })
                    })
            } else {
                if (activeFilters.length > 0) {
                    await firestore()
                        .collection(logUrl)
                        .orderBy("date", "desc")
                        .where("type", "in", activeFilters)
                        .get()
                        .then(function (logsReference) {
                            if (logsReference.docs.length == 0) {
                                vm.setState({
                                    logs: [],
                                    isLoadingLogs: false
                                })
                            } else {
                                vm.manageLogs(logsReference, logsInfo, vm)
                                let sortedLogs = logsInfo.sort((a, b) => b.date - a.date);
                                vm.setState({
                                    logs: sortedLogs,
                                    isLoadingLogs: false
                                })
                            }
                        })
                        .catch((_) => {
                            vm.setState({
                                logs: [],
                                isLoadingLogs: false
                            })
                        })
                } else {
                    await firestore()
                        .collection(logUrl)
                        .orderBy("date", "desc")
                        .get()
                        .then(function (logsReference) {
                            if (logsReference.docs.length == 0) {
                                vm.setState({
                                    logs: [],
                                    isLoadingLogs: false
                                })
                            } else {
                                vm.manageLogs(logsReference, logsInfo, vm)
                                let sortedLogs = logsInfo.sort((a, b) => b.date - a.date);
                                vm.setState({
                                    logs: sortedLogs,
                                    isLoadingLogs: false
                                })
                            }
                        })
                        .catch((_) => {
                            vm.setState({
                                logs: [],
                                isLoadingLogs: false
                            })
                        })
                }

            }
        }
    }

    changeRanch = (value) => {
        this.setState({
            selectedField: value,
            selectedPolygon: value.polygons[0]
        }, () => {
            this.fetchLogs()
        })
    }

    changePolygon = (value) => {
        this.setState({
            selectedPolygon: value
        }, () => {
            this.fetchLogs()
        })
    }

    activeFilterClicked(filter) {
        filter.active = !filter.active
        this.setState({
        }, () => {
            this.fetchLogs()
        })
    }

    closeCreateLog() {
        this.setState({
            showCreateLog: false
        })
    }

    showCreateLog() {
        this.setState({
            showCreateLog: true
        })
    }

    onCreateSuccess() {
        this.setState({
            showCreateLog: false
        }, () => {
            this.fetchLogs()
        })
    }

    onDeleteLog = (log) => {
        this.setState({
            selectedLog: log,
            showDeleteLogModal: true
        })
    }

    onEditLog = (log) => {
        switch (log.type) {
            case "herbicide":
            case "insecticide":
            case "fungicide":
                this.setState({
                    showApplicationModal: true,
                    showFieldDataModal: false,
                    showFertilizationModal: false,
                    showSeedFormModal: false,
                    showGenericModal: false,
                    showYieldModal: false,
                    selectedLog: log
                })
                return
            case "weed_presence":
            case "disease_presence":
            case "bug_presence":
            case "data_campo":
                this.setState({
                    showApplicationModal: false,
                    showFieldDataModal: true,
                    showFertilizationModal: false,
                    showSeedFormModal: false,
                    showGenericModal: false,
                    showYieldModal: false,
                    selectedLog: log
                })
                return
            case "fertilization":
                this.setState({
                    showApplicationModal: false,
                    showFieldDataModal: false,
                    showFertilizationModal: true,
                    showSeedFormModal: false,
                    showGenericModal: false,
                    showYieldModal: false,
                    selectedLog: log
                })
                return
            case "water":
            case "phenology":
            case "lab_analytic":
                this.setState({
                    showApplicationModal: false,
                    showFieldDataModal: false,
                    showFertilizationModal: false,
                    showSeedFormModal: false,
                    showGenericModal: true,
                    showYieldModal: false,
                    selectedLog: log
                })
                return
            case "seeding":
                this.setState({
                    showApplicationModal: false,
                    showFieldDataModal: false,
                    showFertilizationModal: false,
                    showSeedFormModal: true,
                    showGenericModal: false,
                    showYieldModal: false,
                    selectedLog: log
                })
                return
            case "yield":
                this.setState({
                    showApplicationModal: false,
                    showFieldDataModal: false,
                    showFertilizationModal: false,
                    showSeedFormModal: false,
                    showGenericModal: false,
                    showYieldModal: true,
                    selectedLog: log
                })
                return
            default:
                break;
        }
    }

    closeEditLog(success) {
        if (this.state.selectedLog) {
            this.setState({
                showApplicationModal: false,
                showFieldDataModal: false,
                showFertilizationModal: false,
                showSeedFormModal: false,
                showGenericModal: false,
                selectedLog: null
            }, () => {
                if (success) {
                    this.fetchLogs()
                }
            })
            return
        }
    }

    deleteSelectedLog() {
        if (this.state.selectedLog) {
            this.setState({
                selectedLog: null,
                showDeleteLogModal: false
            })
            let vm = this;
            let logUrl = `fields/${this.state.selectedField.value}/polygons/${this.state.selectedPolygon.value}/logs`
            firestore()
                .collection(logUrl)
                .doc(this.state.selectedLog.id)
                .set({ "deleted": true }, { merge: true })
                .then(function () {
                    vm.fetchLogs()
                })
        }
    }

    exportFieldToCSV() {
        if (this.state.logs.length == 0 ) {
            this.notify("No hay registros para exportar");
        } else {
            var logsArray = [];
            this.state.logs.map((log) => {
                logsArray.push(log.getCSVData());
            });
            var logsJSON =  []
            let polygonName = `${this.state.selectedPolygon.label}`
            logsJSON[polygonName] = logsArray
            ExportToCSV.export(logsJSON, Log.getCSVHeading(), ["id", "date", "type", "value", "text"], polygonName, 80)
        }
    }

    notify = (msg) => {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div style={{ zIndex: 10001 }}>
              <div>{msg}</div>
            </div>
          ),
          type: "success",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 4,
        };
        this.refs.notify.notificationAlert(options);
      };

    render() {

        const logDeleteModal = (
            <Modal
                isOpen={this.state.showDeleteLogModal}
            >
                <div className="modal-header justify-content-center">
                    <h5 className="title title-up">Desea eliminar el registro?</h5>
                </div>
                <div className="modal-footer">
                    <div className="left-side">
                        <Button
                            className={"btn-link"}
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showDeleteLogModal: false,
                                    selectedLog: null,
                                })
                            }}
                        >
                            No
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                            color="primary"
                            className={"btn-link"}
                            type="button"
                            onClick={() => { this.deleteSelectedLog() }}
                        >
                            Si
                        </Button>
                    </div>
                </div>
            </Modal>
        );

        return (
            <>
                <div className="content">
                    {
                        this.state.isLoading ?
                            <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                                <Spinner style={{ marginTop: '100px' }} animation="border" variant={"primary"}>
                                </Spinner>
                            </div> : this.state.fields.length == 0 ? <div className="content">
                                <Row>
                                    <Col sm="12" md={{ size: 6, offset: 3 }}>

                                        <Card className="card-testimonial">
                                            <CardBody>
                                                <div className="icon icon-primary">
                                                    <i className="fa fa-laptop-code" />
                                                </div>
                                                <CardTitle tag="h6">
                                                    Oryzativa
                                                </CardTitle>
                                                <CardSubtitle className="mb-2 text-muted">Aún no tiene chacras para ingresar eventos.</CardSubtitle>
                                                <div className="card-avatar" style={{ paddingTop: '1rem' }}>
                                                    <a>
                                                        <img
                                                            alt="..."
                                                            className="img"
                                                            src={require("assets/img/oryzativa_logo.png")}
                                                        />
                                                    </a>
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div className="empty-view-footer">
                                                    <Button
                                                        className={"btn-link"}
                                                        color="success"
                                                        tag={NavLink} to="/admin/cropmap/add-field"
                                                    >
                                                        Añadir chacra
                                                    </Button>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </div> :
                                <div>
                                    <Row>
                                        <Card className="card-stats" style={{ width: '100%' }}>
                                            <CardBody>
                                                <Col >
                                                    <Row style={{ alignItems: 'center', marginBottom: '8px' }}>
                                                        <div style={{ width: '85px' }}>
                                                            <h6>Chacra</h6>
                                                        </div>
                                                        <div style={{ width: '25%', paddingLeft: '1rem' }}>
                                                            <Select
                                                                name="ranch"
                                                                className="react-select"
                                                                placeholder="Seleccione una chacra"
                                                                classNamePrefix="react-select"
                                                                value={this.state.selectedField}
                                                                onChange={(value) => this.changeRanch(value)}
                                                                options={this.state.fields}
                                                            />
                                                        </div>
                                                        <button style= {{padding: '0px', border: 'none', marginLeft: '0.5rem', outline: 'none'}} onClick={() => this.fetchFieldsCSV()}>
                                                            <img src={excelIcon}/>
                                                        </button>
                                                    </Row>
                                                    <Row style={{ alignItems: 'center' }}>
                                                        <div style={{ width: '85px' }}>
                                                            <h6>Poligono</h6>
                                                        </div>
                                                        <div style={{ width: '25%', paddingLeft: '1rem' }}>
                                                            {
                                                                <Select
                                                                    name="poligon"
                                                                    className="react-select"
                                                                    placeholder="Seleccione un poligono"
                                                                    classNamePrefix="react-select"
                                                                    value={this.state.selectedPolygon}
                                                                    onChange={(value) => this.changePolygon(value)}
                                                                    options={this.state.selectedField.polygons}
                                                                />
                                                            }
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <div>
                                                    {
                                                        this.state.filters.slice(0, 4).map((filter, index) => {
                                                            return <button className={filter.active ? "filterEnabled" : "filterDisabled"} onClick={() => this.activeFilterClicked(filter)}>{filter.active ? "✓" : "✖"} {filter.label}</button>;
                                                        })
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        this.state.filters.slice(4, 8).map((filter, index) => {
                                                            return <button className={filter.active ? "filterEnabled" : "filterDisabled"} onClick={() => this.activeFilterClicked(filter)}>{filter.active ? "✓" : "✖"} {filter.label}</button>;
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.isLoadingLogs ? <div style={{ width: '100%', textAlign: 'center' }}>
                                                        <Spinner style={{ marginTop: '100px' }} animation="border" variant={"primary"}>
                                                        </Spinner>
                                                    </div> :
                                                        <div style={{ height: '600px', overflow: 'auto!important' }}>
                                                            <Table borderless responsive style={{ paddingBottom: '10px', overflow: 'auto!important', maxHeight: '570px !important', height: '570px !important' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ fontSize: '1.1rem' }}>
                                                                            Registros
                                                                            <Button
                                                                                style = {{marginLeft: '0.5rem'}}
                                                                                className="btn-icon"
                                                                                color="success"
                                                                                size="sm"
                                                                                title={"Edit"}
                                                                                type="button"
                                                                                onClick={() => { this.showCreateLog() }}
                                                                            >
                                                                                <span className="btn-label">
                                                                                    <i className="fa fa-plus" />
                                                                                </span>
                                                                            </Button>
                                                                            <button style= {{padding: '0px', border: 'none', marginLeft: '0.5rem', outline: 'none'}} onClick={() => this.exportFieldToCSV()}>
                                                                                <img src={excelIcon}/>
                                                                            </button>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    this.state.logs.length == 0 ? <div className="content">
                                                                        <Row>
                                                                            <Col sm={12} md={{ size: 6, offset: 3 }} lg={8} >
                                                                                <Card className="card-testimonial">
                                                                                    <CardBody>
                                                                                        <div className="icon icon-primary">
                                                                                            <i className="fa fa-laptop-code" />
                                                                                        </div>
                                                                                        <CardTitle tag="h6">
                                                                                            Registros
                                                                                        </CardTitle>
                                                                                        <CardSubtitle className="mb-2 text-muted">No hay eventos para este polígono.</CardSubtitle>
                                                                                        <div className="card-avatar" style={{ paddingTop: '1rem' }}>
                                                                                            <a>
                                                                                                <img
                                                                                                    alt="..."
                                                                                                    className="img"
                                                                                                    src={require("assets/img/oryzativa_logo.png")}
                                                                                                />
                                                                                            </a>
                                                                                        </div>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    </div> : <tbody>
                                                                        {
                                                                            this.state.logs && this.state.logs.map((log, logIndex) => {
                                                                                return <tr>
                                                                                    <td> {log.render()} </td>
                                                                                    <td className="text-center">
                                                                                        <Button
                                                                                            className="btn-icon"
                                                                                            color="success"
                                                                                            size="sm"
                                                                                            title={"Edit"}
                                                                                            type="button"
                                                                                            onClick={() => this.onEditLog(log)}

                                                                                        >
                                                                                            <i className="fa fa-edit" />
                                                                                        </Button>
                                                                                        <Button
                                                                                            className="btn-icon"
                                                                                            color="danger"
                                                                                            size="sm"
                                                                                            title={"Delete"}
                                                                                            type="button"
                                                                                            onClick={() => this.onDeleteLog(log)}
                                                                                        >
                                                                                            <i className="fa fa-trash-o" />
                                                                                        </Button>{" "}
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                }

                                                            </Table>
                                                        </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Row>
                                </div>
                    }
                </div>
                <NotificationAlert ref="notify" />
                {
                    this.state.selectedField && this.state.selectedField.polygons && <CreateLogForm
                        isOpen={this.state.showCreateLog}
                        onClose={this.closeCreateLog}
                        onCreateSuccess={this.onCreateSuccess}
                        polygons={this.state.selectedField.polygons}
                        fieldId={this.state.selectedField.value}
                    >
                    </CreateLogForm>
                }
                {

                    this.state.selectedField && this.state.selectedLog && this.state.selectedPolygon && <ApplicationForm
                        isOpen={this.state.showApplicationModal}
                        selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
                        fieldId={this.state.selectedField.value}
                        onClose={this.closeEditLog}
                        selectedLog={this.state.selectedLog}
                    >
                    </ApplicationForm>
                }
                {
                    this.state.selectedField && this.state.selectedLog && this.state.selectedPolygon && <FieldDataForm
                        isOpen={this.state.showFieldDataModal}
                        selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
                        fieldId={this.state.selectedField.value}
                        onClose={this.closeEditLog}
                        selectedLog={this.state.selectedLog}
                    >
                    </FieldDataForm>
                }
                {
                    this.state.selectedField && this.state.selectedLog && this.state.selectedPolygon && <FertilizationForm
                        isOpen={this.state.showFertilizationModal}
                        selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
                        fieldId={this.state.selectedField.value}
                        onClose={this.closeEditLog}
                        selectedLog={this.state.selectedLog}
                    >
                    </FertilizationForm>
                }
                {
                    this.state.selectedField && this.state.selectedLog && this.state.selectedPolygon && <GenericDateAndSelectForm
                        isOpen={this.state.showGenericModal}
                        selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
                        fieldId={this.state.selectedField.value}
                        onClose={this.closeEditLog}
                        selectedLog={this.state.selectedLog}
                    >
                    </GenericDateAndSelectForm>
                }
                {
                    this.state.selectedField && this.state.selectedLog && this.state.selectedPolygon && <CreateAndEditSeedForm
                        isOpen={this.state.showSeedFormModal}
                        selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
                        fieldId={this.state.selectedField.value}
                        onClose={this.closeEditLog}
                        selectedLog={this.state.selectedLog}
                    >
                    </CreateAndEditSeedForm>
                }
                {
                    this.state.selectedField && this.state.selectedLog && this.state.selectedPolygon && <YieldForm
                        isOpen={this.state.showYieldModal}
                        selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
                        fieldId={this.state.selectedField.value}
                        onClose={this.closeEditLog}
                        selectedLog={this.state.selectedLog}
                    >
                    </YieldForm>
                }
                {
                    this.state.selectedField && this.state.selectedLog && this.state.selectedPolygon && logDeleteModal
                }
            </>
        );
    }
}
export default Logs;