import L from 'leaflet';

export const FieldLocationIcon = L.icon({
    iconUrl: require('../../assets/img/venue_location_icon.svg'),
    iconRetinaUrl: require('../../assets/img/venue_location_icon.svg'),
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [35, 35],
    className: 'leaflet-venue-icon'
});
