/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import NotificationAlert from "react-notification-alert";

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {
  FirebaseAuthProvider,
} from "@react-firebase/auth";
import { FirestoreProvider } from "@react-firebase/firestore";
import { config } from "../../config";

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Modal,
  Col,
  Row,
} from "reactstrap";


function getErrorMsg(msg){
  if(msg.includes("password is invalid")){return "Contraseña incorrecta";}
  if(msg.includes("no user record")) {return "Email no registrado. Aún no está en oryzativa?";}
  if(msg.includes("try again later")){return "Ha fallado muchas veces, intente en unos minutos.";}
  return msg;
}
class Login extends React.Component {
constructor(props) {
    super(props);
    this.state = {
      email: "",
      pwd: "",
      resetEmail: "",
      
      alert: null,
      password_modal: false,
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }
     checkClick = (firebase) => {
    var register=true;
    
    if (register){
      firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.pwd).then(() => {
        this.props.history.push('/admin/dashboard')
      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        this.createError(error);
      });
    }
  };

  change = (event, stateName) => {
    this.setState({ [stateName]: event.target.value });
  };


  askReset = () => {
    this.setState({
      password_modal: !this.state.password_modal,
    });
  };

  sendReset = () => {
    firebase.auth().sendPasswordResetEmail(this.state.resetEmail).then(() =>{
      this.createSuccess("Siga las instrucciones enviadas a "+this.state.resetEmail)
    }).catch((error) =>{
      // An error happened.
      this.createError(error);
    });
  };


  
  createError = (error) => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title=""
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          {getErrorMsg(error.message)}
        </ReactBSAlert>
      ),
    });
    setTimeout(this.hideAlert, 4000);
  };

  createSuccess = (msg) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title=""
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          {msg}
        </ReactBSAlert>
      ),
    });
    setTimeout(this.hideAlert, 4000);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {
    return (
      <FirestoreProvider {...config} firebase={firebase}>
      <FirebaseAuthProvider {...config} firebase={firebase}>
      <div className="login-page">
        <Container>
        {this.state.alert}
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" type="email" onChange={(e) =>
                          this.change(e, "email")
                        }/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        onChange={(e) =>
                          this.change(e, "pwd")
                        }
                      />
                      
                    </InputGroup>
                      <label>
                      <a href="#" onClick={(e) => this.askReset()}>Ha olvidado su contraseña?</a>
                      </label>
                      {/* Classic Modal */}
                      <Modal
                        isOpen={this.state.password_modal}
                        toggle={this.askReset}
                      >
                        <div className="modal-header justify-content-center">
                          <button
                            aria-label="Cancelar"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.askReset}
                          >
                            <i className="nc-icon nc-simple-remove" />
                          </button>
                          <h4 className="title title-up">Reestablecer contraseña</h4>
                        </div>
                        <div className="modal-body">
                          <p>
                            Ingrese su dirección de correo, recibirá un correo con instrucciones para reestablecer su contraseña.
                          </p>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Email" type="email" onChange={(e) =>
                                this.change(e, "resetEmail")
                              }/>
                          </InputGroup>
                        </div>
                        <div className="modal-footer">
                          <div className="left-side">
                            <Button
                              className="btn-link"
                              color="default"
                              data-dismiss="modal"
                              type="button"
                              onClick={this.askReset}
                            >
                              Cancelar
                            </Button>
                          </div>
                          <div className="divider" />
                          <div className="right-side">
                            <Button
                              className="btn-link"
                              color="info"
                              type="button"
                              onClick={() => {this.sendReset(); this.askReset();}}
                            >
                              Reestablecer contraseña
                            </Button>
                          </div>
                        </div>
                      </Modal>
                      {/* End Modal */}
                    
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      href="#pablo"
                      onClick={() => {this.checkClick(firebase)}}
                    >
                      Ingresar
                    </Button>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="info"
                      href="/auth/register">
                      Crear cuenta
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/arroz.jpg")})`,
          }}
        />
      </div>
    </FirebaseAuthProvider>
    </FirestoreProvider>
    );
  }
}

export default Login;
