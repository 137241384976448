import React from 'react';
import { FeatureGroup, Map, Marker, WMSTileLayer } from "react-leaflet";
import SubPolygon from "../../../components/Maps/subPolygon";
import { getCropIcon } from "../../../components/Maps/mapIcons";
import L from "leaflet";

class DetailMap extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
    }

    getPolygons = (data) => {

        const { coords } = data;
        let result = [];
        for (let i = 0; i < coords.length; i++) {
            let coord = coords[i];
            let temp = [];
            temp[0] = coord.latitude;
            temp[1] = coord.longitude;

            result.push(temp);
        }

        return result;
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getIconInfo = (crop = '') => {

        let iconUrl = "";

        if (!crop) {
            return null;
        }

        iconUrl = getCropIcon(crop);

        if (!iconUrl) {
            return null;
        }

        let iconInfo = L.icon({
            iconUrl: iconUrl,
            iconRetinaUrl: iconUrl,
            iconAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,

            iconSize: [20, 20],
        });

        return iconInfo;
    };


    getColor = () => {
        var colors = ["#88ebcd", "#00EE66", "#22AAcd", "#9999cd"];
        return colors[3];
    };

    render() {
        return (
            this._isMounted ?
                <Map center={this.props.center} bounds={this.props.bounds} style={{ width: '100%', height: '100%', borderRadius: '5px' }} /*zoom={this.props.zoom}*/ zoomControl={false}>
                    <WMSTileLayer
                        layers='ortofoto_nacional'
                        url="https://mapas.ide.uy/WMS_Uruguay_IDEuy_Ortofoto_CoberturaNacional"
                    />

                    <FeatureGroup>
                        {
                            this.props.fieldList.map((field, index) => (
                                <FeatureGroup key={index} color={field.color ? field.color : 'lime'}>
                                    {
                                        field.polygons && field.polygons.map((polygon, subIndex) => (
                                            <SubPolygon key={subIndex}
                                                iconInfo={this.getIconInfo(polygon.crop)}
                                                hoverStatus={this.props.hoverFieldNumber === index && (this.props.hoverPolygonNumber === -1 || this.props.hoverPolygonNumber === subIndex) ? true : false}
                                                positions={this.getPolygons(polygon)}
                                                name={polygon.name}
                                            />
                                        ))
                                    }
                                </FeatureGroup>
                            ))
                        }
                    </FeatureGroup>
                </Map> : <div></div>

        )
    }

}

export default DetailMap;