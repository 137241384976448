/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import LoadingOverlay from 'react-loading-overlay';
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
// // react components used to create a google map
import * as moment from 'moment'

// reactstrap components
import {
  Button,
  Col, Input, InputGroup, InputGroupAddon, InputGroupText,
  Row
} from "reactstrap";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import "leaflet-geotiff-2";
import { plot as Plot, addColorScale } from "plotty";
// optional renderers

import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import "leaflet-geotiff-2/dist/leaflet-geotiff-rgb";


import SidebarLayer from "../../components/FixedPlugin/SidebarLayer.js";
import FixedPluginH from "../../components/FixedPlugin/FixedPluginH.js";

import firebase, { firestore } from 'firebase';
import { config } from "../../config";

import 'leaflet-measure/dist/leaflet-measure.css';
import 'leaflet-measure/dist/leaflet-measure.es.js';
import '../../assets/css/leaflet-measure.css';
import '../../assets/css/customCursor.css'




import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCentroide } from './geoFunciones.js';
import cloudicon from "assets/img/cloud-icon.png";
import Modal from "reactstrap/es/Modal";

var renderer;
var centered = false;

var map;
var objetos = {
  valores_slider_NDVI: [0, 0.125, 0.5, 1, 0, 0.8],
  valores_slider_CCCI: [0, 0.125, 0.5, 1, 0, 0.8],
  valores_slider_NDWI: [0, 0.125, 0.5, 1, -1, 0.6],
  valores_slider_RGB: [0, 0.125, 0.5, 1, 0, 0.8],
}
class RasterMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateList: ["2018-01-01", "2020-12-30"],
      polygonsList: [],
      type: "NDVI",
      renders: [],
      valores_slider: [0, 0.125, 0.5, 1],
      min: 0,
      max: 0.8,
      done: false,
      colors: ["#444444", "#d7191c", "#f5b60f", "#157a33", "#444444"],
      colors_NDVI: ["#444444", "#d7191c", "#f5b60f", "#157a33", "#444444"],
      colors_NDWI: ["#000", "#d7191c", "#ed0", "#08d", "#444444"],
      colors_CCCI: ["#444444", "#05a", "#f5b60f", "#008a00", "#444444"],
      fieldId: "",
      waypointDescription: "",
      showWaypointModal: false,
      waypointCoord: [],
      waypoints: [],
      loading: false,
      cursorToggled: false
    }
    this.getPolygons = this.getPolygons.bind(this);
    this.onUpdateModalToggle = this.onUpdateModalToggle.bind(this);
    this.addWaypoint = this.addWaypoint.bind(this);
    this.loadWaypoints = this.loadWaypoints.bind(this);
    this.setWaypontCoord = this.setWaypontCoord.bind(this);
  }

  componentDidMount() {
    const { match: { params } } = this.props;


    let fieldId = params.id;

    this.setState({
      fieldId: fieldId
    });

    let timeCount = 0;




    this.map = L.map('map',
      {
        center: [-32, -57],
        zoom: 7,
      });

    this.map.on('click', (e) => {
      if (this.state.cursorToggled) {
        this.setWaypontCoord(e)
      }
    });


    map = this.map;
    map.createPane('wms');
    map.getPane('wms').style.zIndex = 0;

    L.tileLayer.wms('https://mapas.ide.uy/WMS_Uruguay_IDEuy_Ortofoto_CoberturaNacional', {
      layers: 'ortofoto_nacional',
      pane: 'wms',
    }).addTo(map);


    map.createPane('raster');
    map.getPane('raster').style.zIndex = 2;
    map.createPane('polygons');
    map.getPane('polygons').style.zIndex = 1;



    addColorScale("oryzacolor", this.state.colors.slice(0, 4), this.state.valores_slider);

    let measureOptions = {
      position: 'bottomright',
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'hectares', secondaryAreaUnit: undefined,
      activeColor: '#76ff08',
      completedColor: '#C8F2BE',
      captureZIndex: 100000,
      popupOptions: {
        className: 'leaflet-measure-resultpopup', autoPanPadding: [10, 10],
      }
    };

    let measureControl = new L.Control.Measure(measureOptions);
    measureControl.addTo(map);

    var timeInterval = setInterval(() => {
      if (firebase.auth().currentUser == null) {
        timeCount++;
        if (timeCount > 4) {
          this.props.history.push('/auth/login');
        }
      } else {
        this.setState({
          currentUser: firebase.auth().currentUser,
          markers: L.layerGroup()
        }, () => {
          this.loadPolygons(params.id);
          this.loadWaypoints();
        });
        clearInterval(timeInterval);

      }
    }, 1000);

  }

  onUpdateModalToggle(changeCursor) {
    if (changeCursor) {
      L.DomUtil.removeClass(this.map._container, 'marker-cursor-enabled');
    }
    this.setState({
      showWaypointModal: !this.state.showWaypointModal,
      cursorToggled: changeCursor ? false : true
    });
  }

  setWaypontCoord(e) {
    this.setState({
      showWaypointModal: !this.state.showWaypointModal,
      waypointCoord: [e.latlng.lat, e.latlng.lng]
    });
  }

  addWaypoint() {
    this.setState({
      loading: true
    })
    var vm = this;
    let waypointData = {
      coords: new firebase.firestore.GeoPoint(this.state.waypointCoord[0], this.state.waypointCoord[1]),
      detail: this.state.waypointDescription,
      deleted: false
    };
    firestore()
      .collection("fields/" + this.state.fieldId + "/waypoints")
      .add(waypointData)
      .then(function () {
        vm.setState({
          loading: false
        })
        vm.onUpdateModalToggle(true)
        vm.loadWaypoints()
      })
      .catch(function (error) {
        vm.setState({
          loading: false
        })
        vm.onUpdateModalToggle(true)
        console.error("Error writing document", error);
      });
  }

  centerMap(polygons) {
    console.log(polygons);
    if (polygons && polygons.length > 0) {
      var p1 = L.point(polygons[0].coords[0].latitude, polygons[0].coords[0].longitude);
      var bounds = L.latLngBounds([p1, p1]);
      console.log(bounds);
      polygons.forEach((polygon) => {
        polygon.coords.forEach((coord) => {
          var p2 = L.latLng(coord.latitude, coord.longitude);
          bounds.extend(p2);
        })
      });
      console.log(bounds, bounds.isValid());
      map.fitBounds(bounds);
      map.addLayer(this.state.markers);
    }
  }

  async loadWaypoints() {
    let waypoints = []
    const waypointsData = await firestore()
      .collection("fields/" + this.state.fieldId + "/waypoints")
      .get()
    waypointsData.docs.map(item => {
      let tempData = item.data();
      tempData['id'] = item.id;
      if (!tempData.deleted) { waypoints.push(tempData); }
    });
    for (var i = 0; i < waypoints.length; i++) {
      let waypoint = waypoints[i]
      var marker = L.marker([waypoint.coords.latitude, waypoint.coords.longitude])
        .addTo(this.map);
      marker
        .bindPopup(`<b>${waypoint.detail}</b><br>`);
    }
  }

  async loadPolygons(field) {
    var location = "fields/" + field + "/polygons/"
    let polygons = [];
    const fieldData = await firestore().collection(location).get();//ALErt if undefined
    fieldData.docs.map(item => {
      let tempData = item.data();
      tempData['id'] = item.id;
      if (!tempData.deleted) { polygons.push(tempData); }

    });

    console.log(polygons);
    var datesArray = [];
    var datesArray2 = [];
    for (var i = 0; i < polygons.length; i++) {
      const first_rasterData = await firestore().collection(location + polygons[i].id + "/raster_data")
        .where("type", "==", this.state.type)
        .orderBy("date")
        .get();///ANADIR ALERT IF length = 0
      var last;
      first_rasterData.docs.map(item => {
        let tempData = item.data();
        var _date = tempData['date'].seconds * 1000;
        datesArray.push(_date);
      });
    }

    datesArray = datesArray.filter((v, i, a) => a.indexOf(v) === i);
    datesArray = datesArray.sort((a, b) => a - b);
    var last;
    for (var i = 0; i < datesArray.length; i++) {
      last = moment(datesArray[i]);
      last.add(4, 'hours');
      last.toDate().toISOString().substring(0, 10);
      datesArray2.push(last);
    }

    this.setState({ polygonsList: polygons, dateList: datesArray2, done: true })
    this.centerMap(polygons);
    this.handleFecha([null, last]);
    this.drawPoligons(polygons);

  }

  drawPoligons(polygons) {
    for (var i = 0; i < polygons.length; i++) {
      let polygon = polygons[i]
      var polygonOptions = {
        key: i,
        color: '#aaffaa',
        weight: 1.0,
        pane: 'polygons'
      }
      var polygonCoords = this.getPolygons(polygon)
      L.polygon(polygonCoords, polygonOptions).addTo(map);
    }
  }



  async handleColors(colors) {
    var amplitud = colors._60 - colors._0;
    var d1 = (colors._20 - colors._0) / amplitud;
    var d2 = (colors._40 - colors._0) / amplitud;
    var valores = [0, d1, d2, 1];
    var key = "valores_slider_" + this.state.type;
    objetos[key] = valores;
    objetos[key][4] = colors._0;
    objetos[key][5] = colors._60;
    addColorScale("oryzacolor", this.state.colors.slice(0, 4), valores.slice(0, 4));
    if (this.state.type == "NDWI") {
      this.state.renders.forEach((renderer) => renderer.setDisplayRange((2 * colors._0 - 1), (2 * colors._60 - 1)));
      this.setState({ valores_slider: valores, min: (2 * colors._0 - 1), max: (2 * colors._60 - 1) });
    } else {
      this.state.renders.forEach((renderer) => renderer.setDisplayRange(colors._0, colors._60));
      this.setState({ valores_slider: valores, min: colors._0, max: colors._60 });
    }
  }

  async changeIndex(index) {
    var toasId = toast.info("Cargando índice");

    if (index.value == "RGB") {
      this.setState({ type: index.value }, _ => {
        this.handleFecha(this.state.fecha);
      });
    }
    else {
      var colores = this.state["colors_" + index.value];
      var key = "valores_slider_" + index.value;
      addColorScale("oryzacolor", colores.slice(0, 4), objetos[key].slice(0, 4));
      if (index.value == "NDWI") {
        this.setState({ type: index.value, colors: colores, valores_slider: objetos[key].slice(0, 4), min: (2 * objetos[key][4] - 1), max: (2 * objetos[key][5] - 1) }, _ => {
          this.handleFecha(this.state.fecha, (2 * objetos[key][4] - 1), (2 * objetos[key][5] - 1));
        });
      } else {
        this.setState({ type: index.value, colors: colores, valores_slider: objetos[key].slice(0, 4), min: objetos[key][4], max: objetos[key][5] }, _ => {
          this.handleFecha(this.state.fecha, objetos[key][4], objetos[key][5]);
        });
      }
    }

  }

  async deleteFecha(date, polygonId) {
    var m = moment(date);
    m.subtract(1, 'days');
    var desde = m.toDate();
    m.add(2, 'days');
    var hasta = m.toDate();
    var rasters = await firestore()
      .collection(polygonId + "/raster_data")
      .where("date", ">", desde)
      .where("date", "<", hasta)
      .get()

    rasters.foreach(function (r) {
      r.ref.delete();
    });
    firestore().collection(polygonId + "/cloud_data").add({
      date: date,
      type: 3,
      source: "USER"
    });

  }


  async handleFecha(index, min, max) {
    if (min == null) { min = this.state.min }
    if (max == null) { max = this.state.max }

    var fecha = index[1];
    var tipo = this.state.type;
    var id = tipo + " " + fecha;
    var m = moment(fecha);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    var desde = m.toDate();
    m.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
    var hasta = m.toDate();
    console.log(index, fecha, id, m, desde, hasta)
    var location = "fields/" + this.state.fieldId + "/polygons/"
    var renders = []
    var mapa = this.map;
    this.map.eachLayer(function (layer) {
      if (layer.raster) { mapa.removeLayer(layer); }
      console.log(layer);
    });



    this.state.markers.clearLayers();
    for (var i = 0; i < this.state.polygonsList.length; i++) {
      var polygon = this.state.polygonsList[i]
      var rasters = await firestore()
        .collection(location + polygon.id + "/raster_data")
        .where("type", "==", tipo).where("date", ">=", desde)
        .where("date", "<=", hasta)
        .get()
      var clouds = await firestore()
        .collection(location + polygon.id + "/cloud_data").where("date", ">=", desde)
        .where("date", "<=", hasta)
        .get()
      if (clouds.docs.length > 0) {
        var myIcon = L.icon({
          iconUrl: cloudicon,
          iconSize: [32, 32],
          iconAnchor: [16, 16],
          popupAnchor: [0, 0],
        });
        //L.marker([51.5, -0.09]).addTo(mymap);
        var centroide = getCentroide(polygon.coords);
        this.state.markers.addLayer(L.marker([centroide.latitude, centroide.longitude], { icon: myIcon }).bindPopup("Imágen filtrada por nubes."));

      } else if (rasters.docs.length > 0) {

        rasters.forEach(function (ras) {
          if (tipo == "RGB") {
            var renderer = L.LeafletGeotiff.rgb();
            const options2 = {
              alphaBand: 0,
              transpValue: 0,
              renderer: renderer,
              pane: 'raster'
            };

            // create layer
            L.leafletGeotiff(ras.data().url, options2).addTo(map);
          } else {
            var options = {
              // Optional. Minimum values to plot.
              displayMin: min,
              // Optional. Maximum values to plot.
              displayMax: max,
              // Optional. If true values outside `displayMin` to `displayMax` will be rendered as if they were valid values.
              clampLow: false,
              clampHigh: true,
              // Optional. Plotty color scale used to render the image.
              colorScale: "oryzacolor"
            };
            var renderer = L.LeafletGeotiff.plotty(options);
            const options2 = {
              renderer: renderer,
              noDataValue: 0,
              pane: 'raster'
            };
            renders.push(renderer);
            L.leafletGeotiff(ras.data().url, options2).addTo(map)
          }
        });
      }
    }
    this.setState({ renders: renders, fecha: index });
  }




  getPolygons = (data) => {

    const { coords } = data;
    let result = [];
    for (let i = 0; i < coords.length; i++) {
      let coord = coords[i];
      let temp = [];
      temp[0] = coord.latitude;
      temp[1] = coord.longitude;

      result.push(temp);
    }

    return result;
  };

  toggleCursor = () => {
    L.DomUtil.addClass(this.map._container, 'marker-cursor-enabled');
    this.setState({
      cursorToggled: true
    });
  }

  render() {


    const waypointModal = (<Modal
      isOpen={this.state.showWaypointModal}
      keyboard={false}
      style={{ maxWidth: '600px', transform: 'translate(0, 0)' }}
    >
      <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
        <h4 className="title title-up">Agregar Waypoint</h4>

      </div>
      <div className="modal-body">
        <br />
        <Row className="justify-content-center">
          <Col sm={12} md={10} lg={8}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-air-baloon" />
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Texto del Waypoint" type="text"
                value={this.state.polygonName}
                onChange={(e) =>
                  this.setState({
                    waypointDescription: e.target.value
                  })
                } />
            </InputGroup>
          </Col>
        </Row>
      </div>
      {
        !this.state.loading ? <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                this.onUpdateModalToggle(true)
              }
            >
              Cancelar
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="primary"
              type="button"
              onClick={() => this.addWaypoint()}
            >
              Guardar
            </Button>
          </div>
        </div> : <div className="text-center py-4">
          <CircularProgress color="secondary" />
        </div>
      }

    </Modal>);

    return (

      <>
        {
          waypointModal
        }
        <LoadingOverlay active={!this.state.done} spinner text={'Cargando ... '}>
          <SidebarLayer width={280}
            handleColors={this.handleColors.bind(this)}
            colors={this.state.colors} fieldId={this.state.fieldId}
            changeIndex={this.changeIndex.bind(this)}
            toggleCursor={this.toggleCursor.bind(this)}
          />
          <div>
            <div id="map" className="map-content" style={{ height: '100vh', maxWidth: 'calc(100% - 280px)', width: 'auto' }}></div>
          </div>
          <ToastContainer />
          <FixedPluginH
            title={"Control Fechas"}
            bgColor={this.state.backgroundColor}
            handleFecha={this.handleFecha.bind(this)}
            fechas={this.state.dateList}
            initial={this.state.dateList.length - 1}
          />

        </LoadingOverlay>
      </>
    );
  }
}

export default RasterMap;
