import React, { useEffect, useState } from 'react';
import { slide as RightSidebar } from 'react-burger-menu';
import '../../assets/css/map.css';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
const MapSidebar = (props) => {

    const [field, setField] = useState(null);
    const [polygon, setPolygon] = useState(null);
    useEffect(() => {
        setField(props.field);
    }, [props.field]);

    useEffect(() => {
        setPolygon(props.polygon)
    }, [props.polygon]);

    return (

        <div style={{position:"relative"}}>
<RightSidebar right disableCloseOnEsc noOverlay isOpen={props.open} customCrossIcon={false} width={props.width} styles={{bmMenu: {background: 'linear-gradient(#66615b,#000 80%)'}}}>

            <div className='py-2 px-2' style={{ width: '100%' }}>
                <span style={{ fontSize: '24px', color: 'white' }}>
                    <i className='fa fa-angle-right' onClick={props.onHideSidebar} style={{ fontSize: '25px', color: '#ffffff', cursor: 'pointer' }}></i>
                    &nbsp;{field ? field.name : ''}
                </span>
            </div>
            <div className='linea-gris'></div>
            <Row><Col>
                <Card className="m-3">
                    <CardHeader className="text-center">

                        <h6>Detalles del polígono</h6>
                    </CardHeader>
                    <CardBody className="pl-1 pr-1 pt-1">
                        <div className='row mx-0 my-0' style={{ color: "black" }}>


                            <div className='col-12'>
                                {polygon && <span><strong>Nombre : </strong>{polygon.name}</span>}
                            </div>
                            <div className='col-12'>
                                {polygon && polygon.crop && <span><strong>Cultivo : </strong>{polygon.crop}</span>}
                            </div>
                            <div className='col-12'>
                                {field && <span><strong>Variedad : </strong>{polygon.variety}</span>}
                            </div>
                            <div className='col-12'>
                                {field && <span><strong>Fecha de siembra : </strong>{polygon.sowing_date && new Date(polygon.sowing_date.seconds * 1000).toLocaleDateString()}</span>}
                            </div>
                        </div>
                        <div className="row px-2 align-items-center justify-content-center">
                            <div className="col-12">
                                <a className="btn btn-success container" href={field && `/admin/cropmap/detail/${field.id}`}>
                                    <i className="fi flaticon-bar-chart-2"></i>
                                    Gráficos
                                </a>
                            </div>
                            <div className="col-12">
                                <a className="btn btn-success container" href={field && `/admin/indexmap/${field.id}`}>
                                    <i className="fi flaticon-land"></i>
                                    Índices
                                </a>
                            </div>

                        </div>
                    </CardBody>
                </Card>
            </Col></Row>

        </RightSidebar>
        </div>
        
    )
};
// &nbsp;{polygon ? (polygon.name.length < 14 ? polygon.name : polygon.name.substring(0, 11) + '...') : ''}


export default MapSidebar;