/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import * as moment from 'moment'
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps


// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardSubtitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Input, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
import firebase, { firestore } from "firebase";
import DetailMap from "./maps/dashboard/detailMap";
import '../assets/css/map.css';
import Spinner from "reactstrap/es/Spinner";
import L from 'leaflet';
import area from '@turf/area';
import PerformanceForm from "./forms/PerformanceForm";
import NotificationAlert from 'react-notification-alert';
import Modal from "reactstrap/es/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import '../assets/css/emptyView.css';
import { NavLink } from "react-router-dom";

class Dashboard extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      centerLocation: { lat: -32, lng: -57 },
      zoom: 14,
      fieldList: [],
      fieldsUrl: '',
      currentUser: null,
      bounds: [],
      loadingTable: false,
      hoverFieldNumber: -1,
      hoverPolygonNumber: -1,
      mouseOnMapStatus: false,
      showPerformanceForm: false,
      performanceUrl: '',
      showDeleteModal: false,
      deleteUrl: '',
      loading: false,
      showLoading: true,
      showContactModal: false,
      showShareModal: false,
      userEmail: "",
      shareFieldId: null,
      loadingShare: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.onSavePerformance = this.onSavePerformance.bind(this);
  }

  componentDidMount() {
    let timeCount = 0;
    this._isMounted = true;
    var timeInterval = setInterval(() => {
      if (firebase.auth().currentUser == null) {
        timeCount++;
        if (timeCount > 2) {
          this.props.history.push('/auth/login');
        }
      } else {
        this.setState({
          currentUser: firebase.auth().currentUser,
          fieldsUrl: `fields`,
        }, () => {

          this.fetchFieldList();
        });
        clearInterval(timeInterval);
      }
    }, 1500);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getPolygonArea = (polygonArr) => {

    let path = [];
    let result = 0;
    if (polygonArr) {
      for (let i = 0; i < polygonArr.length; i++) {
        let tempItem = [];
        tempItem[0] = polygonArr[i].latitude;
        tempItem[1] = polygonArr[i].longitude;

        path.push(tempItem);
      }

      let tempPolygon = L.polygon(path);
      let sqMeters = area(tempPolygon.toGeoJSON());
      result = (sqMeters / 10000).toFixed(1);
    }

    return result + " has.";
  };

  setBounds = (selectedField = null) => {
    let left = -180;
    let top = -90;
    let right = 180;
    let bottom = 90;

    let result = [
      [this.state.centerLocation.lng, this.state.centerLocation.lat],
      [this.state.centerLocation.lng, this.state.centerLocation.lat]
    ];

    if (!selectedField) {
      let fieldList = this.state.fieldList;

      if (fieldList) {
        for (let i = 0; i < fieldList.length; i++) {
          let polygons = fieldList[i].polygons;

          if (polygons) {
            for (let j = 0; j < polygons.length; j++) {
              let coords = polygons[j].coords;
              if (coords) {
                for (let k = 0; k < coords.length; k++) {
                  let latitude = coords[k].latitude;
                  let longitude = coords[k].longitude;
                  if (latitude > top) {
                    top = coords[k].latitude;
                  }

                  if (latitude < bottom) {
                    bottom = coords[k].latitude;
                  }

                  if (longitude > left) {
                    left = longitude;
                  }

                  if (longitude < right) {
                    right = longitude;
                  }
                }
              }
            }
          }

        }
        result = [
          [top, left],
          [bottom, right]
        ]
      }
    } else {
      let polygons = selectedField.polygons;

      if (polygons) {
        for (let j = 0; j < polygons.length; j++) {
          let coords = polygons[j].coords;
          if (coords) {
            for (let k = 0; k < coords.length; k++) {
              let latitude = coords[k].latitude;
              let longitude = coords[k].longitude;
              if (latitude > top) {
                top = coords[k].latitude;
              }

              if (latitude < bottom) {
                bottom = coords[k].latitude;
              }

              if (longitude > left) {
                left = longitude;
              }

              if (longitude < right) {
                right = longitude;
              }
            }


          }
        }

        result = [
          [top, left],
          [bottom, right]
        ]
      }
    }

    if (this._isMounted) {
      this.setState({
        bounds: result
      })
    }
  };

  MouseRowOver = (selectedField, fieldIdex, polygonIndex = -1) => {
    if (!this.state.showPerformanceForm && this._isMounted) {
      this.setState({
        hoverFieldNumber: fieldIdex,
        hoverPolygonNumber: polygonIndex
      });

      this.setBounds(selectedField);
    }
  };

  MouseRowOut = () => {
    if (!this.state.showPerformanceForm && this._isMounted) {
      this.setState({
        hoverFieldNumber: -1,
        hoverPolygonNumber: -1
      });

      var vm = this;
      setTimeout(function () {
        if (vm.state.hoverFieldNumber === -1 && vm.state.hoverPolygonNumber === -1) {
          vm.validateMapStatus();
        }
      }, 6000);
    }
  };

  validateMapStatus = () => {
    if (this.state.mouseOnMapStatus === false && this.state.hoverFieldNumber === -1 && this.state.hoverPolygonNumber === -1 && !this.state.showPerformanceForm) {
      this.setBounds();
    }
  };

  MouseOnMapStatus = (bStatus = false) => {
    if (this._isMounted) {
      this.setState({
        mouseOnMapStatus: bStatus
      });


      var vm = this;
      if (bStatus === false) {
        setTimeout(function () {
          vm.validateMapStatus();
        }, 6000)
      }
    }
  };

  getColor = (index) => {
    var colors = ["#88ebcd", "#aaffaa", "#00EE66", "#22AAcd", "#9999cd"];
    return colors[(index) % 5];
  };

  fetchFieldList = async () => {

    if (this._isMounted) {
      this.setState({
        fieldList: [],
        showLoading: true,
      })
    }


    firestore().collection("update_queue").doc(this.state.currentUser.uid).set({ date: new Date().toISOString().substring(0, 13) });
    let dataInfo = [];
    let vm = this;
    const fieldsData = await firestore()
      .collection(this.state.fieldsUrl)
      .where("shared", "array-contains", firebase.auth().currentUser.uid)
      .orderBy("name")
      .get()
      .then(async function (fieldsReference) {
        fieldsReference.docs.map((item, index) => {
          if (item.exists === true) {
            let tempData = item.data();
            if (!tempData.deleted && tempData) {
              tempData['id'] = item.id;
              tempData['color'] = vm.getColor(index);
              dataInfo.push(tempData);
            }
          }
        });
        if (dataInfo.length == 0) {
          if (vm._isMounted) {
            vm.setState({
              fieldList: [],
              showLoading: false,
            })
          }
        } else {
          for (let i = 0; i < dataInfo.length; i++) {
            let id = dataInfo[i]['id'];
            let url = vm.state.fieldsUrl + "/" + id + '/polygons';
            let polygons = [];
            const polygonData = await firestore()
              .collection(url)
              .orderBy("name")
              .get()
              .then(async function (polygonsReference) {
                polygonsReference.docs.map(async (item) => {
                  if (item.exists === true) {
                    let tempData = item.data();
                    if (!tempData.deleted) {
                      tempData['id'] = item.id;
                      tempData['area'] = vm.getPolygonArea(tempData.coords);

                      let seedLog = url + "/" + item.id + "/logs";

                      const seedLogData = await firestore()
                        .collection(seedLog)
                        .where("type", "==", "seeding")
                        .orderBy("date", "desc")
                        .limit(1)
                        .get()
                        .then(async function (seedLogReference) {
                          if (seedLogReference.docs.length > 0) {
                            let seedLogJson = seedLogReference.docs[0].data();
                            tempData['crop'] = seedLogJson["crop"][0];
                            tempData['variety'] = seedLogJson["crop"][1];
                            tempData['sowing_date'] = seedLogJson["date"]
                            let sowingDate = moment(tempData['sowing_date'].seconds * 1000)
                            if (moment().diff(sowingDate, 'months') >= 6) {
                              const yieldLogData = await firestore()
                                .collection(seedLog)
                                .where("type", "==", "yield")
                                .orderBy("date", "desc")
                                .limit(1)
                                .get()
                                .then(async function (yieldLogReference) {
                                  if (yieldLogReference.docs.length > 0) {
                                    let yieldLogJson = yieldLogReference.docs[0].data();
                                    tempData['performance'] = yieldLogJson['value']
                                    tempData['yieldDisabled'] = false
                                  } else {
                                    tempData['yieldDisabled'] = false
                                  }
                                });
                            } else {
                              tempData['yieldDisabled'] = true
                            }
                            polygons.push(tempData);
                            dataInfo[i]['polygons'] = polygons;
                            if (vm._isMounted) {
                              vm.setState({
                                fieldList: dataInfo,
                                showLoading: false,
                              }, () => {
                                vm.setBounds()
                              })
                            }
                          }
                        });
                    }
                  }
                })
              });
          }
        }
      }
      );
  };

  closeModal() {
    if (this._isMounted) {
      this.setState({ showPerformanceForm: false })
    }
  }

  onSavePerformance = value => {
    if (value) {
      this.notify('tc', 2, "Guardado!");
      this.fetchFieldList()
    } else {
      this.notify('tc', 3, "Error al guardar el documento. Por favor vuelva a intentarlo");
    }
  }

  notify = (place, color, msg) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div style={{ zIndex: 10001 }}>
          <div>{msg}</div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 4,
    };
    this.refs.notify.notificationAlert(options);
  };

  onDeleteField = () => {

    this.setState({
      loading: true
    })

    let fieldsUrl = this.state.fieldsUrl;
    let fieldId = this.state.deleteUrl;

    var vm = this;

    firestore().collection(fieldsUrl).doc(fieldId).set({
      deleted: true
    }, { merge: true })
      .then(function () {
        let deletedData = {
          fieldId: fieldId,
          date: new Date()
        };

        firestore().collection("field_delete/" + vm.state.currentUser.uid + "/deleted_field").doc(fieldId).set(deletedData)
          .then(function () {
            vm.notify('tc', 2, "Chacra eliminada!");
            vm.fetchFieldList()
            vm.setState({
              loading: false,
              showDeleteModal: false,
              fieldList: []
            })
          })
          .catch(function (error) {
            vm.setState({
              loading: false,
              showDeleteModal: false,
            })
            vm.notify('tc', 3, "Error al eliminar la chacra. Por favor, intente de nuevo.");
          })
      })
      .catch(function (error) {
        vm.setState({
          loading: false,
          showDeleteModal: false,
        })
        vm.notify('tc', 3, "Error al eliminar la chacra. Por favor, intente de nuevo.");
      })
  };

  onCloseContactAlert = () => {
    this.setState({
      showContactModal: false,
    })
  };

  showShareModal(field) {
    this.setState({
      showShareModal: true,
      shareFieldId: field.id
    })
  }

  closeShareModal() {
    this.setState({
      showShareModal: false,
      shareFieldId: null,
      userEmail: "",
      loadingShare: false
    })
  }

  shareField = async () => {
    let userEmail = this.state.userEmail.trim();
    let vm = this;
    if (userEmail !== "" && vm.state.shareFieldId != null) {
      vm.setState({
        loadingShare: true
      })
      firestore()
        .collection("usuarios")
        .where("email", "==", userEmail)
        .limit(1)
        .get()
        .then(function (userReference) {
          if (userReference.docs.length > 0) {
            let item = userReference.docs[0]
            firestore()
              .collection("fields")
              .doc(vm.state.shareFieldId)
              .update({
                shared: firestore.FieldValue.arrayUnion(item.id)
              })
              .then(function () {
                vm.notify('tc', 2, "Chacra compartida!")
                vm.closeShareModal()

              })
              .catch((error) => {
                console.log(error)
                vm.notify('tc', 3, "Error al compartir la chacra. Verifique el email ingresado e intentelo de nuevo")
                vm.closeShareModal()
              })
          } else {
            vm.notify('tc', 3, "Error al compartir la chacra. Verifique el email ingresado e intentelo de nuevo")
            vm.closeShareModal()
          }
        })
        .catch((error) => {
          console.log(error)
          vm.notify('tc', 3, "Error al compartir la chacra. Verifique el email ingresado e intentelo de nuevo")
          vm.closeShareModal()
        })
    }
  }

  render() {


    const shareModal = (
      <Modal
        isOpen={this.state.showShareModal}
      >
        <div className="modal-header justify-content-center">
          <h4 className="title title-up">Compartir chacra</h4>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm={12}>
              <label>Ingrese el email del usuario de Oryzativa al que le quiere compartir la chacra (debe tener creado el usuario previamente)</label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-air-baloon" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="email" type="text"
                  value={this.state.userEmail}
                  onChange={(e) =>
                    this.setState({
                      userEmail: e.target.value
                    })
                  } />
              </InputGroup>
            </Col>
          </Row>
        </div>
        {
          this.state.loadingShare ? <div className="text-center py-4">
            <CircularProgress color="secondary" />
          </div> : <div className="modal-footer">
            <div className="left-side">
              <Button
                className="btn-link"
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={() => this.closeShareModal()}
              >
                No
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                className={this.state.userEmail !== "" ? "btn-link" : "btn-link disabled"}
                color="primary"
                type="button"
                onClick={() => { this.shareField() }}
              >
                Si
              </Button>
            </div>
          </div>
        }
      </Modal>
    );

    const contactModal = (
      <Modal
        isOpen={this.state.showContactModal}
      >
        <div className="modal-header justify-content-center">
          <h4 className="title title-up">Atención</h4>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm={12}>
              <label className="text-center">Para ponerse en contacto con el equipo de Oryzativa envié un email a info@oryzativa.com.</label>
            </Col>
          </Row>
        </div>
        <div className="empty-view-modal-footer">
          <Button
            className="btn-link"
            color="danger"
            data-dismiss="modal"
            type="button"
            style={{ "width": '150px' }}
            onClick={() => this.onCloseContactAlert()}
          >
            Aceptar
          </Button>
        </div>
      </Modal>
    );

    return (
      <>
        {
          contactModal
        }
        {
          shareModal
        }
        <div className="content">
          {!this.state.showLoading ?

            this.state.fieldList.length <= 0 ?
              <div className="content">
                <Row>
                  <Col sm="12" md={{ size: 6, offset: 3 }}>

                    <Card className="card-testimonial">
                      <CardBody>
                        <div className="icon icon-primary">
                          <i className="fa fa-laptop-code" />
                        </div>
                        <CardTitle tag="h6">
                          Bienvenido a Oryzativa
                        </CardTitle>
                        <CardSubtitle className="mb-2 text-muted">Una vez cargadas todas las chacras, avisar a Oryzativa para dar comienzo a la importación de imágenes, la misma podrá tener una demora de 24 horas</CardSubtitle>
                        <div className="card-avatar" style={{ paddingTop: '1rem' }}>
                          <a>
                            <img
                              alt="..."
                              className="img"
                              src={require("assets/img/oryzativa_logo.png")}
                            />
                          </a>
                        </div>
                      </CardBody>
                      <CardFooter>
                        <div className="empty-view-footer">
                          <Button
                            className={"btn-link"}
                            color="success"
                            tag={NavLink} to="/admin/cropmap/add-field"
                          >
                            Añadir chacra
                          </Button>
                          <Button
                            color="success"
                            className={"btn-link"}
                            onClick={() =>
                              this.setState({
                                showContactModal: true,
                              })
                            }
                          >
                            Contacto
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </div>
              :
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <Card className="card-stats" style={{ padding: '8px' }}>
                    <CardBody onMouseOver={() => this.MouseOnMapStatus(true)} onMouseOut={() => this.MouseOnMapStatus(false)} style={{ height: '585px', padding: '0' }}>
                      {
                        this.state.bounds.length > 0 && this.state.fieldList.length > 0 &&
                        <DetailMap bounds={this.state.bounds}
                          center={this.state.centerLocation}
                          zoom={this.state.zoom}
                          fieldList={this.state.fieldList}
                          hoverFieldNumber={this.state.hoverFieldNumber}
                          hoverPolygonNumber={this.state.hoverPolygonNumber}
                        />
                      }
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={8} md={6} sm={12}>
                  <Card className="card-stats">
                    <CardBody style={{ height: '600px' }}>
                      {
                        this.state.fieldList && this.state.fieldList.length > 0 &&
                        <Table responsive hover={false} className="detail-table" style={{ paddingBottom: '10px', overflow: 'auto!important', maxHeight: '570px !important', height: '570px !important' }}>
                          <thead className="text-primary">
                            <tr>
                              <th className="text-center" width={150}></th>
                              <th>Cultivo</th>
                              <th>Variedad</th>
                              <th>Area</th>
                              <th>Fecha de siembra</th>
                              <th>Rendimiento</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.fieldList && this.state.fieldList.map((field, fieldIndex) => (
                                <>
                                  <tr className="field-row" key={`field_${fieldIndex}`}
                                    onMouseOver={() => this.MouseRowOver(field, fieldIndex)}
                                    onMouseOut={() => this.MouseRowOut()}
                                  >
                                    <td className="text-center">{field.name}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <a className="btn-icon btn-sm btn-neutral" href={field && `/admin/indexmap/${field.id}`} style={{ marginRight: '5px' }}>
                                        <i className="fi flaticon-land" />
                                      </a>
                                      <a className="btn-icon btn-sm btn-neutral" href={field && `/admin/cropmap/detail/${field.id}`} style={{ marginRight: '5px' }}>
                                        <i className="fi flaticon-bar-chart-2" />
                                      </a>
                                      {
                                        (this.state.currentUser.uid != null && field.owner === this.state.currentUser.uid) && <a className="btn-icon btn-sm btn-neutral" onClick={() => { this.showShareModal(field) }} style={{ color: "#66615B" }}>
                                          <i className="fi flaticon-leaf-3" />
                                        </a>
                                      }
                                    </td>
                                    <td></td>
                                  </tr>
                                  {
                                    field.polygons && field.polygons.length > 0 ? field.polygons.map((polygon, polygonIndex) => (
                                      <tr className="polygon-row" key={`polygon_${polygonIndex}`}
                                        onMouseOver={() => this.MouseRowOver(field, fieldIndex, polygonIndex)}
                                        onMouseOut={() => this.MouseRowOut()}
                                      >

                                        <td>{polygon.name}</td>
                                        <td>{polygon.crop}</td>
                                        <td>{polygon.variety}</td>
                                        <td>{polygon.area}</td>
                                        <td>{polygon.sowing_date && new Date(polygon.sowing_date.seconds * 1000).toLocaleDateString()}</td>
                                        {
                                          polygon.performance ? <td>{polygon.performance + ' (Kg/ha)'}</td> :
                                            <td className="text-center">
                                              <Button
                                                className="btn-icon"
                                                color="success"
                                                size="sm"
                                                title={"Formulario Rendimiento"}
                                                type="button"
                                                disabled={polygon.yieldDisabled ? true : false}
                                                onClick={() =>
                                                  this.setState({
                                                    showPerformanceForm: true,
                                                    performanceUrl: "/" + field.id + '/polygons/' + polygon.id + '/logs'
                                                  })
                                                }>
                                                <i className="fa fa-edit" />
                                              </Button>
                                            </td>
                                        }
                                        <td></td>
                                      </tr>
                                    )) : (
                                      <tr>
                                        <td colSpan="2">No hay polígonos en esta chacra.</td>
                                        <td className="text-center">
                                          <Button
                                            className="btn-icon"
                                            color="danger"
                                            size="sm"
                                            title={"Eliminar chacra"}
                                            type="button"
                                            id="eliminarChacraTooltip"
                                            onClick={() => {
                                              this.setState({
                                                showDeleteModal: true,
                                                deleteUrl: field.id
                                              })
                                            }
                                            }>
                                            <i className="fa fa-trash-o" />
                                          </Button>
                                          <UncontrolledTooltip placement="top" target="eliminarChacraTooltip">
                                            Eliminar chacra
                                          </UncontrolledTooltip>
                                        </td>
                                      </tr>
                                    )
                                  }
                                </>
                              ))
                            }
                          </tbody>
                        </Table>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row> :
            <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
              <Spinner style={{ marginTop: '100px' }} animation="border" variant={"primary"}>
              </Spinner>
            </div>
          }
        </div>
        <NotificationAlert ref="notify" />
        <PerformanceForm
          isOpen={this.state.showPerformanceForm}
          onClose={this.closeModal}
          onSaveTapped={this.onSavePerformance}
          performanceUrl={this.state.fieldsUrl + this.state.performanceUrl}
        >
        </PerformanceForm>
        <Modal
          isOpen={this.state.showDeleteModal}
        >
          <div className="modal-header justify-content-center">
            <h5 className="title title-up">Confirma eliminar la chacra?</h5>
          </div>
          {
            !this.state.loading ?
              <div className="modal-footer">
                <div className="left-side">
                  <Button
                    className={"btn-link"}
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.setState({
                        showDeleteModal: false
                      })
                    }
                  >
                    No
                  </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                  <Button
                    color="primary"
                    className={"btn-link"}
                    type="button"
                    onClick={() => { this.onDeleteField() }}
                  >
                    Si
                  </Button>
                </div>
              </div>
              : <div className="text-center py-4">
                <CircularProgress color="secondary" />
              </div>
          }
        </Modal>
      </>


    );
  }
}

export default Dashboard;
