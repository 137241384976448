import React, {Fragment, useEffect, useState} from 'react'
import {Polygon, Popup} from 'react-leaflet';

const CurrentPolygons = (props) => {

    const { polygonInfo } = props;

    let result = [];
    const getPolygons = (data) => {

        const {coordsArr} = data;
        let tempPointsArr = coordsArr[0];

        for (let i = 0; i < tempPointsArr.length; i++) {
            let temp = [];
            temp[0] = tempPointsArr[i][1];
            temp[1] = tempPointsArr[i][0];
            result.push(temp);
        }

        return result;
    };

    const polygonComponents = (
        <Polygon positions={getPolygons(polygonInfo)}>
            <Popup>{polygonInfo.name} : Current Field polygon</Popup>
        </Polygon>
    );

    return    <Fragment>{polygonComponents}</Fragment>
};

export default CurrentPolygons;