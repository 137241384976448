/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Login from "views/pages/Login.js";
import Register from "views/pages/Register.js";
//    Imports de ORYZATIVA
import Logs from "views/Logs.js";
import RasterMap from "views/maps/RasterMap.js";
import CropMap from './views/maps/cropMap/cropMap';
import AddField from "./views/maps/cropMap/addField";
import MapDetail from "./views/maps/cropMap/mapDetail";
import './assets/css/custom.css';
//

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fi flaticon-ecology",
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/cropmap/map",
    name: "Mis Cultivos",
    icon : "fi flaticon-location",
    component: CropMap,
    layout: "/admin",
  },
  {
    //collapse: true,
    path: "/logs",
    name: "Registros",
    icon: "nc-icon nc-book-bookmark",
    component: Logs,
    layout: "/admin",
  },
  {
    path: "/cropmap/detail/:id",
    name: "Detail",
    icon : "fi flaticon-location",
    component: MapDetail,
    layout: "/admin",
    hide  : true
  },
  {
    path: "/cropmap/add-field",
    name: "Crear chacra",
    icon: "fi flaticon-location",
    component: AddField,
    layout: "/admin",
    hide: true
  },

  {
    path: "/indexmap/:id",
    name: "Mapa de Índices",
    icon: "fi flaticon-location",
    component: RasterMap,
    layout: "/admin",
    hide: true
  },
  {
    path: "/login",
    name: "Ingreso",
    icon: "fi flaticon-location",
    component: Login,
    layout: "/auth",
    hide: true
  },
  {
    path: "/register",
    name: "Registro de usuario",
    icon: "fi flaticon-location",
    component: Register,
    layout: "/auth",
    hide: true
  },





];

export default routes;
