import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col} from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from 'firebase';

class SeedForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            crop        : null,
            variety     : null,
            sowing_date : null,
            predecesor : null,
            cropArr     : [],
            varietyArr  : [],

            cropState : true,
            predecesorState : true,
            sowingDateState : true,

            predecessorArray : [{
                "value": "ARR",
                "label": "Arroz"
            } , {
                "value": "SOJ",
                "label": "Soja"
            }, {
                "value": "PRA",
                "label": "Pradera"
            }, {
                "value": "CN",
                "label": "Campo natural"
            }, {
                "value": "LAB",
                "label": "Laboreo de verano"
            }],

            predecesorVariety: null,
            predecesorVarietyArr : [],
        }
    }

    fetchCrops = async () => {
        let cropsUrl= "crops/"
        const cropsData = await firestore().collection(cropsUrl).get();

        let dataInfo = [];

        cropsData.docs.map((item, index) => {
            if (item.exists === true) {
                let tempData = item.data();
                let tempArr = {};
                tempArr['value'] = item.id;
                tempArr['label'] = tempData['name'];
                dataInfo.push(tempArr);
            }
        });

        this.setState({
            cropArr : dataInfo
        });
    };

    fetchVarieties = async (value, predecesor) => {
        let cropsUrl= "crops/"
        let url = cropsUrl + value.value + "/varieties/";
        let varietyData = await firestore().collection(url).get();

        let dataInfo = [];

        varietyData.docs.map((item, index) => {
            let tempArr = {};
            tempArr['value'] = item.id;
            tempArr['label'] = item.id;
            dataInfo.push(tempArr);
        });


        if (predecesor) {
            this.setState({
              predecesorVarietyArr : dataInfo,
              predecesorVariety : dataInfo.length > 0 ? dataInfo[0] : null
            });
          } else {
            this.setState({
                varietyArr : dataInfo,
                variety : dataInfo.length > 0 ? dataInfo[0] : null
            });
          }
    };

    changeCrop = (value, predecesor) => {
        if (predecesor) {
            if(value.value == "ARR") {
                this.fetchVarieties(value, predecesor);
                this.setState({
                    predecesor: value,
                    predecesorState : true
                })
            } else {
                this.setState({
                    predecesor: value,
                    predecesorState : true,
                    predecesorVarietyArr : [],
                    predecesorVariety: null
                })
            }
        } else {
            this.fetchVarieties(value, predecesor);
            this.setState({
                crop: value,
                cropState : true
            })
        }
    };

    componentDidMount() {
        this.fetchCrops()
    }

    saveButtonTapped() {
        if (this.isValidated()) {

            let insertData = {};

            let tempSowingDate = this.state.sowing_date;

            let tempDate;

            if (typeof tempSowingDate === 'string') {
                tempDate = new Date(tempSowingDate);
            } else {
                tempDate = tempSowingDate.toDate();
            }

            insertData.crop = [this.state.crop.value, this.state.variety ? this.state.variety.value : ''];
            insertData.date = tempDate;
            insertData.deleted = false;
            insertData.predecessor = [this.state.predecesor.value, this.state.predecesorVariety ? this.state.predecesorVariety.value : '']
            insertData.type = "seeding";

            this.clearStatus()

            this.props.onSaveTapped(insertData)
        }
    }

    clearStatus() {
        this.setState({
            crop : null,
            variety : null,
            predecesor : null,
            sowing_date: null,
            varietyArr: [],
            predecesorVariety : null,
            predecesorVarietyArr : []
        })
    }

    isValidated = () => {

        var isValidated = true

        if (this.state.crop === null) {
          this.setState({
            cropState : false
          });
          isValidated =  false;
        }
    
        if (this.state.sowing_date == null) {
          this.setState({
            sowingDateState : false
          });
    
          if (isValidated) {
            isValidated =  false;
          }
        }

        if (this.state.predecesor === null) {
            this.setState({
              predecesorState : false
            });
            if (isValidated) {
                isValidated =  false;
            }
          }
        return isValidated;
      };

    closeButtonTapped() {
        this.clearStatus()
        this.props.onClose()
    }

    render() {
        return (
            <Modal
            isOpen={this.props.isOpen}
            >
            <div className="modal-header justify-content-center" style={{paddingTop : 0, paddingBottom : 0}}>
                <h4 className="title title-up">Completar datos del polígono</h4>
            </div>
            <div className="modal-body">
                <br/>
                <Row className="justify-content-center">
                <Col sm={12} md={10} lg={8}>
                        {
                            this.state.cropArr.length > 0 &&
                            <FormGroup>
                                <label>Cultivo</label>
                                <Select
                                    name="crop"
                                    className="react-select"
                                    placeholder="Seleccione cultivo"
                                    classNamePrefix="react-select"
                                    value={this.state.crop}
                                    onChange={(value) => this.changeCrop(value, false)}
                                    options={this.state.cropArr}
                                />
                                {this.state.cropState === false ? (
                                        <label style={{color: '#ff0000'}}>Campo requerido</label>
                                ) : null}
                            </FormGroup>
                        }
                    </Col>
                    <Col sm={12} md={10} lg={8}>
                        {
                            this.state.varietyArr.length > 0 &&
                            <FormGroup>
                                <label>Variedad</label>
                                <Select
                                    name="variety"
                                    className="react-select"
                                    placeholder="Seleccione variedad"
                                    classNamePrefix="react-select"
                                    value={this.state.variety}
                                    onChange={(value) => this.setState({
                                        variety : value
                                    })}
                                    options={this.state.varietyArr}
                                />
                            </FormGroup>
                        }
                    </Col>
                    <Col sm="8">
              {
                this.state.predecessorArray.length > 0 &&
                <FormGroup>
                  <label>Antecesor</label>
                  <Select
                      name="crop"
                      className="react-select"
                      placeholder="Seleccionar cultivo"
                      classNamePrefix="react-select"
                      value={this.state.predecesor}
                      onChange={(value) => this.changeCrop(value, true)}
                      options={this.state.predecessorArray}

                  />
                  {this.state.predecesorState === false ? (
                      <label style={{color: '#ff0000'}}>Campo requerido</label>
                  ) : null}
                </FormGroup>
              }
            </Col>
            <Col sm="8">
              {
                this.state.predecesorVarietyArr.length > 0 &&
                <FormGroup>
                  <label>Variedad antecesor</label>
                  <Select
                      name="variety"
                      className="react-select"
                      placeholder="Seleccionar variedad"
                      classNamePrefix="react-select"
                      value={this.state.predecesorVariety}
                      onChange={(value) => this.setState({
                        predecesorVariety : value
                    })}
                      options={this.state.predecesorVarietyArr}
                  />
                </FormGroup>
              }
            </Col>
                    <Col sm={12} md={10} lg={8}>
                        <FormGroup>
                            <label>Fecha de siembra</label>
                            <ReactDatetime
                                value={this.state.sowing_date}
                                onChange={(value) => {
                                    this.setState({
                                        sowingDateState: true,
                                        sowing_date : value,
                                    })
                                }}
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Ingrese fecha de siembra",
                                }}
                                timeFormat={false}
                            />
                            {this.state.sowingDateState === false ? (
                                        <label style={{color: '#ff0000'}}>Campo requerido</label>
                                ) : null}
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            {
                <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.closeButtonTapped()}
                    >
                        Cancelar
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="btn-link"
                        color="primary"
                        type="button"
                        onClick={() => this.saveButtonTapped()}
                    >Guardar</Button>
                </div>
                </div>
            }
        </Modal>
        );
    }
}
export default SeedForm;