/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";

// reactstrap components
import { Col } from "reactstrap";

// wizard steps
import Step1 from "./settingSteps/chacra.js";
import Step2 from "./settingSteps/uso.js";
import Step3 from "./settingSteps/polygons.js";
import LoadingOverlay from 'react-loading-overlay';
import firebase, {firestore} from "firebase";
import {ToastContainer} from "react-toastr";
import '../../../assets/css/toastr.min.css';

var steps = [
    {
        stepName: "CHACRA",
        stepIcon: "fi flaticon-location",
        component: Step1,
    },
    {
        stepName: "USO",
        stepIcon: "fi flaticon-leaf",
        component: Step2,
    },
    {
        stepName: "POLYGONS",
        stepIcon: "fi flaticon-cuadrado",
        component: Step3,
    }
];

let container;

class AddField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addingStatus : false,
            errorMsg : ""
        }
    }

    showErrorToastr = (msg) => {
        container.success(msg, 'Error', {
            closeButton : true
        })
    };


    onFinishWizard = (allSates) => {

        this.setState({
            addingStatus : true
        });

        let fieldUrl = allSates.USO.fieldsUrl;
        let fieldData  = {
            field : {},
            polygons : []
        };

        let crop = allSates.USO.crop;
        let variety = allSates.USO.variety ? allSates.USO.variety.value : null;

        let predecesor = allSates.USO.predecesor.value;
        let predecesorVariety = allSates.USO.predecesorVariety ? allSates.USO.predecesorVariety.value : null;

        let sowing_date = allSates.USO.sowingDate.toDate();

        fieldData.field['name'] = allSates.CHACRA.fieldname;
        fieldData.field['owner'] = firebase.auth().currentUser.uid;
        fieldData.field['deleted'] = false;
        fieldData.field['shared'] = [firebase.auth().currentUser.uid];

        let tempPolygons = allSates.POLYGONS.polygonsArr;

        tempPolygons.map((item, index) => {

            let tempCoords = item.coordsArr;

            let tempData = {};
            if (tempCoords.length > 1) {
                for (let i = 0; i < tempCoords.length; i++) {
                    tempData = {};
                    tempData['coords'] = [];

                    for (let j = 0; j < tempCoords[i].length; j++) {
                        let tempVal = tempCoords[i][j];

                        let tempStructure = new firebase.firestore.GeoPoint(tempVal[1], tempVal[0]);
                        tempData.coords.push(tempStructure);
                    }

                    tempData['name']   = item.name;
                    tempData['deleted']   = false;

                    fieldData.polygons.push(tempData);
                }
            } else {
                tempData['coords'] = [];
                for (let j = 0; j < tempCoords[0].length; j++) {
                    let tempVal = tempCoords[0][j];

                    let tempStructure = new firebase.firestore.GeoPoint(tempVal[1], tempVal[0]);
                    tempData.coords.push(tempStructure);
                }

                tempData['name']   = item.name;
                tempData['deleted']   = false;
                fieldData.polygons.push(tempData);
            }
        });

        var vm = this;
        firestore()
            .collection(fieldUrl)
            .add(fieldData.field)
            .then(function (docRef) {
                fieldData.polygons.map((item, index) => {
                    firestore().collection(fieldUrl + "/" + docRef.id + "/polygons")
                    .add(item)
                        .then(function (polygonReference) {

                            let logsUrl = fieldUrl + "/" + docRef.id + "/polygons/" + polygonReference.id + "/logs"

                            let insertData = {};

                            insertData.crop = [crop.value, variety];
                            insertData.date = sowing_date;
                            insertData.deleted = false;
                            insertData.predecessor = [predecesor, predecesorVariety]
                            insertData.type = "seeding";

                            firestore()
                                .collection(logsUrl)
                                .add(insertData)
                                .then(function() {
                                    if (index === fieldData.polygons.length - 1) {
                                        vm.setState({
                                            addingStatus : false
                                        });
        
                                        // vm.showErrorToastr('Success');
                                        vm.props.history.push('/admin/cropmap/map');
                                    }
                                }).catch(function (error) {
                                    vm.setState({
                                        addingStatus : false
                                    });
                    
                                    vm.showErrorToastr('Error adding document: ' + error);
                                });

                        })
                        .catch(function (error) {
                            vm.setState({
                                addingStatus : false
                            });
                        })
                })
            })
            .catch(function (error) {
                vm.setState({
                    addingStatus : false
                });

                vm.showErrorToastr('Error adding document: ' + error);
            });
    };

    render() {
        return (
            <div className="content" style={{marginTop: "25px !important"}}>
                <ToastContainer
                    ref={ref => container = ref}
                    className="toast-top-right"
                />
                <Col className="mr-auto ml-auto" md="12">
                    <LoadingOverlay active={this.state.addingStatus} spinner text="Guardando datos...">
                        <ReactWizard
                            steps={steps}
                            navSteps
                            validate
                            title="Crear chacra nueva"
                            description="A continuación ingrese los datos de la nueva chacra"
                            headerTextCenter
                            finishButtonClasses="btn-wd"
                            nextButtonClasses="btn-wd"
                            previousButtonClasses="btn-wd"
                            finishButtonText="Finalizar"
                            previousButtonText="Anterior"
                            nextButtonText="Siguiente"
                            finishButtonClick={this.onFinishWizard}
                        />
                    </LoadingOverlay>
                </Col>
            </div>
        );
    }
}

export default AddField;
