import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from 'firebase';
import { Fertilization } from '../../../Models/Logs.js'
import CircularProgress from "@material-ui/core/CircularProgress";
import * as firebase from "firebase/app";

class FertilizationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: props.selectedLog ? props.selectedLog.date : null,
            inputValue: props.selectedLog ? props.selectedLog.value : null,
            inputNValue: props.selectedLog ? props.selectedLog.n : null,
            inputPValue: props.selectedLog ? props.selectedLog.p : null,
            inputKValue: props.selectedLog ? props.selectedLog.k : null,
            inputOtherValue: props.selectedLog ? props.selectedLog.other : null,
            inputOtherNameValue: props.selectedLog ? props.selectedLog.other_name : null,
            detailValue: props.selectedLog ? props.selectedLog.detail : null,

            //Control values
            dateValidState: true,
            loading: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedLog) {
            this.state = {
                date: nextProps.selectedLog ? nextProps.selectedLog.date : null,
                inputValue: nextProps.selectedLog ? nextProps.selectedLog.value : null,
                inputNValue: nextProps.selectedLog ? nextProps.selectedLog.n : null,
                inputPValue: nextProps.selectedLog ? nextProps.selectedLog.p : null,
                inputKValue: nextProps.selectedLog ? nextProps.selectedLog.k : null,
                inputOtherValue: nextProps.selectedLog ? nextProps.selectedLog.other : null,
                inputOtherNameValue: nextProps.selectedLog ? nextProps.selectedLog.other_name : null,
                detailValue: nextProps.selectedLog ? nextProps.selectedLog.detail : null,

                //Control values
                dateValidState: true,
                loading: false,
            }
        }
    }

    clearStatus() {
        this.setState({
            date: null,
            inputNValue: null,
            inputPValue: null,
            inputKValue: null,
            inputOtherValue: null,
            inputValue: null,
            inputOtherNameValue: null,
            detailValue: null,
            dateValidState: true,
            loading: false,
        })
    }

    isValidated = () => {

        var isValidated = true

        if (this.state.date == null) {
            this.setState({
                dateValidState: false
            });

            if (isValidated) {
                isValidated = false;
            }
        }

        return isValidated;
    }

    closeButtonTapped(success) {
        this.clearStatus()
        this.props.onClose(success)
    }

    saveButtonTapped() {
        let polygonsIds = Object.keys(this.props.selectedPolygons)
        if (this.isValidated() && polygonsIds.length > 0 && this.props.fieldId) {
            let insertData = {};
            insertData.type = "fertilization"
            insertData.value = parseFloat(this.state.inputValue)
            insertData.n = parseFloat(this.state.inputNValue)
            insertData.p = parseFloat(this.state.inputPValue)
            insertData.k = parseFloat(this.state.inputKValue)
            insertData.other = parseFloat(this.state.inputOtherValue)
            insertData.other_name = this.state.inputOtherNameValue
            insertData.detail = this.state.detailValue
            insertData.deleted = false
            insertData.date = firebase.firestore.Timestamp.fromDate(new Date(this.state.date));;
            let vm = this;
            this.setState({
                loading: true,
            })
            for (let i = 0; i < polygonsIds.length; i++) {
                let polygonId = polygonsIds[i]
                let logsUrl = "fields/" + this.props.fieldId + "/polygons/" + polygonId + "/logs"
                if (this.props.selectedLog) {
                    firestore()
                        .collection(logsUrl)
                        .doc(this.props.selectedLog.id)
                        .set(insertData, { merge: true })
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                } else {
                    firestore()
                        .collection(logsUrl)
                        .add(insertData)
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                }
            }
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
            >
                <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <h4 className="title title-up">Registro fertilización</h4>
                </div>
                <div className="modal-body">
                    <br />
                    <Row className="justify-content-center">
                        <Col sm={12} md={10} lg={8}>
                            <FormGroup>
                                <label>Fecha</label>
                                <ReactDatetime
                                    value={this.state.date}
                                    onChange={(value) => {
                                        this.setState({
                                            dateValidState: true,
                                            date: value,
                                        })
                                    }}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Ingrese fecha de registro",
                                    }}
                                    timeFormat={false}
                                />
                                {this.state.dateValidState === false ? (
                                    <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                ) : null}
                            </FormGroup>
                            {
                                <div>
                                    <FormGroup>
                                        <label>Kg/ha</label>
                                        <Input
                                            type="number"
                                            min={0}
                                            value={this.state.inputValue}
                                            onChange={(e) => this.setState({
                                                inputValue: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            {
                                <div>
                                    <FormGroup>
                                        <label>Nitrógeno (%)</label>
                                        <Input
                                            type="number"
                                            min={0}
                                            value={this.state.inputNValue}
                                            onChange={(e) => this.setState({
                                                inputNValue: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            {
                                <div>
                                    <FormGroup>
                                        <label>Fósforo (%)</label>
                                        <Input
                                            type="number"
                                            min={0}
                                            value={this.state.inputPValue}
                                            onChange={(e) => this.setState({
                                                inputPValue: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            {
                                <div>
                                    <FormGroup>
                                        <label>Potasio (%)</label>
                                        <Input
                                            type="number"
                                            min={0}
                                            value={this.state.inputKValue}
                                            onChange={(e) => this.setState({
                                                inputKValue: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            {
                                <div>
                                    <FormGroup>
                                        <label>Otro nutriente (%)</label>
                                        <Input
                                            type="number"
                                            min={0}
                                            value={this.state.inputOtherValue}
                                            onChange={(e) => this.setState({
                                                inputOtherValue: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            {
                                <div>
                                    <FormGroup>
                                        <label>Otro nutriente (nombre)</label>
                                        <Input
                                            type="text"
                                            value={this.state.inputOtherNameValue}
                                            onChange={(e) => this.setState({
                                                inputOtherNameValue: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            {
                                <div>
                                    <FormGroup>
                                        <label>Observación</label>
                                        <Input
                                            type="text"
                                            value={this.state.detailValue}
                                            onChange={(e) => this.setState({
                                                detailValue: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
                {
                    !this.state.loading ? <div className="modal-footer">
                        <div className="left-side">
                            <Button
                                className="btn-link"
                                color="danger"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.closeButtonTapped(false)}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button
                                className="btn-link"
                                color="primary"
                                type="button"
                                onClick={() => this.saveButtonTapped()}
                            >Guardar</Button>
                        </div>
                    </div> : <div className="text-center py-4">
                        <CircularProgress color="secondary" />
                    </div>
                }
            </Modal>
        );
    }

}
export default FertilizationForm;