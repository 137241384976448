import React, { useEffect, useState } from 'react';

import { Marker, Polygon, Popup } from "react-leaflet";

const SubPolygon = (props) => {
    const [hoverStatus, setHoverStatus] = useState(false);

    useEffect(() => {
        setHoverStatus(props.hoverStatus)
    }, [props.hoverStatus]);



    const getIconPosition = (positions) => {
        let latSum = 0;
        let lngSum = 0;

        for (let i = 0; i < positions.length; i++) {
            lngSum += positions[i][1];
            latSum += positions[i][0];
        }

        let result = {
            lat: latSum / positions.length,
            lng: lngSum / positions.length
        };

        return result;
    };

    return (
        <Polygon onClick={props.onClick} fillOpacity={hoverStatus === true ? 0.8 : 0.3}
            onMouseOver={() => setHoverStatus(true)}
            onMouseOut={() => setHoverStatus(false)}
            positions={props.positions}
        >
            {
                props.iconInfo ?

                    <Marker onClick={props.onClick} position={getIconPosition(props.positions)} icon={props.iconInfo}>
                        <Popup>{props.name}</Popup>
                    </Marker> :
                    <Popup>{props.name}</Popup>
            }

        </Polygon>
    )
};

export default SubPolygon;