export const config = {
    apiKey: "AIzaSyAyaj2BIThk27uSC8XfeCGE3hFchgrkC6M",
    authDomain: "oryzativa-webapp.firebaseapp.com",
    databaseURL: "https://oryzativa-webapp.firebaseio.com",
    projectId: "oryzativa-webapp",
    storageBucket: "oryzativa-webapp.appspot.com",
    messagingSenderId: "21313583683",
    appId: "1:21313583683:web:098602f6c07ad211f6f2f6",
    measurementId: "G-0KDVS6SECR"
  };



