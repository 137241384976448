import React, { useRef, useState, useEffect } from 'react';

import '../../../../assets/css/dropzone.css';
import {any} from "prop-types";

const Dropzone = (props) => {
    const fileInputRef = useRef();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        let filteredArr = selectedFiles.reduce((acc, current) => {
            const x = acc.find(item => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        setValidFiles([...filteredArr]);
        parseDocument(filteredArr);
    }, [selectedFiles]);

    const parseDocument = (files) => {
        for (let i = 0; i < files.length; i++) {
            let file = files[i];

            let fileReader = new FileReader();
            fileReader.onload = async (e) => {
                let result = await extractGoogleCoords(file.name, e.target.result);
                props.onAddKmlPolygon(result);
            };

            fileReader.readAsText(file);
        }
    };

    const extractGoogleCoords = async (fileName, plainText) => {
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(plainText, "text/xml");
        let googlePolygons = [];

        if (xmlDoc.documentElement.nodeName === "kml") {

            for (const item of xmlDoc.getElementsByTagName('Placemark')) {
                let placeMarkName = item.getElementsByTagName('name')[0] ? item.getElementsByTagName('name')[0].childNodes[0].nodeValue.trim() : `Polygon ${googlePolygons.length}`;
                let polygons = item.getElementsByTagName('Polygon');

                /** POLYGONS PARSE **/
                for (const polygon of polygons) {
                    let coords = polygon.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim();
                    let points = coords.split(" ");

                    let googlePolygonsPaths = [];
                    for (const point of points) {
                        let coord = point.split(",");
                        googlePolygonsPaths.push({ lat: +coord[1], lng: +coord[0] })
                    }
                    let tempItem = {};
                    tempItem.name = placeMarkName;
                    tempItem.coords = googlePolygonsPaths;
                    googlePolygons.push(tempItem);
                }
            }
        } else {
            throw "error while parsing"
        }

        return { fileName : fileName, polygons: googlePolygons }
    };

    const preventDefault = (e) => {
        e.preventDefault();
        // e.stopPropagation();
    };

    const dragOver = (e) => {
        preventDefault(e);
    };

    const dragEnter = (e) => {
        preventDefault(e);
    };

    const dragLeave = (e) => {
        preventDefault(e);
    };

    const fileDrop = (e) => {
        preventDefault(e);
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    };

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    };

    const fileInputClicked = () => {
        fileInputRef.current.click();
    };

    const handleFiles = (files) => {
        for(let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                files[i]['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage('File type not permitted');
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    };

    const validateFile = (file) => {
        const validType = '.kml';
        if (file.name.indexOf(validType) === -1) {
            return false;
        }

        return true;
    };

    const fileSize = (size) => {
        if (size === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    };

    const removeFile = (name) => {
        const index = validFiles.findIndex(e => e.name === name);
        const index2 = selectedFiles.findIndex(e => e.name === name);
        const index3 = unsupportedFiles.findIndex(e => e.name === name);
        validFiles.splice(index, 1);
        selectedFiles.splice(index2, 1);
        setValidFiles([...validFiles]);
        setSelectedFiles([...selectedFiles]);

        props.onRemoveKmlPolygon(name);
        if (index3 !== -1) {
            unsupportedFiles.splice(index3, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
    };

    return (
        <>
            <div style={{width : '100%'}}>
                {unsupportedFiles.length ? <p>Please remove all unsupported files.</p> : ''}
                <div className="drop-container"
                     onDragOver={dragOver}
                     onDragEnter={dragEnter}
                     onDragLeave={dragLeave}
                     onDrop={fileDrop}
                     onClick={fileInputClicked}
                >
                    <div className="drop-message">
                        <div className="upload-icon"></div>
                        Drag & Drop files here or click to select file(s)
                    </div>
                    <input
                        ref={fileInputRef}
                        accept=".kml"
                        className="file-input"
                        type="file"
                        multiple
                        onChange={filesSelected}
                    />
                </div>
                <div className="file-display-container">
                    {
                        validFiles.map((data, i) =>
                            <div className="file-status-bar" key={i}>
                                <div>
                                    <div className="file-type-logo"></div>
                                    <div className="file-type">{fileType(data.name)}</div>
                                    <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                                    <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                                </div>
                                <div className="file-remove" onClick={() => removeFile(data.name)}>X</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default Dropzone;
