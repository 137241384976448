import React, { Component } from 'react';
import { FeatureGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import firebase, { firestore } from 'firebase';
import CustomPolygons from "../../../components/Maps/customPolygons";
import RightSidebar from "../../../components/Maps/sideBar";
import MapPart from "../../../components/Maps/mapPart";
import LoadingOverlay from 'react-loading-overlay';
import {
    Button,
} from "reactstrap";
import { NavLink } from "react-router-dom";
class CropMap extends Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            centerLocation: { lat: -32, lng: -57 },
            zoom: 14,
            fieldList: [],
            fieldsUrl: 'fields',
            openSidebar: false,
            sideBarInfo: [],
            clickPolygon: [],
            currentUser: firebase.auth().currentUser,
            bounds: []
        }
    }

    onOpenSidebar = (polygon, field) => {

        this.setState({
            sideBarInfo: field,
            clickPolygon: polygon,

        }, () => {
            this.setState({
                openSidebar: true
            })
        });
    };

    getColor = (index) => {
        var colors = ["#88ebcd", "#aaffaa", "#00EE66", "#22AAcd", "#9999cd"];
        return colors[(index) % 5];
    };

    onCloseSidebar = () => {
        this.setState({
            openSidebar: false
        })
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let vm = this;
        let timeCount = 0;
        const fetch = async () => {
            let dataInfo = [];
            const fieldData = await firestore()
                .collection(this.state.fieldsUrl)
                .where("shared", "array-contains", firebase.auth().currentUser.uid)
                .orderBy("name")
                .get()
                .then(async function (fieldsReference) {
                    fieldsReference.docs.map((item, index) => {
                        if (item.exists === true) {
                            let tempData = item.data();
                            if (!tempData.deleted && tempData) {
                                tempData['id'] = item.id;
                                tempData['color'] = vm.getColor(index);
                                dataInfo.push(tempData);
                            }
                        }
                    });

                    if (dataInfo.length == 0) {
                        vm.setState({
                            fieldList: dataInfo,
                            showLoading: false,
                        }, () => {
                            vm.setBounds()
                        })
                    } else {
                        for (let i = 0; i < dataInfo.length; i++) {
                            let id = dataInfo[i]['id'];
                            let url = vm.state.fieldsUrl + "/" + id + '/polygons';
                            let polygons = [];
                            const polygonData = await firestore()
                                .collection(url)
                                .orderBy("name")
                                .get()
                                .then(async function (polygonsReference) {
                                    polygonsReference.docs.map(async (item) => {
                                        if (item.exists === true) {
                                            let tempData = item.data();
                                            if (!tempData.deleted) {
                                                tempData['id'] = item.id;

                                                let seedLog = url + "/" + item.id + "/logs";

                                                const seedLogData = await firestore()
                                                    .collection(seedLog)
                                                    .where("type", "==", "seeding")
                                                    .orderBy("date", "desc")
                                                    .limit(1)
                                                    .get()
                                                    .then(async function (seedLogReference) {
                                                        if (seedLogReference.docs.length > 0) {
                                                            let seedLogJson = seedLogReference.docs[0].data();
                                                            tempData['crop'] = seedLogJson["crop"][0];
                                                            tempData['variety'] = seedLogJson["crop"][1];
                                                            tempData['sowing_date'] = seedLogJson["date"]
                                                            polygons.push(tempData);
                                                            dataInfo[i]['polygons'] = polygons;
                                                            vm.setState({
                                                                fieldList: dataInfo,
                                                                showLoading: false,
                                                            }, () => {
                                                                vm.setBounds()
                                                            })
                                                        }
                                                    });
                                            }
                                        }
                                    })
                                });
                        }
                    }
                });
        };

        var timeInterval = setInterval(() => {
            if (firebase.auth().currentUser == null) {
                timeCount++;
                if (timeCount > 2) {
                    this.props.history.push('/auth/login');
                }
            } else {
                this.setState({
                    currentUser: firebase.auth().currentUser,
                    fieldsUrl: "fields"
                }, () => {
                    fetch();
                });
                clearInterval(timeInterval);
            }
        }, 1000);
    }

    setBounds = () => {
        let left = -180;
        let top = -90;
        let right = 180;
        let bottom = 90;

        let fieldList = this.state.fieldList;
        if (fieldList) {
            for (let i = 0; i < fieldList.length; i++) {
                let polygons = fieldList[i].polygons;
                if (polygons) {
                    for (let j = 0; j < polygons.length; j++) {
                        let coords = polygons[j].coords;
                        if (coords) {
                            for (let k = 0; k < coords.length; k++) {
                                let latitude = coords[k].latitude;
                                let longitude = coords[k].longitude;
                                if (latitude > top) {
                                    top = coords[k].latitude;
                                }

                                if (latitude < bottom) {
                                    bottom = coords[k].latitude;
                                }

                                if (longitude > left) {
                                    left = longitude;
                                }

                                if (longitude < right) {
                                    right = longitude;
                                }
                            }
                        }
                    }
                }
            }
        }


        let result = [
            [top, left],
            [bottom, right]
        ];

        if (fieldList.length > 0) {
            this.setState({
                bounds: result
            })

        } else {
            let result = [
                [-30, -59],
                [-35, -52]
            ];
            this.setState({
                bounds: result
            })

        }
        firestore().collection("update_queue").doc(this.state.currentUser.uid).set({ date: new Date().toISOString().substring(0, 13) });


    };

    render() {
        const { centerLocation, zoom } = this.state;

        return (
            <LoadingOverlay active={this.state.bounds.length > 0 ? false : true} spinner text={'Cargando ... '}>
                <>
                    <RightSidebar width={280} onHideSidebar={() => this.onCloseSidebar()} open={this.state.openSidebar} field={this.state.sideBarInfo} polygon={this.state.clickPolygon} />
                    {
                        this.state.bounds.length > 0 ?
                            <MapPart
                                bounds={this.state.bounds}
                                center={centerLocation}
                                zoom={zoom}
                                fieldList={this.state.fieldList}
                                onShowSidebar={(polygon, field) => this.onOpenSidebar(polygon, field)}
                            /> :
                            <div style={{ width: '100%', height: '100vh', backgroundColor: '#fff' }}></div>
                    }
                    <div style={{ position: 'fixed', bottom: '40px', right: '40px', textAlign: 'center', zIndex: 500 }}>
                        <Button className="btn-round btn-lg" style={{ zIndex: 500 }} color="success" tag={NavLink} to="/admin/cropmap/add-field">
                            <i className="fa fa-plus" />
                            Añadir chacra
                        </Button>
                    </div>
                </>
            </LoadingOverlay>
        );
    }
}

export default CropMap;
