import React from "react";

class Log {
    constructor(type, date, id) {
        this.type = type;
        this.date = date;
        this.id = id;
    }

    static getLogTypes() {
        var list = [];
        list.push({"value": "seeding", "label": "Siembra"})
        list.push({"value": "phenology", "label": "Fenología"})
        list.push({"value": "fertilization", "label": "Fertilización"})
        list.push({"value": "water", "label": "Riego"})
        list.push({"value": "field_log", "label": "Registro a campo"})
        list.push({"value": "lab_analytic", "label": "Dato analítico"})
        list.push({"value": "app_log", "label": "Aplicación"})
        list.push({"value": "yield", "label": "Rendimiento"})
        return list;
    } 

    static getCSVHeading() {
        return [
            {
              id: "ID",
              date: "FECHA",
              type: "TIPO",
              value: "VALOR",
              text: "TEXTO"
            }
          ];
    }

    toString() {
        return ""
    }

    render() {
        return false
    }

    getCSVData() {
        return {
            id: this.id,
            date: this.date.toLocaleDateString()
        }
    }
}

class Seeeding extends Log {
    constructor(type, date, id, cropName, cropVar) {
        super(type, date, id);
        this.cropName = cropName;
        this.cropVar = cropVar;
    }

    toString() {
        if (this.cropVar) {
            return `${this.cropName} ${this.cropVar}, siembra ${this.date.toLocaleDateString()}`
        } else {
            return `${this.cropName}, siembra ${this.date.toLocaleDateString()}`
        }
    }

    render() {
        return <tr key={this.id}>
            <td>- {this.toString()}</td>
        </tr>
    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        var valueString = this.cropName
        if (this.cropVar) {
            valueString += " " + this.cropVar
        }
        baseJSON["type"] = "Siembra";
        baseJSON["value"] = valueString;
        baseJSON["text"] = this.toString()
        return baseJSON
    }
}

class Phenology extends Log {
    constructor(type, date, id, value) {
        super(type, date, id);
        this.value = value;
    }


    valueToString() {
        switch (this.value) {
            case "flowering":
                return `50% Floración`
            case "primordium":
                return `Primordio`
            case "emergence":
                return `Emergencia`
            case "harvest":
                return `Cosecha`
            case "harvest_start":
                return `Inicio de cosecha`
            case "harvest_end":
                return `Fin de cosecha`
            default:
                return ""
        }
    }

    toString() {
        return `${this.valueToString()} ${this.date.toLocaleDateString()}`;
    }

    render() {
        return <tr key={this.id}>
            <td>- {this.toString()}</td>
        </tr>
    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        baseJSON["type"] = "Fenología";
        baseJSON["value"] = this.valueToString();
        baseJSON["text"] = this.toString();
        return baseJSON
    }

    static getPhenologyTypes() {
        return [{"value" : "flowering", "label": "50% Floración"}, {"value" : "primordium", "label": "Primordio"}, {"value" : "emergence", "label": "Emergencia"}]
    }
}

class Irrigation extends Log {
    constructor(type, date, id, value, obs) {
        super(type, date, id);
        this.value = value;
        this.obs = obs;
    }

    valueToString() {
        switch (this.value) {
            case "date_start":
                return `Inicio de riego`
            case "date_end":
                return `Fin de riego`
            case "date_complete":
                return `Parcela completa`
            case "date_bath":
                return `Baño para nacer`
            case "event":
                return `Evento`
            default: 
                return ""
        }
    }

    toString() {
        return `${this.valueToString()} ${this.date.toLocaleDateString()}`;
    }

    render() {
        if (this.obs && this.obs !== "") {
            return <tr key={this.id}>
                <td>- {this.toString()} <br></br><div style={{ paddingLeft: '2rem' }}>{this.obs} </div> </td>
            </tr>
        }
        return <tr key={this.id}>
            <td>- {this.toString()}</td>
        </tr>;

    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        baseJSON["type"] = "Riego";
        baseJSON["value"] = this.valueToString();
        baseJSON["text"] = this.toString();
        return baseJSON
    }

    static getIrrigationTypes() {
        return [{"value" : "date_start", "label": "Inicio de Riego"}, {"value" : "date_end", "label": "Fin del riego"}, {"value" : "date_complete", "label": "Riego completado"}, 
        {"value" : "date_bath", "label": "Baño"}, {"value" : "event", "label": "Evento"}]
    }
}

class Yield extends Log {
    constructor(type, date, id, value) {
        super(type, date, id);
        this.value = value;
    }

    toString() {
        return `Rendimiento ${this.value} kg/ha SL cosechados el ${this.date.toLocaleDateString()}`
    }

    render() {
        return <tr key={this.id}>
            <td>- Rendimiento {this.value} kg/ha SL cosechados el {this.date.toLocaleDateString()}</td>
        </tr>
    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        baseJSON["type"] = "Rendimiento";
        baseJSON["value"] = this.value;
        baseJSON["text"] = this.toString();
        return baseJSON
    }
}

class Fertilization extends Log {
    constructor(type, date, id, n, p, k, other, value, other_name, detail) {
        super(type, date, id);
        this.value = value;
        this.n = n;
        this.p = p;
        this.k = k;
        this.other = other;
        this.other_name = other_name;
        this.detail = detail
    }

    toString() {
        if (this.other && this.other_name) {
            return `Fertilización con ${this.value} kg/ha de (${this.n} - ${this.p} - ${this.k} - ${this.other} - ${this.other_name}) el ${this.date.toLocaleDateString()}`
        } else {
            return `Fertilización con ${this.value} kg/ha de (${this.n} - ${this.p} - ${this.k}) el ${this.date.toLocaleDateString()}`
        }
    }

    render() {
        return <tr key={this.id}>
                <td>- {this.toString()}</td>
        </tr>
    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        baseJSON["type"] = "Fertilización";
        baseJSON["value"] = this.value;
        baseJSON["text"] = this.toString();
        return baseJSON
    }
}

class Presence extends Log {
    constructor(type, date, id, values) {
        super(type, date, id);
        this.values = values;
    } 

    valueToString() {
        switch (this.type) {
            case "weed_presence":
                return `Malezas`
            case "disease_presence":
                return `Enfermedades`
            case "bug_presence":
                return `Plagas`
            default:
                return ""
        }
    }

    toString(){
        var log = `${this.valueToString()} ${this.date.toLocaleDateString()}`
        var valuesString = ""
        if (this.values && this.values.length > 0) {
            valuesString = this.values
                .reduce((prevValue, currentValue) => prevValue + `${currentValue.trim()}, `, ``)
            valuesString = valuesString.substring(0, valuesString.length - 2);
        }
        if (valuesString !== "") {
            return `${log} ${valuesString}`
        } else {
            return `${log}`
        }
    }

    render() {
        return <tr key={this.id}>
                <td>- {this.toString()}</td>
            </tr>
    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        baseJSON["type"] = this.valueToString();
        baseJSON["value"] = "";
        baseJSON["text"] = this.toString();
        return baseJSON
    }
}

class Application extends Log {
    constructor(type, date, id, herb, trigger) {
        super(type, date, id);
        this.herb = herb;
        this.trigger = trigger;
    }

    toString(){
        var herbString = this.herb
        .reduce((prevValue, currentValue) => prevValue + `${currentValue["id"]} (${currentValue["dose"]} L/ha), `, ``)
        herbString = herbString.substring(0, herbString.length - 2);
        return herbString;
    }

    render() {
        return <tr key={this.id}>
            <td>- Aplicación de {this.toString()} el {this.date.toLocaleDateString()}</td>
        </tr>
    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        baseJSON["type"] = "Aplicación";
        baseJSON["value"] = "";
        baseJSON["text"] = this.toString();
        return baseJSON
    }

    static getApplicationTypes() {
        return [{"value" : "herbicide", "label": "Herbicida"}, {"value" : "fungicide", "label": "Fungicida"}, {"value" : "insecticide", "label": "Insecticida"}]
    }
}

class LabAnalytics extends Log {
    constructor(type, date, id, data) {
        super(type, date, id);
        this.data = data;
    }

    toString() {
        var labString = "";
        Object.keys(this.data).map((key, index) => {
            labString+= `${key} / ${this.data[key]}, `;
        });
        labString = labString.substring(0, labString.length - 2);
        return labString;
    }

    render() {
        var log = `- Análisis el ${this.date.toLocaleDateString()}:\n`
        return <tr key={this.id}>
            <td>{log}
                {
                    Object.keys(this.data).map((key, index) => {
                        return <><br></br><div key={index} style={{ paddingLeft: '2rem', display: 'flex', justifyContent: 'space-between', width: '20rem' }}>
                            {key}
                            <div style={{ paddingLeft: '2rem' }}>
                                {this.data[key]}
                            </div>
                        </div></>
                    })
                }
            </td>
        </tr>
    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        baseJSON["type"] = "Dato de análisis";
        baseJSON["value"] = "";
        baseJSON["text"] = this.toString();
        return baseJSON
    }

    static getLabAnalyticsTypes() {
        return [{"value" : "pH H2O", "label": "pH H2O", "inputValue": 0}, {"value" : "M.O.", "label": "M.O.", "inputValue": 0}, {"value" : "PMN (mg/kg N-NH4)", "label": "PMN (mg/kg N-NH4)", "inputValue": 0},
        {"value" : "N-NO3 (ppm)", "label": "N-NO3 (ppm)", "inputValue": 0}, {"value" : "P Citrico (ppm)", "label": "P Citrico (ppm)", "inputValue": 0}, {"value" : "P Bray 1(ppm)", "label": "P Bray 1(ppm)", "inputValue": 0},
        {"value" : "K (meq/100g)", "label": "K (meq/100g)", "inputValue": 0}, {"value" : "Mg (meq/100g)", "label": "Mg (meq/100g)", "inputValue": 0}, {"value" : "Ca (meq/100g)", "label": "Ca (meq/100g)", "inputValue": 0},
        {"value" : "Na (meq/100g)", "label": "Na (meq/100g)", "inputValue": 0}, {"value" : "Zn (ppm)", "label": "Zn (ppm)", "inputValue": 0}, {"value" : "CIC-pH7 (meq/100g)", "label": "CIC-pH7 (meq/100g)", "inputValue": 0}]
    }
}

class DataField extends Log {
    constructor(type, date, id, variable, value, unit) {
        super(type, date, id);
        this.variable = variable;
        this.value = value;
        this.unit = unit;
    }

    toString() {
        return `${this.variable} ${this.value} ${this.unit} el ${this.date.toLocaleDateString()}`;
    }

    render() {
        return <tr key={this.id}>
            <td>- {this.toString()}</td>
        </tr>
    }

    getCSVData() {
        let baseJSON = super.getCSVData();
        baseJSON["type"] = `${this.variable} (${this.unit})`;
        baseJSON["value"] = this.value;
        baseJSON["text"] = this.toString();
        return baseJSON
    }

    static getDataFieldTypes() {
        return [{"value": "data_campo", "label": "Altura", "unit": "cm"}, {"value": "data_campo", "label": "Cobertura", "unit": "%"}, {"value": "data_campo", "label": "Densidad", "unit": "pl/m2"},
        {"value": "data_campo", "label": "Estimación de rendimiento", "unit": "kg/ha"}, {"value": "data_campo", "label": "Tallos", "unit": "tallos/m2"}, {"value": "disease_presence", "label": "Enfermedades"},
        {"value": "bug_presence", "label": "Plagas"}, {"value": "weed_presence", "label": "Malezas"} ]
    }
}

export {Log,DataField,LabAnalytics,Application,Presence,Fertilization, Irrigation, Yield, Phenology, Seeeding }