
import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import * as firebase from "firebase/app";
import { firestore } from 'firebase';
import CircularProgress from "@material-ui/core/CircularProgress";

class CreateAndEditSeedForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            crop: null,
            variety: null,
            sowing_date: props.selectedLog ? props.selectedLog.date : null,
            predecesor: null,
            cropArr: [],
            varietyArr: [],

            cropState: true,
            predecesorState: true,
            sowingDateState: true,

            predecessorArray: [{
                "value": "ARR",
                "label": "Arroz"
            }, {
                "value": "SOJ",
                "label": "Soja"
            }, {
                "value": "PRA",
                "label": "Pradera"
            }, {
                "value": "CN",
                "label": "Campo natural"
            }, {
                "value": "LAB",
                "label": "Laboreo de verano"
            }],
            loading: false,
            predecesorVariety: null,
            predecesorVarietyArr: [],
        }
    }

    fetchCrops = async () => {
        let cropsUrl = "crops/"
        let vm = this;
        const cropsData = await firestore().collection(cropsUrl).get();

        let dataInfo = [];

        cropsData.docs.map((item, index) => {
            if (item.exists === true) {
                let tempData = item.data();
                let tempArr = {};
                tempArr['value'] = item.id;
                tempArr['label'] = tempData['name'];
                dataInfo.push(tempArr);
            }
        });

        this.setState({
            cropArr: dataInfo,
        }, () => {
            if (vm.props.selectedLog && vm.props.selectedLog.cropName) {
                let crop = dataInfo.filter(crop => crop.label === vm.props.selectedLog.cropName)[0]
                if (crop) {
                    let variety = vm.props.selectedLog.cropVar
                    this.changeCrop(crop, false, variety)
                }
            }
        });
    };

    fetchVarieties = async (value, predecesor, preSelectedVariety) => {
        let cropsUrl = "crops/"
        let url = cropsUrl + value.value + "/varieties/";
        let varietyData = await firestore().collection(url).get();

        let dataInfo = [];

        varietyData.docs.map((item, index) => {
            let tempArr = {};
            tempArr['value'] = item.id;
            tempArr['label'] = item.id;
            dataInfo.push(tempArr);
        });


        if (predecesor) {
            this.setState({
                predecesorVarietyArr: dataInfo,
                predecesorVariety: dataInfo.length > 0 ? dataInfo[0] : null
            });
        } else {
            this.setState({
                varietyArr: dataInfo,
                variety: preSelectedVariety ? dataInfo.filter(variety => variety.label === preSelectedVariety)[0] : dataInfo.length > 0 ? dataInfo[0] : null
            });
        }
    };

    changeCrop = (value, predecesor, preSelectedVariety) => {
        if (predecesor) {
            if (value.value == "ARR") {
                this.fetchVarieties(value, predecesor, null);
                this.setState({
                    predecesor: value,
                    predecesorState: true
                })
            } else {
                this.setState({
                    predecesor: value,
                    predecesorState: true,
                    predecesorVarietyArr: [],
                    predecesorVariety: null
                })
            }
        } else {
            this.fetchVarieties(value, predecesor, preSelectedVariety);
            this.setState({
                crop: value,
                cropState: true
            })
        }
    };

    componentDidMount() {
        this.fetchCrops()
    }

    saveButtonTapped() {
        if (this.isValidated()) {

            let insertData = {};

            insertData.date = firebase.firestore.Timestamp.fromDate(new Date(this.state.sowing_date));
            insertData.crop = [this.state.crop.value, this.state.variety ? this.state.variety.value : ''];
            insertData.deleted = false;
            insertData.predecessor = [this.state.predecesor.value, this.state.predecesorVariety ? this.state.predecesorVariety.value : '']
            insertData.type = "seeding";

            let vm = this;
            this.setState({
                loading: true,
            })
            let polygonsIds = Object.keys(this.props.selectedPolygons)
            if (polygonsIds.length > 0 && this.props.fieldId) {
                for (let i = 0; i < polygonsIds.length; i++) {
                    let polygonId = polygonsIds[i]
                    let logsUrl = "fields/" + this.props.fieldId + "/polygons/" + polygonId + "/logs"
                    if (this.props.selectedLog) {
                        firestore()
                            .collection(logsUrl)
                            .doc(this.props.selectedLog.id)
                            .set(insertData, { merge: true })
                            .then(function () {
                                if (i === polygonsIds.length - 1) {
                                    vm.setState({
                                        loading: false
                                    })
                                    vm.closeButtonTapped(true)
                                }
                            })
                    } else {
                        firestore()
                            .collection(logsUrl)
                            .add(insertData)
                            .then(function () {
                                if (i === polygonsIds.length - 1) {
                                    vm.setState({
                                        loading: false
                                    })
                                    vm.closeButtonTapped(true)
                                }
                            })
                    }
                }
            }
        }
    }

    clearStatus() {
        this.setState({
            crop: null,
            variety: null,
            predecesor: null,
            sowing_date: null,
            varietyArr: [],
            predecesorVariety: null,
            predecesorVarietyArr: []
        })
    }

    isValidated = () => {

        var isValidated = true

        if (this.state.crop === null) {
            this.setState({
                cropState: false
            });
            isValidated = false;
        }

        if (this.state.sowing_date == null) {
            this.setState({
                sowingDateState: false
            });

            if (isValidated) {
                isValidated = false;
            }
        }

        if (this.state.predecesor === null) {
            this.setState({
                predecesorState: false
            });
            if (isValidated) {
                isValidated = false;
            }
        }
        return isValidated;
    };

    closeButtonTapped(success) {
        this.clearStatus()
        this.props.onClose(success)
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
            >
                <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <h4 className="title title-up">Registro de Siembra</h4>
                </div>
                <div className="modal-body">
                    <br />
                    <Row className="justify-content-center">
                        <Col sm={12} md={10} lg={8}>
                            {
                                this.state.cropArr.length > 0 &&
                                <FormGroup>
                                    <label>Cultivo</label>
                                    <Select
                                        name="crop"
                                        className="react-select"
                                        placeholder="Seleccione cultivo"
                                        classNamePrefix="react-select"
                                        value={this.state.crop}
                                        onChange={(value) => this.changeCrop(value, false, null)}
                                        options={this.state.cropArr}
                                    />
                                    {this.state.cropState === false ? (
                                        <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                    ) : null}
                                </FormGroup>
                            }
                        </Col>
                        <Col sm={12} md={10} lg={8}>
                            {
                                this.state.varietyArr.length > 0 &&
                                <FormGroup>
                                    <label>Variedad</label>
                                    <Select
                                        name="variety"
                                        className="react-select"
                                        placeholder="Seleccione variedad"
                                        classNamePrefix="react-select"
                                        value={this.state.variety}
                                        onChange={(value) => this.setState({
                                            variety: value
                                        })}
                                        options={this.state.varietyArr}
                                    />
                                </FormGroup>
                            }
                        </Col>
                        <Col sm="8">
                            {
                                this.state.predecessorArray.length > 0 &&
                                <FormGroup>
                                    <label>Antecesor</label>
                                    <Select
                                        name="crop"
                                        className="react-select"
                                        placeholder="Seleccionar cultivo"
                                        classNamePrefix="react-select"
                                        value={this.state.predecesor}
                                        onChange={(value) => this.changeCrop(value, true, null)}
                                        options={this.state.predecessorArray}

                                    />
                                    {this.state.predecesorState === false ? (
                                        <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                    ) : null}
                                </FormGroup>
                            }
                        </Col>
                        <Col sm="8">
                            {
                                this.state.predecesorVarietyArr.length > 0 &&
                                <FormGroup>
                                    <label>Variedad antecesor</label>
                                    <Select
                                        name="variety"
                                        className="react-select"
                                        placeholder="Seleccionar variedad"
                                        classNamePrefix="react-select"
                                        value={this.state.predecesorVariety}
                                        onChange={(value) => this.setState({
                                            predecesorVariety: value
                                        })}
                                        options={this.state.predecesorVarietyArr}
                                    />
                                </FormGroup>
                            }
                        </Col>
                        <Col sm={12} md={10} lg={8}>
                            <FormGroup>
                                <label>Fecha de siembra</label>
                                <ReactDatetime
                                    value={this.state.sowing_date}
                                    onChange={(value) => {
                                        this.setState({
                                            sowingDateState: true,
                                            sowing_date: value,
                                        })
                                    }}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Ingrese fecha de siembra",
                                    }}
                                    timeFormat={false}
                                />
                                {this.state.sowingDateState === false ? (
                                    <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {
                    !this.state.loading ? <div className="modal-footer">
                        <div className="left-side">
                            <Button
                                className="btn-link"
                                color="danger"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.closeButtonTapped(false)}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button
                                className="btn-link"
                                color="primary"
                                type="button"
                                onClick={() => this.saveButtonTapped()}
                            >Guardar</Button>
                        </div>
                    </div> : <div className="text-center py-4">
                        <CircularProgress color="secondary" />
                    </div>
                }
            </Modal>
        );
    }
}
export default CreateAndEditSeedForm;