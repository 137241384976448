/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {
  FirebaseAuthProvider,

} from "@react-firebase/auth";
import { FirestoreProvider } from "@react-firebase/firestore";
import { config } from "config";


// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

import Select from "react-select";



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

function getErrorMsg(msg) {
  if (msg.includes("is already in use")) { return "Ya existe una cuenta con su email, pruebe iniciar sesión."; }
  return msg;
}

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      emailS: "",
      nombreS: "",
      apellidoS: "",
      phoneS: "",
      passwordS: "",
      password2S: "",
      empresaS: "",

      email: "",
      nombre: "",
      apellido: "",
      phone: "",
      password: "",
      password2: "",
      alert: null,
      empresa: "",
      pais: "",
      "terminos": false
    };
  }

  componentDidMount() {
    document.body.classList.toggle("register-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }
  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  verifyNumber = (value) => {
    var numberRex = new RegExp("^[+]{0,1}[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "S"]: "has-success" });
        } else {
          this.setState({ [stateName + "S"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, 2)) {
          this.setState({ [stateName + "S"]: "has-success" });
        } else {
          this.setState({ [stateName + "S"]: "has-danger" });
        }
        break;
      case "pwd":
        if (this.verifyLength(event.target.value, 6)) {
          this.setState({ [stateName + "S"]: "has-success" });
        } else {
          this.setState({ [stateName + "S"]: "has-danger" });
        }
        break;
      case "equal":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "S"]: "has-success" });
          this.setState({ [stateNameEqualTo + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "S"]: "has-danger" });
          this.setState({ [stateNameEqualTo + "State"]: "has-danger" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "S"]: "has-success" });
        } else {
          this.setState({ [stateName + "S"]: "has-danger" });
        }
        break;

      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };
  createError = (error) => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          {getErrorMsg(error.message)}
        </ReactBSAlert>
      ),
    });
    setTimeout(this.hideAlert, 5000);
  };
  createUser = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Creando usuario"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          En breves será dirigido al inicio.
        </ReactBSAlert>
      ),
    });
    setTimeout(this.hideAlert, 5000);
  };
  checkClick = (firebase) => {
    var register = true;
    if (this.state.emailS === "") {
      register = false;
      this.setState({ emailS: "has-danger" });
    }
    if (this.state.nombreS === "") {
      register = false;
      this.setState({ nombreS: "has-danger" });
    }
    if (this.state.apellidoS === "") {
      register = false;
      this.setState({ apellidoS: "has-danger" });
    }
    if (this.state.phoneS === "") {
      register = false;
      this.setState({ phoneS: "has-danger" });
    }
    if (this.state.empresaS === "") {
      register = false;
      this.setState({ empresaS: "has-danger" });
    }
    if (
      this.state.passwordS === "" ||
      this.state.password2S === ""
    ) {
      register = false;
      this.setState({ passwordS: "has-danger" });
      this.setState({ password2S: "has-danger" });
    }

    if (this.state.pais)

    if (this.state.emailS == "has-danger" || this.state.nombreS == "has-danger" || this.state.apellidoS == "has-danger" || this.state.empresaS == "has-danger" || this.state.password2S == "has-danger" || this.state.passwordS == "has-danger" || this.state.phoneS == "has-danger") {
      register = false;
    }
    if (!this.state.terminos) {
      register = false;
      this.createError({ "message": "Debe leer y aceptar los terminos y condiciones." });
    }
    if (register) {
      this.createUser();
      firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((result) => {
        var userRef = firebase.firestore().collection('usuarios').doc(result.user.uid);
        result.user.updateProfile({
          displayName: this.state.nombre
        });
        userRef.set({
          'fecha_creacion': firebase.firestore.FieldValue.serverTimestamp(),
          'nombre': this.state.nombre,
          'apellido': this.state.apellido,
          'telefono': this.state.phone,
          'email': this.state.email,
          'empresa': this.state.empresa,
          'pais': this.state.pais,
        });
        result.user.sendEmailVerification();
      }).catch((error) => {
        this.createError(error);
      });
    }
  };



  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {

    let {
      emailS,
      nombreS,
      apellidoS,
      phoneS,
      passwordS,
      password2S,
      empresaS,

      email,
      nombre,
      apellido,
      phone,
      password,
      password2,
      pais,
      empresa,
      terminos
    } = this.state;
    return (
      <FirestoreProvider {...config} firebase={firebase}>
        <FirebaseAuthProvider {...config} firebase={firebase}>

          <div className="register-page">
            <Container>
              {this.state.alert}
              <Row>
                <Col className="ml-auto" lg="5" md="5">
                  <div className="info-area info-horizontal mt-5">
                    <div className="icon icon-primary">
                      <i className="fi flaticon-satelite" />
                    </div>
                    <div className="description">
                      <h5 className="info-title">Objetivo</h5>
                      <p className="description" style={{ textAlign: 'left' }}>
                        Oryzativa nace con el objetivo de aportar soluciones al sector arrocero Uruguayo.
                      </p>
                    </div>
                  </div>
                  <div className="info-area info-horizontal">
                    <div className="icon icon-primary">
                      <i className="fi flaticon-intercambio" />
                    </div>
                    <div className="description">
                      <h5 className="info-title">Alcance</h5>
                      <p className="description" style={{ textAlign: 'left' }}>Es un proyecto abierto y dinámico que se fortalece con el aporte de sus usuarios.
                      </p>
                    </div>
                  </div>
                  <div className="info-area info-horizontal">
                    <div className="icon icon-info">
                      <i className="fi flaticon-arroz" />
                    </div>
                    <div className="description">
                      <h5 className="info-title">Motivo</h5>
                      <p className="description" style={{ textAlign: 'left' }}>
                        El Arroz nos identifica.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" lg="4" md="6">
                  <Card className="card-signup text-center">
                    <CardHeader>
                      <CardTitle tag="h4">Crear nueva cuenta</CardTitle>

                    </CardHeader>
                    <CardBody>
                      <Form action="" className="form" method="">
                        <InputGroup className={`has-label ${nombreS}`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Nombre" type="text" onChange={(e) =>
                            this.change(e, "nombre", "length")
                          }
                          />
                          {this.state.nombreS === "has-danger" ? (
                            <label className="error">
                              Ingrese su nombre.
                            </label>
                          ) : null}
                        </InputGroup>
                        <InputGroup className={`has-label ${apellidoS}`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Apellido" type="text" onChange={(e) =>
                            this.change(e, "apellido", "length")
                          }
                          />
                          {this.state.apellidoS === "has-danger" ? (
                            <label className="error">
                              Ingrese su apellido.
                            </label>
                          ) : null}
                        </InputGroup>
                        <InputGroup className={`has-label ${empresaS}`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-briefcase-24" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Empresa" type="text" onChange={(e) =>
                            this.change(e, "empresa", "length")
                          }
                          />
                          {this.state.empresaS === "has-danger" ? (
                            <label className="error">
                              Indique empresa.
                            </label>
                          ) : null}
                        </InputGroup>
                        <InputGroup className={`has-label ${emailS}`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Email" type="email" autoComplete="UserName" onChange={(e) =>
                            this.change(e, "email", "email")
                          }
                          />
                          {this.state.emailS === "has-danger" ? (
                            <label className="error">
                              Dirección de correo invalida.
                            </label>
                          ) : null}
                        </InputGroup>
                        <InputGroup className={`has-label ${phoneS}`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-mobile" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Telefono" type="tel" onChange={(e) =>
                            this.change(e, "phone", "number")
                          } />
                          {this.state.phoneS === "has-danger" ? (
                            <label className="error">
                              Teléfono inválido.
                            </label>
                          ) : null}
                        </InputGroup>
                        <InputGroup className={`has-label ${passwordS}`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Contraseña" type="password" autoComplete="password" onChange={(e) =>
                            this.change(e, "password", "pwd")
                          } />
                          {this.state.passwordS === "has-danger" ? (
                            <label className="error">
                              Contraseña invalida.
                            </label>
                          ) : null}
                        </InputGroup>
                        <InputGroup className={`has-label ${password2S}`}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Confirmar contraseña" type="password" onChange={(e) =>
                            this.change(
                              e,
                              "password2",
                              "equal",
                              "password"
                            )
                          } />
                          {this.state.password2S === "has-danger" ? (
                            <label className="error">
                              Confirmación de contraseña invalida.
                            </label>
                          ) : null}
                        </InputGroup>

                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={this.state.pais}
                          onChange={(country) =>
                            this.setState({ pais: country.value })
                          }
                          options={[
                            { value: "AR", label: "Argentina" },
                            { value: "BR", label: "Brasil" },
                            { value: "UY", label: "Uruguay" },
                          ]}
                          placeholder="Pais"
                        />




                        <FormGroup check className="text-left">
                          <Label check>
                            <Input type="checkbox" onChange={(e) =>
                              this.setState({ terminos: e.target.checked })
                            } />
                            <span className="form-check-sign" />Acepto los{" "}
                            <a href="#pablo" onClick={(e) => {
                              e.preventDefault();
                            }}
                            >
                              terminos y condiciones.
                            </a>

                          </Label>
                        </FormGroup>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Button
                        className="btn-round"
                        color="info"
                        href="#pablo"
                        onClick={() => { this.checkClick(firebase) }}
                      >
                        Crear cuenta
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div
              className="full-page-background"
              style={{
                backgroundImage: `url(${require("assets/img/bg/arroz.jpg")})`,
              }}
            />
          </div>
        </FirebaseAuthProvider>
      </FirestoreProvider>
    );
  }
}

export default Register;
