class LogTypeFilter {
    constructor(label, types, active) {
        this.label = label;
        this.types = types;
        this.active = active;
    }

    static getFilters() {
        var list = [];
        list.push(new LogTypeFilter("Siembra", ["seeding"], true));
        list.push(new LogTypeFilter("Fenología", ["phenology"], true));
        list.push(new LogTypeFilter("Fertilización", ["fertilization"], true));
        list.push(new LogTypeFilter("Riego", ["water"], true));
        list.push(new LogTypeFilter("Registro a campo", ["disease_presence",
            "bug_presence",
            "weed_presence",
            "data_campo"], true));
        list.push(new LogTypeFilter("Dato analítico", ["lab_analytic"], true));
        list.push(new LogTypeFilter("Aplicación", ["herbicide", "fungicide", "insecticide"], true));
        list.push(new LogTypeFilter("Rendimiento", ["yield"], true));
        return list;
    }
}

export {LogTypeFilter}