import FileSaver from "file-saver";
import XLSX from "xlsx";

class ExportToCSV {
    static export(jsonData, Heading, headers, fileName, wscols) {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const wb = XLSX.utils.book_new();
        Object.keys(jsonData).map((key) => {
            var ws = XLSX.utils.json_to_sheet(Heading, {
                header: headers,
                skipHeader: true,
                origin: 0 //ok
              });
              ws["!cols"] = wscols;
              XLSX.utils.sheet_add_json(ws, jsonData[key], {
                header: headers,
                skipHeader: true,
                origin: -1 //ok
              });
              XLSX.utils.book_append_sheet(wb, ws, key);
        });
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
}

export {ExportToCSV}