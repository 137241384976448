import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from 'firebase';
import { LabAnalytics, Irrigation, Phenology } from '../../../Models/Logs.js'
import CircularProgress from "@material-ui/core/CircularProgress";
import * as firebase from "firebase/app";
import Chip from '../../components/Chip.js'

class GenericDateAndSelectForm extends Component {

    constructor(props) {
        super(props);

        var eventTypes = []
        var logType = null
        var selectedEventType = null
        var selectedChemichals = []

        if (props.type) {
            logType = props.type
        } else if (props.selectedLog) {
            logType = props.selectedLog.type
        }

        if (logType === "phenology") {
            eventTypes = Phenology.getPhenologyTypes();
        } else if (logType === "lab_analytic") {
            eventTypes = LabAnalytics.getLabAnalyticsTypes();
        } else if (logType === "water") {
            eventTypes = Irrigation.getIrrigationTypes();
        }

        if (props.selectedLog) {
            if (logType === "lab_analytic") {
                if (props.selectedLog.data) {
                    selectedChemichals = eventTypes.filter(item => props.selectedLog.data.hasOwnProperty(item.value) )
                }
            } else {
                if (props.selectedLog.value) {
                    selectedEventType =  eventTypes.filter(item => item.value === props.selectedLog.value)
                }
            }
        }

        this.state = {
            logType: logType,
            date: props.selectedLog ? props.selectedLog.date : null,

            eventTypes: eventTypes,

            selectedEventType: selectedEventType,
            selectedChemichals: selectedChemichals,

            waterObsInput: props.selectedLog ? props.selectedLog.obs : null,

            //Control values
            dateValidState: true,
            eventTypeState: true,
            chemichalSelectState: true,
            loading: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        var eventTypes = []
        var logType = null
        var selectedEventType = null
        var selectedChemichals = []

        if (nextProps.type) {
            logType = nextProps.type
        } else if (nextProps.selectedLog) {
            logType = nextProps.selectedLog.type
        }

        if (logType === "phenology") {
            eventTypes = Phenology.getPhenologyTypes();
        } else if (logType === "lab_analytic") {
            eventTypes = LabAnalytics.getLabAnalyticsTypes();
        } else if (logType === "water") {
            eventTypes = Irrigation.getIrrigationTypes();
        }

        if (nextProps.selectedLog) {
            if (logType === "lab_analytic") {
                if (nextProps.selectedLog.data) {
                    selectedChemichals = eventTypes.filter(item => nextProps.selectedLog.data.hasOwnProperty(item.value) )
                }
            } else {
                if (nextProps.selectedLog.value) {
                    selectedEventType =  eventTypes.filter(item => item.value === nextProps.selectedLog.value)
                }
            }
        }

        this.state = {
            logType: logType,
            date: nextProps.selectedLog ? nextProps.selectedLog.date : null,

            eventTypes: eventTypes,

            selectedEventType: selectedEventType,
            selectedChemichals: selectedChemichals,

            waterObsInput: nextProps.selectedLog ? nextProps.selectedLog.obs : null,

            //Control values
            dateValidState: true,
            eventTypeState: true,
            chemichalSelectState: true,
            loading: false,
        }
    }

    clearStatus() {
        this.setState({
            date: null,
            eventTypes: [],
            selectedEventType: null,
            waterObsInput: null,
            logType: null,
            dateValidState: true,
            eventTypeState: true,
            chemichalSelectState: true,
            loading: false,
        })
    }

    isValidated = () => {
        var isValidated = true
        if (this.state.date == null) {
            this.setState({
                dateValidState: false
            });

            if (isValidated) {
                isValidated = false;
            }
        }

        if (this.shouldShowSelect()) {
            if (this.state.selectedEventType == null) {
                this.setState({
                    eventTypeState: false
                });

                if (isValidated) {
                    isValidated = false;
                }
            }
        } else {
            if (this.state.selectedChemichals.length === 0) {
                this.setState({
                    chemichalSelectState: false
                });

                if (isValidated) {
                    isValidated = false;
                }
            }
        }

        return isValidated;
    }

    closeButtonTapped(success) {
        this.clearStatus()
        this.props.onClose(success)
    }

    changeSelectedEventType(value) {
        this.setState({
            selectedEventType: value
        })
    }

    selectChemical(value) {
        if (this.state.selectedChemichals.indexOf(value) <= -1) {
            this.state.selectedChemichals.push(value)
            this.setState({})
        }
    }

    onChangeInput = (input) => {
        let chemicalIndex = input.target.id
        if (this.state.selectedChemichals && this.state.selectedChemichals.length > 0 && this.state.selectedChemichals[chemicalIndex]) {
            let agroChemichal = this.state.selectedChemichals[chemicalIndex]
            agroChemichal["inputValue"] = input.target.value
            this.setState({})
        }
    }

    onDeleteTapped = (input) => {
        let chemicalIndex = input.target.id
        if (this.state.selectedChemichals && this.state.selectedChemichals.length > 0 && this.state.selectedChemichals[chemicalIndex]) {
            let agroChemichal = this.state.selectedChemichals[chemicalIndex]
            let filteredArray = this.state.selectedChemichals.filter(item => item.value !== agroChemichal.label)
            this.setState({ selectedChemichals: filteredArray })
        }
    }

    saveButtonTapped() {
        let polygonsIds = Object.keys(this.props.selectedPolygons)
        if (this.isValidated() && polygonsIds.length > 0 && this.props.fieldId) {
            let insertData = {};
            insertData.type = this.state.logType

            if (this.shouldShowSelect()) {
                if (this.state.selectedEventType) {
                    insertData.value = this.state.selectedEventType.value
                } else {
                    insertData.value = null
                }
            } else {
                if (this.state.selectedChemichals && this.state.selectedChemichals.length > 0) {
                    let chemichalData = {}
                    for (let k = 0; k < this.state.selectedChemichals.length; k++) {
                        let item = this.state.selectedChemichals[k];
                        chemichalData[item["value"]] = parseFloat(item["inputValue"]);
                    }
                    insertData.data = chemichalData
                } else {
                    insertData.data = null
                }
            }

            if (this.shouldShowObs()) {
                insertData.obs = this.state.waterObsInput;
            }

            insertData.deleted = false
            insertData.date = firebase.firestore.Timestamp.fromDate(new Date(this.state.date));;
            let vm = this;
            this.setState({
                loading: true,
            })
            for (let i = 0; i < polygonsIds.length; i++) {
                let polygonId = polygonsIds[i]
                let logsUrl = "fields/" + this.props.fieldId + "/polygons/" + polygonId + "/logs"
                if (this.props.selectedLog) {
                    firestore()
                        .collection(logsUrl)
                        .doc(this.props.selectedLog.id)
                        .set(insertData, { merge: true })
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                } else {
                    firestore()
                        .collection(logsUrl)
                        .add(insertData)
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                }
            }
        }
    }

    getFormTitle() {
        if (this.state.logType === "phenology") {
            return "Registro de fenología"
        } else if (this.state.logType === "lab_analytic") {
            return "Registro de análisis"
        } else if (this.state.logType === "water") {
            return "Registro de riego"
        }
    }

    getSelectPlaceholder() {
        if (this.state.logType === "phenology") {
            return "Seleccione evento fenológico"
        } else if (this.state.logType === "water") {
            return "Seleccione evento de riego"
        }
        return "";
    }

    shouldShowSelect() {
        if (this.state.logType === "phenology") {
            return true;
        } else if (this.state.logType === "water") {
            return true;
        }
        return false;
    }

    shouldShowObs() {
        return this.state.logType === "water"
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
            >
                <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <h4 className="title title-up">{this.getFormTitle()}</h4>
                </div>
                <div className="modal-body">
                    <br />
                    <Row className="justify-content-center">
                        <Col sm={12} md={10} lg={8}>
                            <FormGroup>
                                <label>Fecha</label>
                                <ReactDatetime
                                    value={this.state.date}
                                    onChange={(value) => {
                                        this.setState({
                                            dateValidState: true,
                                            date: value,
                                        })
                                    }}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Ingrese fecha de registro",
                                    }}
                                    timeFormat={false}
                                />
                                {this.state.dateValidState === false ? (
                                    <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                ) : null}
                            </FormGroup>
                            {
                                this.shouldShowSelect() && <div>
                                    <FormGroup>
                                        <label>Tipo de evento</label>
                                        <Select
                                            name="logType"
                                            className="react-select"
                                            placeholder={this.getSelectPlaceholder()}
                                            classNamePrefix="react-select"
                                            value={this.state.selectedEventType}
                                            onChange={(value) => this.changeSelectedEventType(value)}
                                            options={this.state.eventTypes}
                                        />
                                        {this.state.eventTypeState === false ? (
                                            <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            }
                            {
                                !this.shouldShowSelect() && <div>
                                    <FormGroup>
                                        <label>Añadir variable</label>
                                        <Select
                                            name="logType"
                                            className="react-select"
                                            placeholder="Añadir variable"
                                            classNamePrefix="react-select"
                                            value={null}
                                            onChange={(value) => this.selectChemical(value)}
                                            options={this.state.eventTypes}
                                        />
                                        {this.state.chemichalSelectState === false ? (
                                            <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            }
                            {
                                this.shouldShowObs() && <div>
                                    <FormGroup>
                                        <label>Observación</label>
                                        <Input
                                            type="text"
                                            value={this.state.waterObsInput}
                                            onChange={(e) => this.setState({
                                                waterObsInput: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            <div>
                                {
                                    this.state.selectedChemichals && this.state.selectedChemichals.map((agrochemical, index) => {
                                        return <Chip
                                            id={index}
                                            value={agrochemical["inputValue"]}
                                            onChange={this.onChangeInput}
                                            label={agrochemical.label}
                                            onDeleteTapped={this.onDeleteTapped}
                                        >
                                        </Chip>
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    !this.state.loading ? <div className="modal-footer">
                        <div className="left-side">
                            <Button
                                className="btn-link"
                                color="danger"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.closeButtonTapped(false)}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button
                                className="btn-link"
                                color="primary"
                                type="button"
                                onClick={() => this.saveButtonTapped()}
                            >Guardar</Button>
                        </div>
                    </div> : <div className="text-center py-4">
                        <CircularProgress color="secondary" />
                    </div>
                }
            </Modal>
        );
    }
}

export default GenericDateAndSelectForm;