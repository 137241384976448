/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
    FormGroup,
    Input,
    Row,
    Col,
    Card,
    CardHeader,
    Collapse,
    CardBody,
    InputGroup,
    InputGroupAddon, InputGroupText
} from "reactstrap";
import Button from "reactstrap/es/Button";
import EditCtrl from "../../../../components/Maps/mapEditCtrl";
import firebase, { firestore } from "firebase";
import { createBrowserHistory } from "history";
import Modal from "reactstrap/es/Modal";
import LoadingOverlay from "react-loading-overlay";
import Dropzone from "../components/Dropzone";

const backPolygonsColor = '#ffe913';
const createdPolygonsColor = '#d15f0d';
const drawingPolygonsColor = '#26f5fa';

class Wizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openedCollapses: ["collapseOne", "collapse1"],

            mapShow: false,

            centerLocation: { lat: -32, lng: -57 },
            zoom: 14,
            initGeoJson: [],

            polygonName: "",
            coordsArr: [],

            polygonStatus: true,

            polygonsArr: [],

            fieldList: [],
            fieldsUrl: "",
            bounds: [
                [
                    -90, -180
                ], [90, 180]],

            showPolygonNameModal: false,
            onlyFirst: true,

            showKmlModal: false,
            kmlPolygonData: []
        }
    }

    getBounds = () => {
        var left = -57;
        var top = -32;
        var right = 57;
        var bottom = 32;

        let fieldList = this.state.fieldList;
        if (fieldList) {
            for (let i = 0; i < fieldList.length; i++) {
                let polygons = fieldList[i].polygons;
                if (polygons) {
                    for (let j = 0; j < polygons.length; j++) {
                        let coords = polygons[j].coords;
                        if (coords) {
                            for (let k = 0; k < coords.length; k++) {
                                let latitude = coords[k].latitude;
                                let longitude = coords[k].longitude;
                                if (latitude > top) {
                                    top = coords[k].latitude;
                                }

                                if (latitude < bottom) {
                                    bottom = coords[k].latitude;
                                }

                                if (longitude > left) {
                                    left = longitude;
                                }

                                if (longitude < right) {
                                    right = longitude;
                                }
                            }
                        }
                    }
                }
            }
        }

        let result = [
            [top, left],
            [bottom, right]
        ];

        return result;
    };

    componentDidMount() {
        let vm = this;
        const fetch = async () => {
            let dataInfo = [];
            const fieldData = await firestore()
                .collection(this.state.fieldsUrl)
                .where("shared", "array-contains", firebase.auth().currentUser.uid)
                .orderBy("name")
                .get()
                .then(async function (fieldsReference) {
                    fieldsReference.docs.map((item, index) => {
                        if (item.exists === true) {
                            let tempData = item.data();
                            if (!tempData.deleted && tempData) {
                                tempData['id'] = item.id;
                                dataInfo.push(tempData);
                            }
                        }
                    });

                    for (let i = 0; i < dataInfo.length; i++) {
                        let id = dataInfo[i]['id'];
                        let url = vm.state.fieldsUrl + "/" + id + '/polygons';
                        let polygons = [];
                        const polygonData = await firestore()
                            .collection(url)
                            .orderBy("name")
                            .get()
                            .then(async function (polygonsReference) {
                                polygonsReference.docs.map(async (item) => {
                                    if (item.exists === true) {
                                        let tempData = item.data();
                                        if (!tempData.deleted) {
                                            tempData['id'] = item.id;
                                            polygons.push(tempData);
                                            dataInfo[i]['polygons'] = polygons;
                                            vm.setState({
                                                fieldList: dataInfo
                                            })
                                        }
                                    }
                                })
                            });
                    }
                });

        };

        let timeCount = 0;
        var timeInterval = setInterval(() => {
            if (firebase.auth().currentUser == null) {
                timeCount++;
                if (timeCount > 2) {

                    createBrowserHistory().push('/auth/login');
                }
            } else {
                this.setState({
                    currentUser: firebase.auth().currentUser,
                    fieldsUrl: `fields`,
                }, () => {
                    fetch();
                });
                clearInterval(timeInterval);
            }
        }, 1000);
    }

    collapsesToggle = (collapse) => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter((item) => item !== collapse),
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses,
            });
        }
    };

    onSetPolygonName = () => {
        let tempPolygonInfo = {
            name: this.state.polygonName,
            coordsArr: this.state.coordsArr
        };

        this.onToggle();

        console.log('current polygons info');
        console.log(tempPolygonInfo);


        this.setState((prevState) => ({
            mapShow: false,
            polygonsArr: [...prevState.polygonsArr, tempPolygonInfo]
        }), () => {

            this.setState({
                coordsArr: [],
                polygonName: "",
                mapShow: true
            });
        });

    };

    onAddPolygon = () => {
        this.setState({
            onlyFirst: false
        });

        if (this.state.mapShow === false) {
            this.setState({
                mapShow: true,
            })
        }

        if (this.state.polygonStatus === false) {
            this.setState({
                polygonStatus: true
            })
        }
    };

    leftSide = () => (
        this.state.polygonsArr.map((item, index) => {
            return (
                <Card className="card-plain" key={index}>
                    <CardHeader role="tab">

                        {item.name}

                    </CardHeader>
                </Card>
            )
        })
    );

    onAddKmlPolygon = (polygonInfo) => {

        let kmlPolygonData = this.state.kmlPolygonData;

        let bOk = true;
        for (let i = 0; i < kmlPolygonData.length; i++) {
            if (kmlPolygonData[i].fileName === polygonInfo.fileName) {
                bOk = false;
                break;
            }
        }

        if (bOk === true) {
            kmlPolygonData.push(polygonInfo);
            this.setState({
                kmlPolygonData: kmlPolygonData
            })
        }
    };

    onRemoveKmlPolygon = (fileName) => {

        let kmlPolygonData = this.state.kmlPolygonData;

        const index = kmlPolygonData.findIndex(e => e.fileName === fileName);

        kmlPolygonData.splice(index, 1);

        this.setState({
            kmlPolygonData: kmlPolygonData
        })
    };

    onToggle = () => {
        this.setState({
            showPolygonNameModal: !this.state.showPolygonNameModal
        })
    };

    onKmlToggle = () => {
        this.setState({
            showKmlModal: !this.state.showKmlModal
        })
    };

    onDrawKmlData = () => {
        let kmlPolygonData = this.state.kmlPolygonData;
        let currentPolygonArr = this.state.polygonsArr;

        kmlPolygonData.map((item, index) => {
            let polygons = item.polygons;

            polygons.map((subItem, subIndex) => {
                let tempCoords = subItem.coords;
                let coords = [];
                let name = subItem.name;

                tempCoords.map(coord => {
                    let tempArr = [];
                    tempArr[0] = coord.lng;
                    tempArr[1] = coord.lat;

                    coords.push(tempArr);
                });

                let tempPolygonInfo = {
                    name: "",
                    coordsArr: []
                };
                tempPolygonInfo.name = name;
                tempPolygonInfo.coordsArr[0] = coords;

                currentPolygonArr.push(tempPolygonInfo);
            });
        });

        this.setState(() => ({
            mapShow: false,
            polygonsArr: currentPolygonArr
        }), () => {

            this.setState({
                coordsArr: [],
                showKmlModal: false,
                polygonName: "",
                kmlPolygonData: [],
                mapShow: true
            });
        })
    };

    onEditChange = (geoJson) => {

        if (geoJson.features) {

            let tempGeoJson = [];

            for (let i = 0; i < geoJson.features.length; i++) {
                let temp = geoJson.features[i].geometry;

                if (temp.type === 'Polygon') {
                    tempGeoJson.push(temp.coordinates[0]);
                }
            }

            this.setState({
                coordsArr: tempGeoJson
            }, () => {

                if (this.state.showPolygonNameModal === false) {
                    this.setState({
                        showPolygonNameModal: true
                    })
                }
            })
        }
    };

    isValidated = () => {
        if (this.state.polygonsArr.length < 1) {
            this.setState({
                polygonStatus: false
            });
            return false;
        }

        return true;
    };

    render() {

        const polygonSettingModal = (<Modal
            isOpen={this.state.showPolygonNameModal}
            toggle={() => this.onToggle()}
            backdrop={false}
            keyboard={false}
        >
            <div className="modal-header justify-content-center">
                <h4 className="title title-up">Inserte el nombre del polígono</h4>
            </div>
            <div className="modal-body">
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="nc-icon nc-air-baloon" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Nombre del polígono" type="text" onChange={(e) =>
                        this.setState({
                            polygonName: e.target.value
                        })
                    } />
                </InputGroup>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.onToggle()}
                    >
                        Cancelar
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="btn-link"
                        color="primary"
                        type="button"
                        onClick={() => { this.onSetPolygonName() }}
                    >
                        Guardar
                    </Button>
                </div>
            </div>
        </Modal>);

        const polygonKmlModal = (<Modal
            isOpen={this.state.showKmlModal}
            toggle={() => this.onKmlToggle()}
            backdrop={false}
            keyboard={false}
        >
            <div className="modal-header justify-content-center">
                <h4 className="title title-up">Importar polígonos desde KML</h4>
            </div>
            <div className="modal-body">
                <Dropzone
                    onAddKmlPolygon={this.onAddKmlPolygon}
                    onRemoveKmlPolygon={this.onRemoveKmlPolygon}
                />
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.onKmlToggle()}
                    >
                        Cancelar
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className={this.state.kmlPolygonData.length > 0 ? 'btn-link' : 'btn-link disabled'}
                        color="primary"
                        type="button"
                        onClick={() => { this.onDrawKmlData() }}
                    >
                        Importar
                    </Button>
                </div>
            </div>
        </Modal>);

        return (
            <>
                <Row className="justify-content-center">
                    {
                        polygonSettingModal
                    }
                    {
                        polygonKmlModal
                    }
                    <Col sm={this.state.onlyFirst === false ? 3 : 8}>
                        <div
                            aria-multiselectable={true}
                            className="card-collapse"
                            id="accordion"
                            style={{ paddingRight: '20px', height: this.state.mapShow === false ? '220px' : '240px', overflow: 'auto' }}
                            role="tablist"
                        >
                            {this.leftSide()}
                            {this.state.polygonStatus === false ? (
                                <label style={{ color: '#ff0000' }}>Nuevos polígonos</label>
                            ) : null}

                        </div>
                        <div style={{ display: this.state.onlyFirst === true && 'none' }}>
                            <Button color="info" style={{ width: '100%' }}
                                onClick={() => this.onKmlToggle()}
                            >
                                <span className="btn-label">
                                    <i className="nc-icon nc-simple-add" />
                                </span>
                                Importar KML
                            </Button>
                        </div>
                        {
                            this.state.mapShow === false &&
                            <Button color="info" style={{ width: '100%' }}
                                onClick={() => this.onAddPolygon()
                                }
                            >
                                <span className="btn-label">
                                    <i className="nc-icon nc-simple-add" />
                                </span>
                                Añadir polígono
                            </Button>
                        }
                    </Col>
                    <Col sm="9">
                        <LoadingOverlay active={!this.state.mapShow} spinner text={'Cargando...'}>
                            {
                                this.state.mapShow === true ?
                                    <EditCtrl
                                        center={this.state.centerLocation}
                                        zoom={12}
                                        initGeoJson={this.state.initGeoJson}
                                        onChange={this.onEditChange}
                                        bounds={this.getBounds()}
                                        fieldList={this.state.fieldList}
                                        backPolygonsColor={backPolygonsColor}
                                        createdList={this.state.polygonsArr}
                                        createdPolygonsColor={createdPolygonsColor}
                                        drawingPolygonsColor={drawingPolygonsColor}
                                    /> :
                                    <div style={{ width: '100%', height: '100%' }}></div>
                            }
                        </LoadingOverlay>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Wizard;
