/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {FormGroup, Input, Row, Col, InputGroup, CardBody} from "reactstrap";
import firebase, {firestore} from 'firebase';

import {useHistory} from 'react-router-dom';
import ReactDatetime from "react-datetime";
import {createBrowserHistory} from "history";

const cropsUrl = "crops/";
class Wizard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      crop : null,
      cropArr : [],
      cropState : true,

      variety : null,
      varietyArr : [],

      predecessorArray : [{
        "value": "ARR",
        "label": "Arroz"
      } , {
        "value": "SOJ",
        "label": "Soja"
      }, {
        "value": "PRA",
        "label": "Pradera"
      }, {
        "value": "CN",
        "label": "Campo natural"
      }, {
        "value": "LAB",
        "label": "Laboreo de verano"
      }],

      sowingDate : "",
      sowingDateState : true,

      currentUser : null,
      fieldsUrl : "",

      predecesor : null,
      predecesorState : true,
      
      predecesorVariety: null,
      predecesorVarietyArr : [],
    }
  }

  componentDidMount() {
    const fetchCrops = async () => {
      const cropsData = await firestore().collection(cropsUrl).get();

      let dataInfo = [];

      cropsData.docs.map((item, index) => {
        if (item.exists === true) {
          let tempData = item.data();
          let tempArr = {};
          tempArr['value'] = item.id;
          tempArr['label'] = tempData['name'];
          dataInfo.push(tempArr);
        }
      });

      this.setState({
        cropArr : dataInfo
      });
    };

    let timeCount = 0;
    var timeInterval = setInterval(() => {
      if (firebase.auth().currentUser == null) {
        timeCount ++ ;
        if (timeCount > 2) {

          createBrowserHistory().push('/auth/login');
        }
      } else {
        this.setState({
          currentUser : firebase.auth().currentUser,
          fieldsUrl : `fields`,
        }, () => {
          fetchCrops();
        });
        clearInterval(timeInterval);
      }
    }, 1000);
  };

  changeCrop = (value, predecesor) => {

    const fetchVarieties = async () => {
      let url = cropsUrl + value.value + "/varieties/";
      let varietyData = await firestore().collection(url).get();

      let dataInfo = [];

      varietyData.docs.map((item, index) => {
        if (item.exists === true) {
          let tempArr = {};
          tempArr['value'] = item.id;
          tempArr['label'] = item.id;
          dataInfo.push(tempArr);
        }

      });

      
      if (predecesor) {
        this.setState({
          predecesorVarietyArr : dataInfo,
          predecesorVariety : dataInfo.length > 0 ? dataInfo[0] : null
        });
      } else {
        this.setState({
          varietyArr : dataInfo,
          variety : dataInfo.length > 0 ? dataInfo[0] : null
        });
      }
    };

    

    // alert(event);
    if (predecesor) {
      if(value.value == "ARR") {
        fetchVarieties();
        this.setState({
            predecesor: value,
            predecesorState : true
        })
      } else {
        this.setState({
            predecesor: value,
            predecesorState : true,
            predecesorVarietyArr : [],
            predecesorVariety: null
        })
      }
    } else {
      fetchVarieties();
      this.setState({
        crop: value,
        cropState : true
      })
    }
  };

  changeVariety = (value, predecesor) => {
    if (predecesor) {
      this.setState({
        predecesorVariety : value,
      })
    } else {
      this.setState({
        variety : value,
      })
    }
  };

  isValidated = () => {
    if (this.state.crop === null) {
      this.setState({
        cropState : false
      });
      return false;
    }

    if (this.state.sowingDate === "") {
      this.setState({
        sowingDateState : false
      });

      return false;
    }
    return true;
  };
  render() {
    return (
        <>
          <Row className="justify-content-center">
            <Col sm="12">
              <h5 className="info-text">Seleccionar cultivo y variedad.</h5>
            </Col>
            <Col sm="8">
              {
                this.state.cropArr.length > 0 &&
                <FormGroup>
                  <label>Cultivo</label>
                  <Select
                      name="crop"
                      className="react-select"
                      placeholder="Seleccionar cultivo"
                      classNamePrefix="react-select"
                      value={this.state.crop}
                      onChange={(value) => this.changeCrop(value, false)}
                      options={this.state.cropArr}

                  />
                  {this.state.cropState === false ? (
                      <label style={{color: '#ff0000'}}>Campo requerido</label>
                  ) : null}
                </FormGroup>
              }
            </Col>
            <Col sm="8">
              {
                this.state.varietyArr.length > 0 &&
                <FormGroup>
                  <label>Variedad</label>
                  <Select
                      name="variety"
                      className="react-select"
                      placeholder="Seleccionar variedad"
                      classNamePrefix="react-select"
                      value={this.state.variety}
                      onChange={(value) => this.changeVariety(value, false)}
                      options={this.state.varietyArr}
                  />
                </FormGroup>
              }
            </Col>
            <Col sm="8">
              {
                this.state.predecessorArray.length > 0 &&
                <FormGroup>
                  <label>Antecesor</label>
                  <Select
                      name="crop"
                      className="react-select"
                      placeholder="Seleccionar cultivo"
                      classNamePrefix="react-select"
                      value={this.state.predecesor}
                      onChange={(value) => this.changeCrop(value, true)}
                      options={this.state.predecessorArray}

                  />
                  {this.state.predecesorState === false ? (
                      <label style={{color: '#ff0000'}}>Campo requerido</label>
                  ) : null}
                </FormGroup>
              }
            </Col>
            <Col sm="8">
              {
                this.state.predecesorVarietyArr.length > 0 &&
                <FormGroup>
                  <label>Variedad antecesor</label>
                  <Select
                      name="variety"
                      className="react-select"
                      placeholder="Seleccionar variedad"
                      classNamePrefix="react-select"
                      value={this.state.predecesorVariety}
                      onChange={(value) => this.changeVariety(value, true)}
                      options={this.state.predecesorVarietyArr}
                  />
                </FormGroup>
              }
            </Col>
            <Col sm="8">
              <FormGroup>
                <label>Fecha de siembra</label>
                <ReactDatetime
                    value={this.state.sowingDate}
                    onChange={(value) => {
                      this.setState({
                        sowingDate : value,
                        sowingDateState : true
                      })
                    }}
                    inputProps={{
                      className: "form-control",
                      placeholder: "Ingrese fecha de siembra",
                    }}
                    timeFormat={false}
                />
                {this.state.sowingDateState === false ? (
                    <label style={{color: '#ff0000'}}>Campo requerido</label>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
        </>
    );
  }
}

export default Wizard;
