import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from 'firebase';
import { Application, Presence } from '../../../Models/Logs.js'
import Chip from '../../components/Chip.js'
import CircularProgress from "@material-ui/core/CircularProgress";
import * as firebase from "firebase/app";

class ApplicationForm extends Component {

    constructor(props) {
        super(props);
        this.applicationTypes = Application.getApplicationTypes();

        let appTypeProps = props.selectedLog ? this.applicationTypes.filter(item => item.value === props.selectedLog.type)[0] : null
        this.state = {
            appType: appTypeProps,
            triggerLog: null,
            date: props.selectedLog ? props.selectedLog.date : "",

            agrochemicalsArray: [],
            logsToControl: [],

            selectedAgrochemicals: [],

            //Control values

            dateValidState: true,
            appTypeState: true,
            loading: false,
        }
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onDeleteTapped = this.onDeleteTapped.bind(this);
        if (props.selectedLog && appTypeProps) {
            this.getAgrochemicals()
            this.getLogsToControl()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedLog) {
            let appTypeProps = this.applicationTypes.filter(item => item.value === nextProps.selectedLog.type)[0]
            this.setState({
                appType: appTypeProps,
                date: nextProps.selectedLog.date,
                selectedAgrochemicals: []
            }, () => {
                if (appTypeProps) {
                    this.getAgrochemicals()
                    this.getLogsToControl()
                }
            });
        }
    }

    clearStatus() {
        this.setState({
            appType: null,
            triggerLog: null,
            date: null,
            agrochemicalsArray: null,
            triggerArr: [],
            selectedAgrochemicals: [],
            loading: false,
        })
    }

    isValidated = () => {
        var isValidated = true

        if (this.state.appType === null) {
            this.setState({
                appTypeState: false
            });
            isValidated = false;
        }

        if (this.state.date == null) {
            this.setState({
                dateValidState: false
            });

            if (isValidated) {
                isValidated = false;
            }
        }

        return isValidated;
    }

    closeButtonTapped(success) {
        this.clearStatus()
        this.props.onClose(success)
    }

    changeAppType = (value) => {
        this.setState({
            appType: value,
            agrochemicalsArray: null,
            triggerArr: [],
            selectedAgrochemicals: [],
            triggerLog: null,
            appTypeState: true
        }, () => {
            this.getLogsToControl()
            this.getAgrochemicals()
        })
    }

    logToControl() {
        if (this.state.appType.value) {
            switch (this.state.appType.value) {
                case "herbicide":
                    return "weed_presence"
                case "insecticide":
                    return "bug_presence"
                case "fungicide":
                    return "disease_presence"
                default:
                    return null;
            }
        }
    }

    async getLogsToControl() {
        let logToControl = this.logToControl()
        let polygonsIds = Object.keys(this.props.selectedPolygons)
        if (this.props.fieldId && polygonsIds.length > 0 && logToControl != null) {
            let vm = this;
            let logUrl = `fields/${this.props.fieldId}/polygons/${polygonsIds[0]}/logs`
            let logsToControlInfo = [];
            await firestore()
                .collection(logUrl)
                .orderBy("date", "desc")
                .where("type", "==", logToControl)
                .get()
                .then(async function (logsReference) {
                    logsReference.docs.map((item, _) => {
                        if (item.exists === true) {
                            let logData = item.data();
                            if (!logData.deleted && logData) {
                                let type = logData["type"];
                                let id = item.id;
                                let date = new Date(logData["date"].seconds * 1000);
                                switch (type) {
                                    case "weed_presence":
                                    case "disease_presence":
                                    case "bug_presence":
                                        let values = logData["value"];
                                        let presenceLog = new Presence(type, date, id, values)
                                        logsToControlInfo.push({ "value": id, "label": presenceLog.toString() })
                                        break
                                    default:
                                        break;
                                }
                            }
                        }
                    })
                    vm.setState({
                        logsToControl: logsToControlInfo
                    })

                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    async getAgrochemicals() {
        if (this.state.appType) {
            let vm = this;
            let agrochemicalUrl = `${this.state.appType.value}s`
            let agrochemicalsInfo = [];
            await firestore()
                .collection(agrochemicalUrl)
                .get()
                .then(async function (agrochemicalReference) {
                    agrochemicalReference.docs.map((item, _) => {
                        if (item.exists === true) {
                            agrochemicalsInfo.push({ "value": item.id, "label": item.id, "inputValue": 0 })
                        }
                    })
                    vm.setState({
                        agrochemicalsArray: agrochemicalsInfo,
                        selectedAgrochemicals: vm.props.selectedLog && vm.props.selectedLog.herb ? agrochemicalsInfo.filter(item =>  vm.props.selectedLog.herb.map(herb => herb.id).indexOf(item.value) > -1)  : []
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    changeLogToControl(value) {
        this.setState({
            triggerLog: value
        })
    }

    selectAgrochemichal(value) {
        if (this.state.selectedAgrochemicals.indexOf(value) <= -1) {
            this.state.selectedAgrochemicals.push(value)
            this.setState({})
        }
    }

    saveButtonTapped() {
        let polygonsIds = Object.keys(this.props.selectedPolygons)
        if (this.isValidated() && polygonsIds.length > 0 && this.props.fieldId) {

            let insertData = {};
            let value = this.state.selectedAgrochemicals.map((item, _) => { return { "dose": parseFloat(item["inputValue"]), "id": item["value"] } })
            var trigger = null;
            if (this.state.triggerLog) {
                trigger = polygonsIds[0] + "/" + this.state.triggerLog.value
            }
            let deleted = false;
            let tempDate = this.state.date;
            insertData.value = value;
            insertData.trigger = trigger;
            insertData.type = this.state.appType.value
            insertData.deleted = deleted;
            insertData.date = firebase.firestore.Timestamp.fromDate(new Date(tempDate));;
            let vm = this;
            this.setState({
                loading: true,
            })
            for (let i = 0; i < polygonsIds.length; i++) {
                let polygonId = polygonsIds[i]
                let logsUrl = "fields/" + this.props.fieldId + "/polygons/" + polygonId + "/logs"
                if (this.props.selectedLog) {
                    firestore()
                        .collection(logsUrl)
                        .doc(this.props.selectedLog.id)
                        .set(insertData,  { merge: true })
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                } else {
                    firestore()
                        .collection(logsUrl)
                        .add(insertData)
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                }
            }
        }
    }

    onChangeInput = (input) => {
        let agrochemicalIndex = input.target.id
        if (this.state.selectedAgrochemicals && this.state.selectedAgrochemicals.length > 0 && this.state.selectedAgrochemicals[agrochemicalIndex]) {
            let agroChemichal = this.state.selectedAgrochemicals[agrochemicalIndex]
            agroChemichal["inputValue"] = input.target.value
            this.setState({})
        }
    }

    onDeleteTapped = (input) => {
        let agrochemicalIndex = input.target.id
        if (this.state.selectedAgrochemicals && this.state.selectedAgrochemicals.length > 0 && this.state.selectedAgrochemicals[agrochemicalIndex]) {
            let agroChemichal = this.state.selectedAgrochemicals[agrochemicalIndex]
            let filteredArray = this.state.selectedAgrochemicals.filter(item => item.value !== agroChemichal.label)
            this.setState({ selectedAgrochemicals: filteredArray })
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
            >
                <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <h4 className="title title-up">Registro de aplicación</h4>
                </div>
                <div className="modal-body">
                    <br />
                    <Row className="justify-content-center">
                        <Col sm={12} md={10} lg={8}>
                            <FormGroup>
                                <label>Fecha</label>
                                <ReactDatetime
                                    value={this.state.date}
                                    onChange={(value) => {
                                        this.setState({
                                            dateValidState: true,
                                            date: value,
                                        })
                                    }}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Ingrese fecha de registro",
                                    }}
                                    timeFormat={false}
                                />
                                {this.state.dateValidState === false ? (
                                    <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                ) : null}
                            </FormGroup>
                            <div>
                                <FormGroup>
                                    <label>Tipo de aplicación</label>
                                    <Select
                                        name="logType"
                                        className="react-select"
                                        placeholder="Seleccione tipo de aplicación"
                                        classNamePrefix="react-select"
                                        value={this.state.appType}
                                        onChange={(value) => this.changeAppType(value)}
                                        options={this.applicationTypes}
                                    />
                                    {this.state.appTypeState === false ? (
                                        <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                    ) : null}
                                </FormGroup>
                            </div>
                            {
                                this.state.logsToControl && this.state.logsToControl.length > 0 && <div>
                                    <FormGroup>
                                        <label>Reporte a controlar</label>
                                        <Select
                                            name="logType"
                                            className="react-select"
                                            placeholder="Seleccione reporte a controlar"
                                            classNamePrefix="react-select"
                                            value={this.state.triggerLog}
                                            onChange={(value) => this.changeLogToControl(value)}
                                            options={this.state.logsToControl}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            {
                                this.state.agrochemicalsArray && this.state.agrochemicalsArray.length > 0 && <div>
                                    <FormGroup>
                                        <label>Añadir P.A</label>
                                        <Select
                                            name="logType"
                                            className="react-select"
                                            placeholder="Añadir P.A"
                                            classNamePrefix="react-select"
                                            value={null}
                                            onChange={(value) => this.selectAgrochemichal(value)}
                                            options={this.state.agrochemicalsArray}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            <div>
                                {
                                    this.state.selectedAgrochemicals && this.state.selectedAgrochemicals.map((agrochemical, index) => {
                                        return <Chip
                                            id={index}
                                            value={agrochemical["inputValue"]}
                                            onChange={this.onChangeInput}
                                            label={agrochemical.label}
                                            onDeleteTapped={this.onDeleteTapped}
                                        >
                                        </Chip>
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    !this.state.loading ? <div className="modal-footer">
                        <div className="left-side">
                            <Button
                                className="btn-link"
                                color="danger"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.closeButtonTapped(false)}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button
                                className="btn-link"
                                color="primary"
                                type="button"
                                onClick={() => this.saveButtonTapped()}
                            >Guardar</Button>
                        </div>
                    </div> : <div className="text-center py-4">
                        <CircularProgress color="secondary" />
                    </div>
                }
            </Modal>
        );
    }
}
export default ApplicationForm;