/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Form, FormGroup, Input,Modal, FormFeedback, Button, Col } from "reactstrap";

class ModalInputs extends Component {

    constructor(props) {
        super(props);
        this.state = {
          values: props.values,
          invalidInputs: [false,false,false,false]
        };
        this.hasValue = [undefined, undefined, undefined, undefined]
        this.originalValues = [0,10,40,80]
        this.placeHolders = this.props.placeHolders
        this.handleChange = this.handleChange.bind(this);
        this.isInputInvalid = this.isInputInvalid.bind(this);
        this.managePlaceholders = this.managePlaceholders.bind(this);
        this.saveButtonTapped = this.saveButtonTapped.bind(this);
        this.buttonDisabled = this.buttonDisabled.bind(this);
        this.NDWI = this.props.index == "NDWI"
      }

      componentWillReceiveProps(nextProps) {
        this.placeHolders = nextProps.placeHolders
        this.NDWI = this.props.index == "NDWI"
        this.state = {
            values: nextProps.values,
            invalidInputs: [false,false,false,false]
          }
        }
    
    buttonDisabled() {
        return this.hasValue.some(element => element == undefined)
    }

    handleChange(e){
        var tagName = e.target.name
        if (tagName == "firstRange"){
            var firstValue = parseFloat(e.target.value, 10)
            if (this.isInputInvalid(e)) {
                if (isNaN(firstValue)) {
                    this.state.invalidInputs[0] = false
                } else {
                    this.state.invalidInputs[0] = true
                }
                this.hasValue[0] = undefined
                if (this.NDWI) {
                    this.state.values[0] = parseFloat(((this.originalValues[0]/100)).toFixed(2), 10)
                } else {
                    this.state.values[0] = this.originalValues[0]
                }
                this.managePlaceholders()
            } else {
                this.hasValue[0] = firstValue
                this.state.invalidInputs[0] = false
                if (this.NDWI) {
                    this.state.values[0] = parseFloat(((firstValue + 1) / 2).toFixed(2), 10)
                    this.placeHolders[0] =`${(2*(this.originalValues[0])-1)/100} - ${(2*(this.state.values[1])-1).toFixed(2)}`
                    this.placeHolders[1] = `${firstValue} - ${(2*(this.state.values[2])-1).toFixed(2)}`
                    this.placeHolders[2] = `${firstValue} - ${(2*(this.state.values[3])-1).toFixed(2)}`
                } else {
                    this.state.values[0] = firstValue
                    this.placeHolders[0] =`${this.originalValues[0]/100} - ${this.state.values[1]}`
                    this.placeHolders[1] = `${firstValue} - ${this.state.values[2]}`
                    this.placeHolders[2] = `${firstValue} - ${this.state.values[3]}`
                }
            }
        } else if (tagName == "secondRange") {
            var secondValue = parseFloat(e.target.value)
            if (this.isInputInvalid(e)) {
                if (isNaN(secondValue)) {
                    this.state.invalidInputs[1] = false
                } else {
                    this.state.invalidInputs[1] = true
                }
                this.hasValue[1] = undefined
                if (this.NDWI) {
                    this.state.values[1] = parseFloat(((this.originalValues[1]/100)).toFixed(2), 10)
                } else {
                    this.state.values[1] = this.originalValues[1]
                }
                this.managePlaceholders()
            } else {
                this.state.invalidInputs[1] = false
                this.hasValue[1] = secondValue
                if (this.NDWI) {
                    this.state.values[1] = parseFloat(((secondValue + 1) / 2).toFixed(2), 10)
                    this.placeHolders[0] =`${0} - ${secondValue}`
                    this.placeHolders[1] =`${(2*(this.state.values[0])-1).toFixed(2)} - ${(2*(this.state.values[2])-1).toFixed(2)}`
                    this.placeHolders[2] = `${secondValue} - ${(2*(this.state.values[3])-1).toFixed(2)}`
                } else {
                    this.state.values[1] = secondValue
                    this.placeHolders[0] =`${0} - ${secondValue}`
                    this.placeHolders[1] =`${this.state.values[0]} - ${this.state.values[2]}`
                    this.placeHolders[2] = `${secondValue} - ${this.state.values[3]}`
                }
            }
        } else if (tagName == "thirdRange") {
            var thirdValue = parseFloat(e.target.value)
            if (this.isInputInvalid(e)) {
                if (isNaN(thirdValue)) {
                    this.state.invalidInputs[2] = false
                } else {
                    this.state.invalidInputs[2] = true
                }
                this.hasValue[2] = undefined
                if (this.NDWI) {
                    this.state.values[2] = parseFloat(((this.originalValues[2]/100)).toFixed(2), 10)
                } else {
                    this.state.values[2] = this.originalValues[2]
                }
                this.managePlaceholders()
            } else {
                this.state.invalidInputs[2] = false
                this.hasValue[2] = thirdValue
                if (this.NDWI) {
                    this.state.values[2] = parseFloat(((thirdValue + 1) / 2).toFixed(2), 10)
                    this.placeHolders[1] = `${(2*(this.state.values[0])-1).toFixed(2)} - ${thirdValue}`
                    this.placeHolders[2] = `${(2*(this.state.values[1])-1).toFixed(2)} - ${(2*(this.state.values[3])-1).toFixed(2)}`
                    this.placeHolders[3] = `${thirdValue} - ${1.0}`
                } else {
                    this.state.values[2] = thirdValue
                    this.placeHolders[1] = `${this.state.values[0]} - ${thirdValue}`
                    this.placeHolders[2] = `${this.state.values[1]} - ${this.state.values[3]}`
                    this.placeHolders[3] = `${thirdValue} - ${1.0}`
                }
            }
        } else if (tagName == "fourthRange") {
            var fourthValue = parseFloat(e.target.value)
            if (this.isInputInvalid(e)) {
                if (isNaN(fourthValue)) {
                    this.state.invalidInputs[3] = false
                } else {
                    this.state.invalidInputs[3] = true
                }
                this.hasValue[3] = undefined
                if (this.NDWI) {
                    this.state.values[3] = parseFloat(((this.originalValues[3]/100)).toFixed(2), 10)
                } else {
                    this.state.values[3] = this.originalValues[3]
                }
                this.managePlaceholders()
            } else {
                this.state.invalidInputs[3] = false
                this.hasValue[3] = fourthValue
                if (this.NDWI) {
                    this.state.values[3] = parseFloat(((fourthValue + 1) / 2).toFixed(2), 10)
                    this.placeHolders[2] = `${(2*(this.state.values[1])-1).toFixed(2)} - ${fourthValue}`
                    this.placeHolders[3] = `${(2*(this.state.values[2])-1).toFixed(2)} - ${1.0}`
                } else {
                    this.state.values[3] = fourthValue
                    this.placeHolders[2] = `${this.state.values[1]} - ${fourthValue}`
                    this.placeHolders[3] = `${this.state.values[2]} - ${1.0}`
                }
            }
        }
        this.setState({invalidInputs: this.state.invalidInputs, values : this.state.values})
    }
    
    isInputInvalid(e) {
        var tagName = e.target.name
        var value = parseFloat(e.target.value, 10)
        if (!e.target.value) {
            return true
        }
        if (tagName == "firstRange" && !isNaN(value)) {
            if (this.NDWI) {
                if (!(value >= -1.0 && value <= (2*(this.state.values[1])-1))) {
                    return true
                }
            } else {
                if (!(value >= 0 && value <= this.state.values[1])) {
                    return true
                }
            }
        } else if (tagName == "secondRange" && !isNaN(value)) {
            if (this.NDWI) {
                if (!(value >= ((2*(this.state.values[0]))-1) && value <= ((2*(this.state.values[2]))-1))) {
                    return true
                }
            } else {
                if (!(value >= this.state.values[0] && value <= this.state.values[2])) {
                    return true
                }
            }
        } else if (tagName == "thirdRange" && !isNaN(value)) {
            if (this.NDWI) {
                if (!(value >= ((2*(this.state.values[1]))-1) && value <= ((2*(this.state.values[3]))-1))) {
                    return true
                }
            } else {
                if (!(value >= this.state.values[1] && value <= this.state.values[3])) {
                    return true
                }
            }
        } else if (tagName == "fourthRange" && !isNaN(value)) {
            if (this.NDWI) {
                if (!(value >= ((2*(this.state.values[2]))-1) && value <= 1.0)) {
                    return true
                }
            } else {
                if (!(value >= this.state.values[2] && value <= 1.0)) {
                    return true
                }
            }
        }
        return false
    }

    managePlaceholders() {
        if (this.NDWI) {
            if (this.hasValue[1]) {
                this.placeHolders[0] = `${-1.0} - ${this.hasValue[1]}`
            } else {
                this.placeHolders[0] = `${-1.0} - ${parseFloat(2*(this.originalValues[1]/100)-1).toFixed(2)}`
            }
        } else {
            if (this.hasValue[1]) {
                this.placeHolders[0] = `${0.0} - ${this.hasValue[1]}`
            } else {
                this.placeHolders[0] = `${0.0} - ${this.originalValues[1]/100}`
            }
        }

        if (this.NDWI) {
            if (this.hasValue[0] && this.hasValue[2]) {
                this.placeHolders[1] = `${this.hasValue[0]} - ${this.hasValue[2]}`
            } else if (this.hasValue[0]) {
                this.placeHolders[1] = `${this.hasValue[0]} - ${parseFloat(2*(this.originalValues[2]/100)-1).toFixed(2)}`
            } else if (this.hasValue[2]) {
                this.placeHolders[1] = `${parseFloat(2*(this.originalValues[0]/100)-1).toFixed(2)} - ${this.hasValue[2]}`
            } else {
                this.placeHolders[1] = `${parseFloat(2*(this.originalValues[0]/100)-1).toFixed(2)} - ${parseFloat(2*(this.originalValues[2]/100)-1).toFixed(2)}`
            }
        } else {
            if (this.hasValue[0] && this.hasValue[2]) {
                this.placeHolders[1] = `${this.hasValue[0]} - ${this.hasValue[2]}`
            } else if (this.hasValue[0]) {
                this.placeHolders[1] = `${this.hasValue[0]} - ${this.originalValues[2]/100}`
            } else if (this.hasValue[2]) {
                this.placeHolders[1] = `${this.originalValues[0]/100} - ${this.hasValue[2]}`
            } else {
                this.placeHolders[1] = `${this.originalValues[0]/100} - ${this.originalValues[2]/100}`
            }
        }

        if (this.NDWI) {
            if (this.hasValue[1] && this.hasValue[3]) {
                this.placeHolders[2] = `${this.hasValue[1]} - ${this.hasValue[3]}`
            } else if (this.hasValue[1]) {
                this.placeHolders[2] = `${this.hasValue[1]} - ${parseFloat(2*(this.originalValues[3]/100)-1).toFixed(2)}`
            } else if (this.hasValue[3]) {
                this.placeHolders[2] = `${parseFloat(2*(this.originalValues[1]/100)-1).toFixed(2)} - ${this.hasValue[3]}`
            } else {
                this.placeHolders[2] = `${parseFloat(2*(this.originalValues[1]/100)-1).toFixed(2)} - ${parseFloat(2*(this.originalValues[3]/100)-1).toFixed(2)}`
            }
        } else {
            if (this.hasValue[1] && this.hasValue[3]) {
                this.placeHolders[2] = `${this.hasValue[1]} - ${this.hasValue[3]}`
            } else if (this.hasValue[1]) {
                this.placeHolders[2] = `${this.hasValue[1]} - ${this.originalValues[3]/100}`
            } else if (this.hasValue[3]) {
                this.placeHolders[2] = `${this.originalValues[1]/100} - ${this.hasValue[3]}`
            } else {
                this.placeHolders[2] = `${this.originalValues[1]/100} - ${this.originalValues[3]/100}`
            }
        }
        
        if (this.NDWI) {
            if (this.hasValue[2]) {
                this.placeHolders[3] = `${this.hasValue[2]} - ${1.0}`
            } else {
                this.placeHolders[3] = `${parseFloat(2*(this.originalValues[2]/100)-1).toFixed(2)} - ${1.0}`
            }
        } else {
            if (this.hasValue[2]) {
                this.placeHolders[3] = `${this.hasValue[2]} - ${1.0}`
            } else {
                this.placeHolders[3] = `${this.originalValues[2]/100} - ${1.0}`
            }
        }
    }

    saveButtonTapped() {
        this.props.onClose()
        var valuesAux = []
        valuesAux[0] = parseFloat((this.state.values[0] * 100).toFixed(2), 10)
        valuesAux[1] = parseFloat(((this.state.values[1] - this.state.values[0]) * 100).toFixed(2), 10)
        valuesAux[2] = parseFloat(((this.state.values[2] - this.state.values[1]) * 100).toFixed(2), 10)
        valuesAux[3] = parseFloat(((this.state.values[3] - this.state.values[2]) * 100).toFixed(2), 10)
        valuesAux[4] = parseFloat(((1.0 - this.state.values[3]) * 100).toFixed(2) , 10)
        this.props.onSaveTapped(valuesAux)
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <div className="modal-header">
                    <button aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.props.onClose}>
                    <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h5 className="modal-title" id="myModalLabel">
                        Parámetros de {this.props.index}
                    </h5>
                </div>
                <div className="modal-body">
                <Form>
                    <FormGroup row className="align-items-center">
                        <i className="fa fa-square center" style={{color: this.props.colors[0]}} sm={2}></i>
                        <Col sm={10}>
                        <Input type="number" placeholder={this.placeHolders[0]} name = "firstRange"  onChange={(e)=>{this.handleChange(e)}} invalid={this.state.invalidInputs[0]}/>
                        <FormFeedback invalid>You must put a value between {this.placeHolders[0]}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                    <i className="fa fa-square" style={{color: this.props.colors[1]}} sm={2}></i>
                    <Col sm={10}>
                        <Input type="number" placeholder={this.placeHolders[1]} name = "secondRange" onChange={(e)=>{this.handleChange(e)}} invalid={this.state.invalidInputs[1]}/>
                        <FormFeedback>You must select a value between {this.placeHolders[1]}</FormFeedback>
                    </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                    <i className="fa fa-square" style={{color: this.props.colors[2]}} sm={2}></i>
                    <Col sm={10}>
                        <Input type="number" placeholder={this.placeHolders[2]} name = "thirdRange" onChange={(e)=>{this.handleChange(e)}} invalid={this.state.invalidInputs[2]}/>
                        <FormFeedback>You must select a value between {this.placeHolders[2]}</FormFeedback>
                    </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                        <i className="fa fa-square" style={{color: this.props.colors[3]}} sm={2}></i>
                        <Col sm={10}>
                        <Input type="number" placeholder={this.placeHolders[3]} name = "fourthRange" onChange={(e)=>{this.handleChange(e)}} invalid={this.state.invalidInputs[3]}/>
                        <FormFeedback>You must select a value between {this.placeHolders[3]}</FormFeedback>
                        </Col>
                    </FormGroup>
              </Form>
              <div className="col-12">
              <Button
                    className="btn btn-success container"
                    data-dismiss="modal"
                    type="button"
                    disabled= {this.buttonDisabled()}
                    onClick={() => this.saveButtonTapped()}>
                      Aceptar
                      </Button>
              </div>
            </div>
            </Modal>
        )
    }
}

export default ModalInputs;