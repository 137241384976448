import firebase, {firestore} from 'firebase';

export var getCentroide = function(coords) {
var minLat=100000;
var minLng=100000;
var maxLat=-100000;
var maxLng=-100000;

coords.forEach(function(coord){
	if(coord.latitude>-90 && coord.latitude<90)
	{
		if (coord.latitude>maxLat) {maxLat=coord.latitude;}
		if (coord.latitude<minLat) {minLat=coord.latitude;}
	}
	if(coord.longitude>-180 && coord.longitude<180)
	{
		if (coord.longitude>maxLng) {maxLng=coord.longitude;}
		if (coord.longitude<minLng) {minLng=coord.longitude;}
	}
});
return new firebase.firestore.GeoPoint((minLat+maxLat)/2, (minLng+maxLng)/2) 
};