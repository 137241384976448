import React, {Fragment, useEffect, useState} from 'react'
import {Polygon, Popup} from 'react-leaflet';

const BackPolygons = (props) => {

    const { field } = props;

    const getPolygons = (data) => {

        const {coords} = data;
        let result = [];
        for (let i = 0; i < coords.length; i++ ) {
            let coord = coords[i];
            let temp = [];
            temp[0] = coord.latitude;
            temp[1] = coord.longitude;

            result.push(temp);
        }

        return result;
    };
    let polygons = field['polygons']
    if (polygons) {
        const polygonComponents = polygons.map((polygon, index) => (
            <Polygon key={index} positions={getPolygons(polygon)}>
                <Popup>{polygon.name} : Existing polygons</Popup>
            </Polygon>
        ));
        return    <Fragment>{polygonComponents}</Fragment>
    }
    return <Fragment>{ }</Fragment>
};

export default BackPolygons;