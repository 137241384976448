import React, { Component } from "react";
import { FormGroup, Modal, Button, Table } from "reactstrap";
import { Log } from '../../Models/Logs.js'
import Select from "react-select";
import ApplicationForm from './Forms/ApplicationForm.js';
import FieldDataForm from './Forms/FieldDataForm.js';
import FertilizationForm from './Forms/FertilizationForm.js';
import GenericDateAndSelectForm from './Forms/GenericDateAndSelectForm.js';
import CreateAndEditSeedForm from './Forms/CreateAndEditSeedForm.js';
import YieldForm from './Forms/YieldForm.js';

class CreateLogForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            polygonsArray: [],
            showApplicationModal: false,
            showFieldDataModal: false,
            showFertilizationModal: false,
            showGenericDateAndSelectModal: false,
            showYieldForm: false,
            showSeedFormData: false,
            selectedLogType: null,
        }

        this.isPolygonChecked = this.isPolygonChecked.bind(this);
        this.onAddPolygon = this.onAddPolygon.bind(this);
        this.clearState = this.clearState.bind(this);
        this.fillDataTapped = this.fillDataTapped.bind(this);
        this.createLogData = {};
        this.logTypes = Log.getLogTypes();
        this.closeLogModal = this.closeLogModal.bind(this);
    }

    componentWillReceiveProps() {
        this.state = {
            polygonsArray: [],
            showApplicationModal: false,
            showFieldDataModal: false,
            showFertilizationModal: false,
            showGenericDateAndSelectModal: false,
            showYieldForm: false,
            showSeedFormData: false,
            selectedLogType: null,
        }
        this.createLogData = {}
    }

    onAddPolygon(polygon) {
        if (polygon.value) {
            if (this.isPolygonChecked(polygon)) {
                delete this.createLogData[polygon.value]
                this.setState({})
            } else {
                this.createLogData[polygon.value] = {}
                this.setState({})
            }
        }
    };

    isPolygonChecked(polygon) {
        if (polygon.value in this.createLogData) {
            return true
        }
        return false
    };

    clearState() {
        this.setState({
            polygonsArray: [],
            showApplicationModal: false,
            showFieldDataModal: false,
            showGenericDateAndSelectModal: false,
            showYieldForm: false,
            showSeedFormData: false,
            showFertilizationModal: false
        })
        this.createLogData = {}
    }

    onCloseButtonTapped(success) {
        this.clearState()
        if (success) {
            this.props.onCreateSuccess()
        } else {
            this.props.onClose()
        }
    }

    changeLogType = (value) => {
        this.setState({
            selectedLogType: value,
            showFieldDataModal: false,
            showApplicationModal: false,
            showGenericDateAndSelectModal: false,
            showYieldForm: false,
            showSeedFormData: false,
            showFertilizationModal: false,
        })
    }

    fillDataTapped() {
        if (this.state.selectedLogType && Object.keys(this.createLogData).length > 0) {
            switch (this.state.selectedLogType.value) {
                case "app_log":
                    this.setState({
                        showApplicationModal: true,
                        showFieldDataModal: false,
                        showGenericDateAndSelectModal: false,
                        showSeedFormData: false,
                        showYieldForm: false,
                        showFertilizationModal: false
                    })
                    return
                case "field_log":
                    this.setState({
                        showApplicationModal: false,
                        showFieldDataModal: true,
                        showGenericDateAndSelectModal: false,
                        showSeedFormData: false,
                        showYieldForm: false,
                        showFertilizationModal: false
                    })
                    return
                case "fertilization":
                    this.setState({
                        showApplicationModal: false,
                        showFieldDataModal: false,
                        showGenericDateAndSelectModal: false,
                        showSeedFormData: false,
                        showYieldForm: false,
                        showFertilizationModal: true
                    })
                    return
                case "water":
                case "phenology":
                case "lab_analytic":
                    this.setState({
                        showApplicationModal: false,
                        showFieldDataModal: false,
                        showGenericDateAndSelectModal: true,
                        showSeedFormData: false,
                        showYieldForm: false,
                        showFertilizationModal: false
                    })
                    return
                case "seeding":
                    this.setState({
                        showApplicationModal: false,
                        showFieldDataModal: false,
                        showGenericDateAndSelectModal: false,
                        showSeedFormData: true,
                        showYieldForm: false,
                        showFertilizationModal: false
                    })
                    return
                case "yield":
                    this.setState({
                        showApplicationModal: false,
                        showFieldDataModal: false,
                        showGenericDateAndSelectModal: false,
                        showSeedFormData: false,
                        showYieldForm: true,
                        showFertilizationModal: false
                    })
                    return
                default:
                    break;
            }
        }
    }

    closeLogModal(success) {
        if (this.state.selectedLogType && Object.keys(this.createLogData).length > 0) {
            this.setState({
                showApplicationModal: false,
                showFieldDataModal: false,
                showGenericDateAndSelectModal: false,
                showSeedFormData: false,
                showYieldForm: false,
                showFertilizationModal: false
            }, () => {
                if (success) {
                    this.props.onCreateSuccess()
                }
            })
            return
        }
    }

    getSelectedLogType() {
        if (this.state.selectedLogType) {
            return this.state.selectedLogType.value
        }
        return null
    }

    render() {

        const fieldLogModal = <FieldDataForm
            isOpen={this.state.showFieldDataModal}
            selectedPolygons={this.createLogData}
            fieldId={this.props.fieldId}
            onClose={this.closeLogModal}
        >
        </FieldDataForm>;

        const appLogModal = <ApplicationForm
            isOpen={this.state.showApplicationModal}
            selectedPolygons={this.createLogData}
            fieldId={this.props.fieldId}
            onClose={this.closeLogModal}
        >
        </ApplicationForm>;

        const fertilizationModal = <FertilizationForm
            isOpen={this.state.showFertilizationModal}
            selectedPolygons={this.createLogData}
            fieldId={this.props.fieldId}
            onClose={this.closeLogModal}
        >
        </FertilizationForm>;

        const genericDateAndSelectModal = <GenericDateAndSelectForm
            isOpen={this.state.showGenericDateAndSelectModal}
            selectedPolygons={this.createLogData}
            fieldId={this.props.fieldId}
            onClose={this.closeLogModal}
            type={this.getSelectedLogType()}
        >
        </GenericDateAndSelectForm>;

        const seedForm = <CreateAndEditSeedForm
            isOpen={this.state.showSeedFormData}
            selectedPolygons={this.createLogData}
            fieldId={this.props.fieldId}
            onClose={this.closeLogModal}
        >
        </CreateAndEditSeedForm>

        const yieldForm = <YieldForm
            isOpen={this.state.showYieldForm}
            selectedPolygons={this.createLogData}
            fieldId={this.props.fieldId}
            onClose={this.closeLogModal}
        >
        </YieldForm>

        return (
            <>
                {
                    appLogModal
                }
                {
                    fieldLogModal

                }
                {
                    fertilizationModal

                }
                {
                    genericDateAndSelectModal
                }
                {
                    seedForm
                }
                {
                    yieldForm
                }
                <Modal
                    isOpen={this.props.isOpen}
                >
                    <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <h4 className="title title-up">Agregar registro</h4>
                    </div>
                    <div className="modal-body">
                        <br />
                        <label className="text-center">Elija los polígonos a los que quiere ingresar un nuevo registro</label>
                        <br />
                        <div style={{ height: '200px' }}>
                            <Table responsive hover={true} className="detail-table">
                                <thead className="text-primary">
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th>Polígono</th>
                                        <th>Agregar registro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.polygons && this.props.polygons.length > 0 ? this.props.polygons.map((polygon, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{polygon.label}</td>
                                                <td className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        readOnly checked={this.isPolygonChecked(polygon)}
                                                        onClick={() => this.onAddPolygon(polygon)} />
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: 'center', fontSize: '1.8em' }}>No hay polígonos en esta chacra.</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <FormGroup>
                                <label>Tipo de registro</label>
                                <Select
                                    name="logType"
                                    className="react-select"
                                    placeholder="Seleccione un tipo de registro"
                                    classNamePrefix="react-select"
                                    value={this.state.selectedLogType}
                                    onChange={(value) => this.changeLogType(value)}
                                    options={this.logTypes}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    {
                        <div className="modal-footer">
                            <div className="left-side">
                                <Button
                                    className="btn-link"
                                    color="danger"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.onCloseButtonTapped(false)}
                                >
                                    Cancelar
                                </Button>
                            </div>
                            <div className="divider" />
                            <div className="right-side">
                                <Button
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                    onClick={() => this.fillDataTapped()}
                                >Ingresar datos</Button>
                            </div>
                        </div>
                    }
                </Modal>
            </>
        );
    }
}
export default CreateLogForm;