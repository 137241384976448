import '../../assets/css/Chip.css';
import React, { Component } from "react";

class Chip extends Component {
    render() {
        return (<div class="chip">
            {
                this.props.onChange ? <input type="number" id={this.props.id} value={this.props.value} onChange={this.props.onChange} class= "input"/> : null
            }
            <p class = "inputLabel" for = {this.props.id}> {this.props.label} </p>
            <span class="closebtn" id={this.props.id} onClick={this.props.onDeleteTapped}>&times;</span>
        </div>)
    }
}
export default Chip;