import React, { useEffect, useState } from 'react';
import { FaLeaf } from 'react-icons/fa';
import { firestore } from "firebase";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import { CardBody, CardHeader, CardTitle } from "reactstrap";
import * as moment from 'moment'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import ReactDatetime from "react-datetime";
import { Chart, Line } from 'react-chartjs-2';
import Select from "react-select";

class TermicWidget extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentpolygon: null,
      polygondata: null,
      chartdata: null,
      phenologyDates: [],
      date1: moment().subtract(30, 'days'),
      date2: moment(),
    }
    console.log("E1AA", this.state);
  }

  update_poly = async () => {
    this.setState({ chartdata: {} })
    let data = await firestore().collection(this.props.url + "/polygons/" + this.state.currentpolygon.value + "/climate_data").get();
    let metadata = await firestore()
      .collection(this.props.url + "/polygons/" + this.state.currentpolygon.value + "/logs")
      .where("type", "==", "seeding")
      .orderBy("date", "desc")
      .limit(1)
      .get();
    let metadataJson = metadata.docs.length > 0 ? metadata.docs[0].data() : {};
    let phenologyData = await firestore()
      .collection(this.props.url + "/polygons/" + this.state.currentpolygon.value + "/logs")
      .where("type", "==", "phenology")
      .get()
    var phenologyDatesAux = [];
    phenologyData.docs.map((item, index) => {
      let data = item.data();
      if (data.date) {
        phenologyDatesAux.push({date: data.date, type: data.value})
      }
    })
    this.setState({ polydata: data, polysowing: moment(metadataJson["date"].seconds * 1000), phenologyDates: phenologyDatesAux });
    this.update();
    console.log("E2", this.state);
  }
  update() {
    var labels = [];
    var max = [];
    var mean = [];
    var min = [];
    var rad = [];




    this.state.polydata.docs.map((item, index) => {

      if (moment(item.id).isBetween(this.state.date1, this.state.date2)) {
        let datos = item.data();
        labels.push(datos.date);
        max.push(datos["tmaxsat"].toFixed(2));
        min.push(datos["tminsat"].toFixed(2));
        mean.push(datos["tsat"].toFixed(2));
        rad.push(datos["rad"].toFixed(2));
      }
    })

    var data = {
      labels: labels,
      datasets: [
        {
          label: 'T Min C',
          data: min,
          fill: false,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgba(54, 162, 235, 0.2)',

          yAxisID: 'y-axis-1',
        },
        {
          label: 'T Med C',
          data: mean,
          fill: false,
          backgroundColor: 'rgb(90, 150, 132)',
          borderColor: 'rgba(180, 200, 132, 0.2)',
          yAxisID: 'y-axis-1',
        },
        {
          label: 'T Max C',
          data: max,
          fill: false,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgba(255, 99, 132, 0.2)',
          yAxisID: 'y-axis-1',
        },
        {
          label: 'Rad',
          data: rad,
          fill: false,
          backgroundColor: 'rgb(200, 200,0)',
          borderColor: 'rgba(255, 200, 0, 0.2)',
          yAxisID: 'y-axis-2',
        }
      ],
    }
    const options = {
      scales: {
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            scaleLabel: { display: true, labelString: "Temperatura (C)" }
          },
          {
            labelString: "Radiación (W/m2)",
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            scaleLabel: { display: true, labelString: "Radiación (MJ/m2)" },
            gridLines: {
              drawOnArea: false,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: { display: true, labelString: "Fecha" }
          }
        ]

      },

    }
    console.log("E3", this.state);
    this.setState({ chartdata: data, options: options })



  }
  componentDidMount() {
    console.log("E1A", this.state);

    if (this.props.polygons.length > 0) {
      let polygons = this.props.polygons;

      const fetchIndexData = async () => {

        var db = []
        for (let i = 0; i < polygons.length; i++) {
          let polygon = polygons[i];
          db.push({ label: polygon.name, value: polygon.id })
        }
        this.setState({ polygons: db, currentpolygon: { label: db[0].label, value: db[0].value } }, this.update_poly);
        console.log("E1", this.state);
      };
      let vm = this;
      fetchIndexData();
      Chart.pluginService.register({
        afterDraw: function (chart, easing) {

          if (chart.scales['y-axis-1'] && vm.state.phenologyDates.length > 0) {
            var ctx = chart.ctx;

            var xaxis = chart.scales['x-axis-0'];
            var yaxis = chart.scales['y-axis-1'];
            const topY = yaxis.top;
            const bottomY = yaxis.bottom;

            for (let i = 0; i < vm.state.phenologyDates.length; i++) {
              let dateValue = vm.state.phenologyDates[i].date.seconds * 1000;
              let type = vm.state.phenologyDates[i].type;
              let phenologyDate = moment(dateValue).format("YYYY-MM-DD")
              const x = xaxis.getPixelForValue(phenologyDate);
              console.log(phenologyDate, x);
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, topY + 24);
              ctx.lineTo(x, bottomY);
              ctx.lineWidth = 2;
              ctx.strokeStyle = 'green';
              ctx.stroke();

              var verticalReference = "";
              if (type == "primordium") {
                verticalReference = "P"
              } else if (type == "emergence") {
                verticalReference = "E"
              } else if (type == "flowering") {
                verticalReference = "F"
              }
              
              ctx.textAlign = 'center';
              ctx.fillStyle = 'green';
              ctx.fillText(verticalReference, x, topY + 10);

              ctx.restore();
            }
          }
        }
      });
      console.log("E1B", this.state);
    }
  }


  render() {

    return (
      <>
        {
          this.state.chartdata !== null && this.state.currentpolygon ?
            <Row>
              <Col lg={12} md={12} sm={12}>
                <br></br>
                <Card>

                  <CardBody>
                    <h6>Meteorología</h6>
                    <Row >
                      <Col lg={4} md={4} sm={4}>
                        <ReactDatetime
                          timeFormat={false}
                          value={this.state.date1}
                          onChange={(val) => {
                            if (val) {
                              this.setState({
                                date1: val
                              });
                              this.update_poly();
                            }
                          }}
                          inputProps={{
                            className: "form-control",
                            placeholder: "Desde",
                          }}
                        />
                      </Col>
                      <Col lg={4} md={4} sm={4}>
                        <ReactDatetime
                          timeFormat={false}
                          value={this.state.date2}
                          onChange={(val) => {
                            if (val) {
                              this.setState({
                                date2: val
                              });
                              this.update_poly();
                            }
                          }}
                          inputProps={{
                            className: "form-control",
                            placeholder: "Hasta",
                          }}
                        /> </Col>
                      <Col lg={4} md={4} sm={4}>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name=""
                          value={this.state.currentpolygon}
                          onChange={(value) => {
                            this.setState({ currentpolygon: value });
                            this.update_poly();
                          }}
                          options={this.state.polygons}
                          placeholder="Chacra"
                        /></Col></Row><br></br>
                    <Row >
                      <Line data={this.state.chartdata} options={this.state.options} /></Row>

                  </CardBody>
                </Card>
              </Col>

            </Row>

            :
            null

        }
      </>

    )
  }


};

export default TermicWidget;



