import React, { Component } from "react";
import { Row, Modal, Button, Col, Table } from "reactstrap";
import { firestore } from 'firebase';
import CircularProgress from "@material-ui/core/CircularProgress";
import SeedForm from "./SeedForm.js";

class ReUseRanch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            reuseArray: [],
            showSeedForm: false,
            selectedPolygonId: null,
            showConfirmationModal: false
        }
        this.closeSeedForm = this.closeSeedForm.bind(this);
        this.onReUsePolygon = this.onReUsePolygon.bind(this);
        this.isPolygonChecked = this.isPolygonChecked.bind(this);
        this.clearState = this.clearState.bind(this);
        this.ranchSeedData = {}
    }

    componentWillReceiveProps() {
        this.state = {
            loading: false,
            reuseArray: [],
            showSeedForm: false
        }
    }

    onReUsePolygon(polygon) {
        if (polygon.id) {
            if (this.isPolygonChecked(polygon)) {
                delete this.ranchSeedData[polygon.id]
                this.setState({})
            } else {
                this.ranchSeedData[polygon.id] = {}
                this.setState({})
            }
        }
    };

    isPolygonChecked(polygon) {
        if (polygon.id in this.ranchSeedData) {
            return true
        }
        return false
    }

    showConfirmationModal() {
        this.setState({
            showConfirmationModal: true
        })
    }

    closeConfirmationModal() {
        this.setState({
            showConfirmationModal: false
        })
    }

    showSeedForm() {
        this.setState({
            showSeedForm: true
        })
    }

    saveButtonTapped() {
        this.setState({
            showConfirmationModal: false,
            loading: true
        })
        let vm = this;
        for (let i = 0; i < this.props.polygons.length; i++) {
            let polygon = this.props.polygons[i]
            if (this.isPolygonChecked(polygon)) {
                let logsUrl = "fields/" + this.props.fieldId + "/polygons/" + polygon.id + "/logs"
                firestore()
                    .collection(logsUrl)
                    .add(this.ranchSeedData[polygon.id])
                    .then(function () {
                        if (i === vm.props.polygons.length - 1) {
                            vm.setState({
                                loading: false
                            })
                            vm.onCloseButtonTapped(true)
                        }
                    })
            } else {
                let polygonUrl = "fields/" + this.props.fieldId + "/polygons/"
                firestore()
                    .collection(polygonUrl)
                    .doc(polygon.id)
                    .set({
                        deleted: true
                    }, { merge: true })
                    .then(function () {
                        if (i === vm.props.polygons.length - 1) {
                            vm.setState({
                                loading: false
                            })
                            vm.onCloseButtonTapped(true)
                        }
                    }).catch(function (error) { });
            }
        }
    }

    seedFormPolygonData = polygonSeedLogData => {
        this.closeSeedForm()
        if (polygonSeedLogData) {
            for (var key in this.ranchSeedData) {
                this.ranchSeedData[key] = polygonSeedLogData
            }
            this.showConfirmationModal()
        }
    }

    closeSeedForm() {
        this.setState({ showSeedForm: false })
    }

    clearState() {
        this.setState({
            loading: false,
            reuseArray: [],
            showSeedForm: false,
            selectedPolygonId: null,
        })
        this.ranchSeedData = {}
    }

    onCloseButtonTapped(success) {
        this.clearState()
        if (success) {
            this.props.onUpdateSuccess()
        } else {
            this.props.onClose()
        }
    }


    render() {

        const confirmModal = (
            <Modal
                isOpen={this.state.showConfirmationModal}
            >
                <div className="modal-header justify-content-center">
                    <h4 className="title title-up">Confirmar operación</h4>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col sm={12}>
                            <label>Desea confirmar la operación?</label>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <div className="left-side">
                        <Button
                            className="btn-link"
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.closeConfirmationModal()}
                        >
                            No
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                            className={"btn-link"}
                            color="primary"
                            type="button"
                            onClick={() => { this.saveButtonTapped() }}
                        >
                            Si
                        </Button>
                    </div>
                </div>
            </Modal>
        );

        return (
            <>
                {
                    confirmModal
                }
                <Modal
                    isOpen={this.props.isOpen}
                >
                    <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <h4 className="title title-up">Reutilizar chacra</h4>
                    </div>
                    <div className="modal-body">
                        <br />
                        <label className="text-center">Elija los polígonos que quiere reutilizar en la próxima zafra haciendo click en el checkbox. Los que deje sin marcar no serán tenidos en cuenta. Luego haga click en ingresar datos para ingresar la nueva info para cada polígono</label>
                        <br />
                        <div style={{ height: '300px' }}>
                            <Table responsive hover={true} className="detail-table">
                                <thead className="text-primary">
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th>Polígono</th>
                                        <th>Reutilizar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.polygons && this.props.polygons.length > 0 ? this.props.polygons.map((polygon, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{polygon.name}</td>
                                                <td className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        readOnly checked={this.isPolygonChecked(polygon)}
                                                        onClick={(e) => this.onReUsePolygon(polygon)} />
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: 'center', fontSize: '1.8em' }}>No hay polígonos en esta chacra.</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {
                        !this.state.loading ? <div className="modal-footer">
                            <div className="left-side">
                                <Button
                                    className="btn-link"
                                    color="danger"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.onCloseButtonTapped()}
                                >
                                    Cancelar
                                </Button>
                            </div>
                            <div className="divider" />
                            <div className="right-side">
                                <Button
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                    onClick={() => this.showSeedForm()}
                                >Ingresar datos</Button>
                            </div>
                        </div>
                            :
                            <div className="text-center py-4">
                                <CircularProgress color="secondary" />
                            </div>
                    }
                    <SeedForm
                        isOpen={this.state.showSeedForm}
                        onClose={this.closeSeedForm}
                        onSaveTapped={this.seedFormPolygonData}
                    >
                    </SeedForm>
                </Modal>
            </>
        );
    }
}

export default ReUseRanch;