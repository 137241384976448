import React, { Component } from "react";
import { Row, FormGroup, FormFeedback, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from 'firebase';
import CircularProgress from "@material-ui/core/CircularProgress";

class PerformanceForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            harvest_date: null,
            performanceValue: null,
            performanceInvalid: false,
            loading: false,
        }
    }

    handlePerformanceChange(e) {
        var performanceValue = parseFloat(e.target.value, 10)
        if (!performanceValue || performanceValue <= 0) {
            this.setState({
                performanceInvalid: true
            })
        } else {
            this.setState({
                performanceValue: performanceValue,
                performanceInvalid: false
            })
        }
    }

    saveButtonTapped() {
        this.setState({
            loading: true
        })
        let performanceData = {};
        performanceData.date = this.state.harvest_date
        performanceData.value = this.state.performanceValue
        performanceData.type = "yield"
        firestore()
            .collection(this.props.performanceUrl)
            .add(performanceData)
            .then(() => {
                this.clearState()
                this.props.onClose()
                this.props.onSaveTapped(true)
            })
            .catch((error) => {
                this.clearState()
                this.props.onClose()
                this.props.onSaveTapped(false)
            })
    }

    clearState() {
        this.setState({
            harvest_date: null,
            performanceValue: null,
            performanceInvalid: null,
            loading: false
        })
    }

    closeTapped() {
        this.clearState()
        this.props.onClose()
    }

    buttonDisabled() {
        return !this.state.harvest_date || !this.state.performanceValue
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
            >
                <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <h4 className="title title-up">Rendimiento</h4>
                </div>
                <div className="modal-body">
                    <br />
                    <Row className="justify-content-center">
                        <Col sm={12} md={10} lg={8}>
                            {
                                <FormGroup>
                                    <label>Rendimiento seco y limpio (kg/ha)</label>
                                    <Input type="number" name="performance" onChange={(e) => { this.handlePerformanceChange(e) }} invalid={this.state.performanceInvalid} />
                                    <FormFeedback invalid>El valor no puede ser negativo</FormFeedback>
                                </FormGroup>
                            }
                        </Col>
                        <Col sm={12} md={10} lg={8}>
                            <FormGroup>
                                <label>Fecha de cosecha</label>
                                <ReactDatetime
                                    value={this.state.harvest_date}
                                    onChange={(val) => {
                                        if (val.toDate) {
                                            this.setState({
                                                harvest_date: val.toDate()
                                            })
                                        } else {
                                            this.setState({
                                                harvest_date: ""
                                            })
                                        }
                                    }}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Seleccione fecha de cosecha",
                                    }}
                                    timeFormat={false}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {
                    !this.state.loading ? <div className="modal-footer">
                        <div className="left-side">
                            <Button
                                className="btn-link"
                                color="danger"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.closeTapped()}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button
                                className="btn-link"
                                color="primary"
                                type="button"
                                disabled={this.buttonDisabled()}
                                onClick={() => this.saveButtonTapped()}
                            >Guardar</Button>
                        </div>
                    </div>
                        :
                        <div className="text-center py-4">
                            <CircularProgress color="secondary" />
                        </div>
                }
            </Modal>
        );
    }
}

export default PerformanceForm;