import React, { Component } from "react";
import { Row, FormGroup,FormFeedback, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import * as firebase from "firebase/app";
import { firestore } from 'firebase';
import CircularProgress from "@material-ui/core/CircularProgress";

class YieldForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            harvest_date: props.selectedLog ? props.selectedLog.date : null,
            performanceValue: props.selectedLog ? props.selectedLog.value : null,
            performanceInvalid: false,
            loading: false,
        }
    }

    handlePerformanceChange(e) {
        var performanceValue = parseFloat(e.target.value, 10)
        if (!performanceValue || performanceValue <= 0) {
            this.setState({
                performanceInvalid: true
            })
        } else {
            this.setState({
                performanceValue: performanceValue,
                performanceInvalid: false
            })
        }
    }

    saveButtonTapped() {
        let performanceData = {};
        performanceData.date = firebase.firestore.Timestamp.fromDate(new Date(this.state.harvest_date));
        performanceData.value = parseFloat(this.state.performanceValue)
        performanceData.type = "yield"
        performanceData.deleted = false

        let vm = this;
        this.setState({
            loading: true,
        })
        let polygonsIds = Object.keys(this.props.selectedPolygons)
        if (polygonsIds.length > 0 && this.props.fieldId) {
            for (let i = 0; i < polygonsIds.length; i++) {
                let polygonId = polygonsIds[i]
                let logsUrl = "fields/" + this.props.fieldId + "/polygons/" + polygonId + "/logs"
                if (this.props.selectedLog) {
                    firestore()
                        .collection(logsUrl)
                        .doc(this.props.selectedLog.id)
                        .set(performanceData, { merge: true })
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                } else {
                    firestore()
                        .collection(logsUrl)
                        .add(performanceData)
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                }
            }
        }
    }

    clearState() {
        this.setState({
            harvest_date: null,
            performanceValue: null,
            performanceInvalid: null,
            loading: false
        })
    }

    closeButtonTapped(success) {
        this.clearState()
        this.props.onClose(success)
    }

    buttonDisabled() {
        return !this.state.harvest_date || !this.state.performanceValue
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
            >
                <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <h4 className="title title-up">Rendimiento</h4>
                </div>
                <div className="modal-body">
                    <br />
                    <Row className="justify-content-center">
                        <Col sm={12} md={10} lg={8}>
                            {
                                <FormGroup>
                                    <label>Rendimiento seco y limpio (kg/ha)</label>
                                    <Input type="number" name="performance" value = {this.state.performanceValue} onChange={(e) => { this.handlePerformanceChange(e) }} invalid={this.state.performanceInvalid} />
                                    <FormFeedback invalid>El valor no puede ser negativo</FormFeedback>
                                </FormGroup>
                            }
                        </Col>
                        <Col sm={12} md={10} lg={8}>
                            <FormGroup>
                                <label>Fecha de cosecha</label>
                                <ReactDatetime
                                    value={this.state.harvest_date}
                                    onChange={(val) => {
                                        this.setState({
                                            harvest_date: val
                                        })
                                    }}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Seleccione fecha de cosecha",
                                    }}
                                    timeFormat={false}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {
                    !this.state.loading ? <div className="modal-footer">
                        <div className="left-side">
                            <Button
                                className="btn-link"
                                color="danger"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.closeButtonTapped()}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button
                                className="btn-link"
                                color="primary"
                                type="button"
                                disabled={this.buttonDisabled()}
                                onClick={() => this.saveButtonTapped()}
                            >Guardar</Button>
                        </div>
                    </div>
                        :
                        <div className="text-center py-4">
                            <CircularProgress color="secondary" />
                        </div>
                }
            </Modal>
        );
    }
}

export default YieldForm;