import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from 'firebase';
import { DataField } from '../../../Models/Logs.js'
import Chip from '../../components/Chip.js'
import CircularProgress from "@material-ui/core/CircularProgress";
import * as firebase from "firebase/app";

class FieldDataForm extends Component {

    constructor(props) {
        super(props);
        this.logTypes = DataField.getDataFieldTypes();

        var inputValueProps = null
        if (props.selectedLog) {
            if (props.selectedLog.type == "data_campo") {
                inputValueProps = props.selectedLog.value
            }
        }

        let logTypeProps = props.selectedLog ? this.logTypes.filter(item => item.value === props.selectedLog.type)[0] : null
        this.state = {
            logType: logTypeProps,
            date: props.selectedLog ? props.selectedLog.date : null,
            inputValue: inputValueProps,

            presenceArray: [],
            selectedPrecenses: [],

            //Control values

            dateValidState: true,
            logTypeState: true,
            loading: false,
        }

        this.onDeleteTapped = this.onDeleteTapped.bind(this);
        if (props.selectedLog && props.selectedLog.values && props.selectedLog.values.length > 0) {
            this.getPrecenses()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedLog) {
            var inputValueProps = null
            if (nextProps.selectedLog) {
                if (nextProps.selectedLog.type == "data_campo") {
                    inputValueProps = nextProps.selectedLog.value
                }
            }
            let logTypeProps = nextProps.selectedLog ? this.logTypes.filter(item => item.value === nextProps.selectedLog.type)[0] : null
            this.setState({
                logType: logTypeProps,
                date: nextProps.selectedLog ? nextProps.selectedLog.date : null,
                inputValue: inputValueProps,
                selectedPrecenses: [],
            }, () => {
                if (nextProps.selectedLog && nextProps.selectedLog.values && nextProps.selectedLog.values.length > 0) {
                    this.getPrecenses()
                }
            });
        }
    }

    clearStatus() {
        this.setState({
            logType: null,
            date: null,
            loading: false,
            dateValidState: true,
            logTypeState: true,
            presenceArray: [],
            selectedPrecenses: [],
            inputValue: null,
        })
    }

    isValidated = () => {

        var isValidated = true

        if (this.state.logType === null) {
            this.setState({
                logTypeState: false
            });
            isValidated = false;
        }

        if (this.state.date == null) {
            this.setState({
                dateValidState: false
            });

            if (isValidated) {
                isValidated = false;
            }
        }

        return isValidated;
    }

    closeButtonTapped(success) {
        this.clearStatus()
        this.props.onClose(success)
    }

    changeLogType = (value) => {
        this.setState({
            logType: value,
            presenceArray: [],
            selectedPrecenses: [],
            logTypeState: true
        }, () => {
            if (this.shouldShowPresenceField()) {
                this.getPrecenses()
            }
        })
    }

    async getPrecenses() {
        if (this.state.logType) {
            let presenceType = this.state.logType.value.split("_")[0] + "s"
            let presencesInfo = [];
            let vm = this;
            await firestore()
                .collection(presenceType)
                .get()
                .then(async function (presenceReference) {
                    presenceReference.docs.map((item, _) => {
                        if (item.exists === true) {
                            presencesInfo.push({ "value": item.id, "label": item.id })
                        }
                    })
                    vm.setState({
                        presenceArray: presencesInfo,
                        selectedPrecenses: vm.props.selectedLog && vm.props.selectedLog.values ? presencesInfo.filter(item =>  vm.props.selectedLog.values.indexOf(item.value) > -1)  : []
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    selectPresence(value) {
        if (this.state.selectedPrecenses.indexOf(value) <= -1) {
            this.state.selectedPrecenses.push(value)
            this.setState({})
        }
    }

    onDeleteTapped = (input) => {
        let presenceIndex = input.target.id
        if (this.state.selectedPrecenses && this.state.selectedPrecenses.length > 0 && this.state.selectedPrecenses[presenceIndex]) {
            let presence = this.state.selectedPrecenses[presenceIndex]
            let filteredArray = this.state.selectedPrecenses.filter(item => item.value !== presence.label)
            this.setState({ selectedPrecenses: filteredArray })
        }
    }

    saveButtonTapped() {
        let polygonsIds = Object.keys(this.props.selectedPolygons)
        if (this.isValidated() && polygonsIds.length > 0 && this.props.fieldId) {
            let insertData = {};
            insertData.type = this.state.logType.value
            if (this.shouldShowInputField()) {
                if (this.state.inputValue) {
                    insertData.value = parseFloat(this.state.inputValue)
                    insertData.unit = this.state.logType.unit
                    insertData.variable = this.state.logType.label
                    insertData.location = null
                    insertData.photo_url = null
                }
            } else {
                if (this.state.selectedPrecenses) {
                    insertData.value = this.state.selectedPrecenses.map((item, _) => item.value)
                }
            }
            insertData.deleted = false
            insertData.date = firebase.firestore.Timestamp.fromDate(new Date(this.state.date));;
            let vm = this;
            this.setState({
                loading: true,
            })
            for (let i = 0; i < polygonsIds.length; i++) {
                let polygonId = polygonsIds[i]
                let logsUrl = "fields/" + this.props.fieldId + "/polygons/" + polygonId + "/logs"
                if (this.props.selectedLog) {
                    firestore()
                        .collection(logsUrl)
                        .doc(this.props.selectedLog.id)
                        .set(insertData, { merge: true })
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                } else {
                    firestore()
                        .collection(logsUrl)
                        .add(insertData)
                        .then(function () {
                            if (i === polygonsIds.length - 1) {
                                vm.setState({
                                    loading: false
                                })
                                vm.closeButtonTapped(true)
                            }
                        })
                }
            }
        }
    }

    shouldShowInputField() {
        if (this.state.logType) {
            if (this.state.logType.value === "data_campo") {
                return true
            }
            return false
        }
        return false
    }

    shouldShowPresenceField() {
        if (this.state.logType) {
            if (this.state.logType.value !== "data_campo") {
                return true
            }
            return false
        }
        return false
    }

    getInputPlaceholder() {
        if (this.state.logType) {
            if (this.state.logType.value === "data_campo") {
                return `${this.state.logType.label} (${this.state.logType.unit})`
            }
            return null
        }
        return null
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
            >
                <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <h4 className="title title-up">Registro a campo</h4>
                </div>
                <div className="modal-body">
                    <br />
                    <Row className="justify-content-center">
                        <Col sm={12} md={10} lg={8}>
                            <FormGroup>
                                <label>Fecha</label>
                                <ReactDatetime
                                    value={this.state.date}
                                    onChange={(value) => {
                                        this.setState({
                                            dateValidState: true,
                                            date: value,
                                        })
                                    }}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Ingrese fecha de registro",
                                    }}
                                    timeFormat={false}
                                />
                                {this.state.dateValidState === false ? (
                                    <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                ) : null}
                            </FormGroup>
                            <div>
                                <FormGroup>
                                    <label>Tipo de registro a campo</label>
                                    <Select
                                        name="logType"
                                        className="react-select"
                                        placeholder="Seleccione tipo de registro"
                                        classNamePrefix="react-select"
                                        value={this.state.logType}
                                        onChange={(value) => this.changeLogType(value)}
                                        options={this.logTypes}
                                    />
                                    {this.state.logTypeState === false ? (
                                        <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                    ) : null}
                                </FormGroup>
                            </div>
                            {
                                this.shouldShowPresenceField() && this.state.presenceArray && this.state.presenceArray.length > 0 && <div>
                                    <FormGroup>
                                        <label>Añadir especies</label>
                                        <Select
                                            name="logType"
                                            className="react-select"
                                            placeholder="Añadir especies"
                                            classNamePrefix="react-select"
                                            value={null}
                                            onChange={(value) => this.selectPresence(value)}
                                            options={this.state.presenceArray}
                                        />
                                    </FormGroup>
                                </div>
                            }
                            <div>
                                {
                                    this.shouldShowPresenceField() && this.state.selectedPrecenses && this.state.selectedPrecenses.map((presence, index) => {
                                        return <Chip
                                            id={index}
                                            label={presence.label}
                                            onDeleteTapped={this.onDeleteTapped}
                                        >
                                        </Chip>
                                    })
                                }
                            </div>
                            {
                                this.shouldShowInputField() && <div>
                                    <FormGroup>
                                        <label>{this.getInputPlaceholder()}</label>
                                        <Input
                                            type="number"
                                            min={0}
                                            value={this.state.inputValue}
                                            onChange={(e) => this.setState({
                                                inputValue: e.target.value
                                            })}
                                        />
                                    </FormGroup>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
                {
                    !this.state.loading ? <div className="modal-footer">
                        <div className="left-side">
                            <Button
                                className="btn-link"
                                color="danger"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.closeButtonTapped(false)}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button
                                className="btn-link"
                                color="primary"
                                type="button"
                                onClick={() => this.saveButtonTapped()}
                            >Guardar</Button>
                        </div>
                    </div> : <div className="text-center py-4">
                        <CircularProgress color="secondary" />
                    </div>
                }
            </Modal>
        );
    }
}
export default FieldDataForm;