import React, { Fragment } from 'react'
import SubPolygon from "./subPolygon";
import { getCropIcon } from "./mapIcons";
import L from "leaflet";

const CustomPolygons = (props) => {

    const { field } = props;

    const getPolygons = (data) => {

        const { coords } = data;
        let result = [];
        if (coords) {
            for (let i = 0; i < coords.length; i++) {
                let coord = coords[i];
                let temp = [];
                temp[0] = coord.latitude;
                temp[1] = coord.longitude;

                result.push(temp);
            }
        }

        return result;
    };


    const getIconInfo = (crop = '') => {

        let iconUrl = "";

        if (crop === '') {
            return null;
        } else {
            iconUrl = getCropIcon(crop);
        }

        if (!iconUrl) {
            return null;
        }

        let iconInfo = L.icon({
            iconUrl: iconUrl,
            iconRetinaUrl: iconUrl,
            iconAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,

            iconSize: [20, 20],
        });

        return iconInfo;
    };

    let polygons = field['polygons']
    if (polygons) {
        const polygonComponents = polygons.map((polygon, index) => (
            <SubPolygon iconInfo={getIconInfo(polygon.crop)} onClick={() => props.onShowSidebar(polygon)}
                key={index} positions={getPolygons(polygon)} name={polygon.name}
            />
        ));
        return <Fragment>{polygonComponents}</Fragment>
    }
    return <Fragment>{ }</Fragment>
};

export default CustomPolygons;