import React, { Component, useEffect } from 'react';
import { FeatureGroup, Map, Marker, Popup, WMSTileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../../../assets/css/map.css';

import L from 'leaflet';
import area from '@turf/area';

import firebase, { firestore } from 'firebase';
import LoadingOverlay from 'react-loading-overlay';
import {
    Button,
    Card,
    CardBody,
    Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText,
    Row, Table
} from "reactstrap";
import DetailMap from "./components/detail-map";
import Modal from "reactstrap/es/Modal";
import Select from "react-select";
import ReactDatetime from "react-datetime";

import IndexChart from "./components/index-chart";
import TermicWidget from "./components/termicwidget";

import MapPolygonEdit from "./components/map-polygon-edit";
import NotificationAlert from "react-notification-alert";
import Spinner from "reactstrap/es/Spinner";

import ReUseRanch from "../../forms/ReUseRanch.js";
import Dropzone from "./components/Dropzone.js";

const cropsUrl = "crops/";
const backPolygonsColor = '#ffe913';
const createdPolygonsColor = '#d15f0d';
const drawingPolygonsColor = '#26f5fa';

class MapDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            centerLocation: { lat: -32, lng: -57 },
            zoom: 14,
            fieldInfo: {},
            polygons: [],
            fieldsUrl: '',
            currentUser: firebase.auth().currentUser,
            bounds: [],
            deleteStatus: false,
            polyIndex: 0,

            showDeleteModal: false,
            showUpdateFieldNameModal: false,

            tempFieldName: "",

            hoverNumber: -1,
            cropArr: [],
            varietyArr: [],
            fieldId: '',
            loadingTable: false,
            index_data: [],
            confirmValue: '',

            chatInfo: null,

            showPolygonDeleteModal: false,
            selectedDeletePolygonId: "",
            deletePolygonStatus: false,


            // update part
            selectedPolygon: null,
            changedCoords: [],
            initGeoJson: {
                type: "FeatureCollection",
                features: [
                ]
            },
            showUpdateModal: false,
            selectedPolygonId: '',

            // add part
            createdPolygon: null,
            createdCoords: [],
            showAddModal: false,
            showAddConfirmModal: false,

            //common of add and update
            otherPolygonsArr: [],
            polygonName: '',
            crop: null,
            variety: null,
            sowing_date: null,
            fieldList: [],

            predecesor: null,
            predecesorState: true,

            predecessorArray: [{
                "value": "ARR",
                "label": "Arroz"
            }, {
                "value": "SOJ",
                "label": "Soja"
            }, {
                "value": "PRA",
                "label": "Pradera"
            }, {
                "value": "CN",
                "label": "Campo natural"
            }, {
                "value": "LAB",
                "label": "Laboreo de verano"
            }],

            predecesorVariety: null,
            predecesorVarietyArr: [],

            isLoading: false,
            showReUseRanch: false,

            showAllPoligonsYieldWarning: false,

            showKmlModal: false,
            kmlPolygonData: [],
            polygonsArr: [],
            fromKml: false
        }
        this.closeReUseRanch = this.closeReUseRanch.bind(this);
        this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
        this.allPolygonsYield = false
    }

    onUpdateModalToggle = () => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal
        })
    };

    onAddModalToggle = () => {
        this.setState({
            showAddModal: !this.state.showAddModal
        })
    };


    onDeleteToggle = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        })
    };

    onDeletePolygonToggle = () => {
        this.setState({
            showPolygonDeleteModal: !this.state.showPolygonDeleteModal
        })
    };

    onDeleteField = () => {

        this.onDeleteToggle();
        this.setState({
            deleteStatus: true
        });

        let fieldsUrl = this.state.fieldsUrl;
        let fieldId = this.state.fieldId;

        var vm = this;

        firestore()
            .collection(fieldsUrl)
            .doc(fieldId)
            .set({
                deleted: true
            }, { merge: true }
            )
            .then(function () {
                let deletedData = {
                    fieldId: fieldId,
                    date: new Date()
                };

                firestore().collection("field_delete/" + vm.state.currentUser.uid + "/deleted_field").doc(fieldId).set(deletedData)
                    .then(function () {
                        vm.setState({
                            deleteStatus: false
                        });

                        vm.props.history.push('/admin/cropmap/map');
                    })
                    .catch(function (error) {
                        console.error("Error writing to field_delete: ", error);
                        vm.setState({
                            deleteStatus: false
                        })
                    })
            })
            .catch(function (error) {
                console.error("Error removing document: ", error);

                let deletedData = {
                    fieldId: fieldId,
                    date: new Date()
                };

                firestore().collection("field_delete/" + vm.state.currentUser.uid + "/deleted_field").doc(fieldId).set(deletedData)
                    .then(function () {
                        vm.setState({
                            deleteStatus: false
                        });

                        vm.props.history.push('/admin/cropmap/map');
                    })
                    .catch(function (error) {
                        console.error("Error writing to field_delete: ", error);
                        vm.setState({
                            deleteStatus: false
                        })
                    })
            })
    };

    setInitGeoJson = (selectedPolygon) => {

        let result = {
            type: "FeatureCollection",
            features: [
            ]
        };

        let realCoords = [];

        if (selectedPolygon) {
            let coords = selectedPolygon.coords;
            if (coords) {
                for (let i = 0; i < coords.length; i++) {
                    let tempCoord = coords[i];

                    let tempArr = [];
                    tempArr[0] = tempCoord.longitude;
                    tempArr[1] = tempCoord.latitude;

                    realCoords.push(tempArr);
                }


                if (realCoords) {
                    result = {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [realCoords]
                                }
                            }
                        ]
                    }
                }
            }
        }

        this.setState({
            changedCoords: realCoords,
            initGeoJson: result
        })
    };

    notify = (place, color, msg) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div style={{ zIndex: 10001 }}>
                    <div>{msg}</div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 4,
        };
        this.refs.notify.notificationAlert(options);
    };

    setOtherPolygonsArr = (selectedPolygonId = "", bAdd = true) => {

        let currentPolygons = this.state.polygons;
        let resultArr = [];

        console.log(currentPolygons);

        if (currentPolygons) {
            currentPolygons.map((subItem, subIndex) => {

                if (bAdd === true) {
                    let tempCoords = subItem.coords;
                    let coords = [];
                    let name = subItem.name;

                    tempCoords.map(coord => {
                        let tempArr = [];
                        tempArr[0] = coord.longitude;
                        tempArr[1] = coord.latitude;

                        coords.push(tempArr);
                    });

                    let tempPolygonInfo = {
                        name: "",
                        coordsArr: []
                    };
                    tempPolygonInfo.name = name;
                    tempPolygonInfo.coordsArr[0] = coords;

                    resultArr.push(tempPolygonInfo);
                } else {
                    if (subItem.id !== selectedPolygonId) {
                        let tempCoords = subItem.coords;
                        let coords = [];
                        let name = subItem.name;

                        tempCoords.map(coord => {
                            let tempArr = [];
                            tempArr[0] = coord.longitude;
                            tempArr[1] = coord.latitude;

                            coords.push(tempArr);
                        });

                        let tempPolygonInfo = {
                            name: "",
                            coordsArr: []
                        };
                        tempPolygonInfo.name = name;
                        tempPolygonInfo.coordsArr[0] = coords;

                        resultArr.push(tempPolygonInfo);
                    }
                }
            });
        }

        this.setState({
            otherPolygonsArr: resultArr
        })
    };

    fetchOtherFields = async () => {
        let fieldsUrl = this.state.fieldsUrl;
        let currentFieldId = this.state.fieldId;
        const fieldsOtherData = await firestore()
            .collection(fieldsUrl)
            .where("shared", "array-contains", this.state.currentUser.uid)
            .get();

        let dataInfo = [];


        fieldsOtherData.docs.map(item => {
            if (item.exists && item.id !== currentFieldId) {
                let tempData = item.data();
                if (!tempData.deleted) {
                    tempData['id'] = item.id;
                    dataInfo.push(tempData);
                }
            }
        });

        for (let i = 0; i < dataInfo.length; i++) {
            let id = dataInfo[i]['id'];
            let url = this.state.fieldsUrl + "/" + id + '/polygons';

            const polygonData = await firestore()
                .collection(url)
                .get();
            let polygons = [];
            polygonData.docs.map(item => {
                if (item.exists) {
                    let tempData = item.data();
                    if (!tempData.deleted) {
                        tempData['id'] = item.id;

                        polygons.push(tempData);
                    }
                }
            });
            dataInfo[i]['polygons'] = polygons;
        }

        this.setState({
            fieldList: dataInfo
        });
    };

    fetchPolygons = async (fieldId) => {
        let docRef = await firestore()
            .collection(this.state.fieldsUrl)
            .doc(fieldId)
            .get();

        let fieldInfo = {};

        var allPolygonsYieldAux = true

        fieldInfo = docRef.data();

        if (fieldInfo) {
            fieldInfo['id'] = fieldId;
            let polygons = [];

            let polygonUrl = this.state.fieldsUrl + "/" + fieldId + "/polygons";
            const polygonData = await firestore().collection(polygonUrl).get();


            for (let i = 0; i < polygonData.docs.length; i++) {
                let item = polygonData.docs[i];
                if (item.exists === true) {
                    let tempData = item.data();

                    if (!tempData.deleted) {
                        tempData['id'] = item.id;
                        tempData['area'] = this.getPolygonArea(tempData.coords);

                        let logs = polygonUrl + "/" + item.id + "/logs";

                        const seedLogData = await firestore()
                            .collection(logs)
                            .where("type", "==", "seeding")
                            .orderBy("date", "desc")
                            .limit(2)
                            .get();

                        if (seedLogData.docs) {
                            let seedLogJson = seedLogData.docs[0].data();
                            tempData['crop'] = seedLogJson["crop"][0];
                            tempData['variety'] = seedLogJson["crop"][1];
                            tempData['sowing_date'] = seedLogJson["date"]
                        }

                        if (allPolygonsYieldAux) {
                            const yieldLogData = await firestore()
                                .collection(logs)
                                .where("type", "==", "yield")
                                .limit(3)
                                .get();

                            if (yieldLogData.docs.length <= 0) {
                                allPolygonsYieldAux = false
                            }
                        }

                        polygons.push(tempData);

                    }
                }
            }

            this.setBounds(polygons);
            this.allPolygonsYield = allPolygonsYieldAux

            this.setState({
                fieldInfo: fieldInfo,
                polygons: polygons,
                isLoading: false
            });

            this.yearChange("ACTUAL");
        }
    };

    fetchCrops = async () => {
        const cropsData = await firestore().collection(cropsUrl).get();

        let dataInfo = [];

        cropsData.docs.map((item, index) => {
            if (item.exists === true) {
                let tempData = item.data();
                let tempArr = {};
                tempArr['value'] = item.id;
                tempArr['label'] = tempData['name'];
                dataInfo.push(tempArr);
            }
        });

        this.setState({
            cropArr: dataInfo
        });
    };

    fetchVarieties = async (value, predecesor) => {

        let url = cropsUrl + value.value + "/varieties/";
        let varietyData = await firestore().collection(url).get();

        let dataInfo = [];

        varietyData.docs.map((item, index) => {
            let tempArr = {};
            tempArr['value'] = item.id;
            tempArr['label'] = item.id;
            dataInfo.push(tempArr);
        });


        if (predecesor) {
            this.setState({
                predecesorVarietyArr: dataInfo
            });
        } else {
            this.setState({
                varietyArr: dataInfo
            });
        }
    };

    fetchTable = async () => {
        let polygons = [];
        let polygonUrl = this.state.fieldsUrl + "/" + this.state.fieldId + "/polygons";
        const polygonData = await firestore().collection(polygonUrl).get();


        for (let i = 0; i < polygonData.docs.length; i++) {
            let item = polygonData.docs[i];
            if (item.exists === true) {
                let tempData = item.data();

                if (!tempData.deleted) {
                    tempData['id'] = item.id;
                    tempData['area'] = this.getPolygonArea(tempData.coords);

                    let seedLog = polygonUrl + "/" + item.id + "/logs";

                    const seedLogData = await firestore()
                        .collection(seedLog)
                        .where("type", "==", "seeding")
                        .orderBy("date", "desc")
                        .limit(1)
                        .get();

                    if (seedLogData.docs) {
                        let seedLogJson = seedLogData.docs[0].data();
                        tempData['crop'] = seedLogJson["crop"][0];
                        tempData['variety'] = seedLogJson["crop"][1];
                        tempData['sowing_date'] = seedLogJson["date"]
                    }


                    polygons.push(tempData);

                }
            }
        }

        this.setBounds(polygons);

        this.setState({
            polygons: polygons,
            loadingTable: false
        });
    };

    componentDidMount() {

        const { match: { params } } = this.props;

        let fieldId = params.id;

        this.setState({
            fieldId: fieldId,
            isLoading: true
        });

        let timeCount = 0;

        var timeInterval = setInterval(() => {
            if (firebase.auth().currentUser == null) {
                timeCount++;
                if (timeCount > 2) {
                    this.props.history.push('/auth/login');
                }
            } else {
                this.setState({
                    currentUser: firebase.auth().currentUser,
                    fieldsUrl: `fields`,
                }, () => {
                    this.fetchOtherFields();
                    this.fetchPolygons(fieldId);
                    this.fetchCrops();
                });
                clearInterval(timeInterval);
            }
        }, 1000);
    }

    showValidateAlert = (content) => {
        let options = {};
        options = {
            place: "tc",
            message: <div style={{ zIndex: 10000 }}>
                <div>
                    {content}
                </div>
            </div>,
            type: "danger",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 5
        };

        this.refs.notify.notificationAlert(options);
    };

    processCreatedInfo = async () => {
        if (!this.state.fromKml) {
            if (!this.state.createdCoords) {
                this.showValidateAlert('Por favor dibuje el polígono.');
                return;
            }

            if (!this.state.polygonName) {
                this.showValidateAlert('Ingrese nombre del polígono.');
                return;
            }
        }

        if (!this.state.crop) {
            this.showValidateAlert('Seleccione el cultivo.');
            return;
        }

        if (!this.state.sowing_date) {
            this.showValidateAlert('Seleccione la fecha de siembra.');
            return;
        }

        if (this.state.fromKml) {
            for (let i = 0; i < this.state.polygonsArr.length; i++) {

                let kmlPolygon = this.state.polygonsArr[i]

                let coords = kmlPolygon.coordsArr;
                let realCoords = [];
                for (let j = 0; j < coords.length; j++) {
                    let tempVal = coords[j];
                    let tempStructure = new firebase.firestore.GeoPoint(tempVal[1], tempVal[0]);
                    realCoords.push(tempStructure);
                }
                let tempSowingDate = this.state.sowing_date;

                let tempDate = '';

                if (typeof tempSowingDate === 'string') {
                    tempDate = new Date(tempSowingDate);
                } else {
                    tempDate = tempSowingDate.toDate();
                }

                let polygonData = {
                    name: kmlPolygon.name,
                    coords: realCoords,
                    deleted: false,
                    tempDate: tempDate,
                };
                await this.addPolygon(polygonData);
            }
        } else {
            let coords = this.state.createdCoords;
            let realCoords = [];
            for (let j = 0; j < coords.length; j++) {
                let tempVal = coords[j];
                let tempStructure = new firebase.firestore.GeoPoint(tempVal[1], tempVal[0]);
                realCoords.push(tempStructure);
            }

            let tempSowingDate = this.state.sowing_date;

            let tempDate = '';

            if (typeof tempSowingDate === 'string') {
                tempDate = new Date(tempSowingDate);
            } else {
                tempDate = tempSowingDate.toDate();
            }

            let polygonData = {
                name: this.state.polygonName,
                coords: realCoords,
                deleted: false,
                tempDate: tempDate,
            };
            await this.addPolygon(polygonData);
        }

        this.setState({
            showAddConfirmModal: false,
            loadingTable: true
        })
        this.onAddModalToggle();
        this.fetchTable();
    };

    addPolygon = async (polygonData) => {
        var vm = this;
        await firestore()
            .collection(this.state.fieldsUrl + "/" + this.state.fieldId + "/polygons")
            .add(polygonData)
            .then(function (polygonReference) {

                let logsUrl = vm.state.fieldsUrl + "/" + vm.state.fieldId + "/polygons/" + polygonReference.id + "/logs"

                let insertData = {};

                insertData.crop = [vm.state.crop.value, vm.state.variety ? vm.state.variety.value : ''];
                insertData.date = polygonData.tempDate;
                insertData.deleted = false;
                insertData.predecesor = [vm.state.predecesor.value, vm.state.predecesorVariety ? vm.state.predecesorVariety.value : ''];
                insertData.type = "seeding";

                firestore()
                    .collection(logsUrl)
                    .add(insertData)

            })
    }

    processUpdatedInfo = async () => {
        if (!this.state.changedCoords) {
            this.showValidateAlert('Por favor dibuje el polígono');
            return;
        }

        if (!this.state.sowing_date) {
            this.showValidateAlert('Por favor ingrese fecha de siembra.');
            return;
        }

        if (!this.state.selectedPolygonId) {
            return;
        }

        var vm = this;

        let coords = this.state.changedCoords;
        let realCoords = [];
        for (let j = 0; j < coords.length; j++) {
            let tempVal = coords[j];

            let tempStructure = new firebase.firestore.GeoPoint(tempVal[1], tempVal[0]);
            realCoords.push(tempStructure);
        }

        let selectedPolygonId = this.state.selectedPolygonId;

        let tempSowingDate = this.state.sowing_date;

        let tempDate = '';

        if (typeof tempSowingDate === 'string') {
            tempDate = new Date(tempSowingDate);
        } else {
            tempDate = tempSowingDate.toDate();
        }

        let polygonData = {
            name: this.state.polygonName,
            coords: realCoords,
            deleted: false
        };

        this.setState({
            loadingTable: true
        }, async () => {
            firestore().collection(this.state.fieldsUrl + "/" + this.state.fieldId + "/polygons")
                .doc(selectedPolygonId)
                .update(polygonData)
                .then(async () => {

                    let logsUrl = vm.state.fieldsUrl + "/" + vm.state.fieldId + "/polygons/" + selectedPolygonId + "/logs"

                    let insertData = {};

                    insertData.crop = [vm.state.crop.value, vm.state.variety ? vm.state.variety.value : ''];
                    insertData.date = tempDate;
                    insertData.deleted = false;
                    insertData.type = "seeding";


                    const seedLogData = await firestore()
                        .collection(logsUrl)
                        .where("type", "==", "seeding")
                        .orderBy("date", "desc")
                        .limit(1)
                        .get();

                    if (seedLogData.docs.length > 0) {
                        let seedLogDoc = seedLogData.docs[0]
                        firestore()
                            .collection(logsUrl)
                            .doc(seedLogDoc.id)
                            .set(insertData, { merge: true })
                            .then(function () {
                                vm.fetchTable();
                            }).catch(function (error) {
                                console.log("ERROR AL UPDATEAR " + error);
                                vm.setState({
                                    loadingTable: false
                                })
                            });
                    }




                })
                .catch(function (error) {
                    console.error("Error writing document", error);
                    vm.setState({
                        loadingTable: false
                    })
                });
        });

        this.onUpdateModalToggle();
    };

    getAddMapBounds = () => {
        let left = -180;
        let top = -90;
        let right = 180;
        let bottom = 90;

        let polygons = this.state.polygons;

        let centerPosition = this.state.centerLocation;
        let result = [
            [centerPosition.lat, centerPosition.lng],
            [centerPosition.lat, centerPosition.lng]
        ];

        if (polygons.length > 0) {
            for (let j = 0; j < polygons.length; j++) {
                let coords = polygons[j].coords;

                for (let k = 0; k < coords.length; k++) {
                    let latitude = coords[k].latitude;
                    let longitude = coords[k].longitude;
                    if (latitude > top) {
                        top = coords[k].latitude;
                    }

                    if (latitude < bottom) {
                        bottom = coords[k].latitude;
                    }

                    if (longitude > left) {
                        left = longitude;
                    }

                    if (longitude < right) {
                        right = longitude;
                    }
                }
            }

            result = [
                [top, left],
                [bottom, right]
            ];
        }

        return result;
    };

    getUpdateMapBounds = () => {
        let left = -180;
        let top = -90;
        let right = 180;
        let bottom = 90;

        let polygon = this.state.selectedPolygon;
        let result = [];

        if (polygon) {
            let coords = polygon.coords;

            for (let k = 0; k < coords.length; k++) {
                let latitude = coords[k].latitude;
                let longitude = coords[k].longitude;
                if (latitude > top) {
                    top = coords[k].latitude;
                }

                if (latitude < bottom) {
                    bottom = coords[k].latitude;
                }

                if (longitude > left) {
                    left = longitude;
                }

                if (longitude < right) {
                    right = longitude;
                }
            }

            result = [
                [top, left],
                [bottom, right]
            ];
        }

        return result;
    };

    changeCrop = (value, predecesor) => {
        if (predecesor) {
            if (value.value == "ARR") {
                this.fetchVarieties(value, predecesor);
                this.setState({
                    predecesor: value,
                    predecesorState: true
                })
            } else {
                this.setState({
                    predecesor: value,
                    predecesorState: true,
                    predecesorVarietyArr: [],
                    predecesorVariety: null
                })
            }
        } else {
            this.fetchVarieties(value, predecesor);
            this.setState({
                crop: value,
                cropState: true
            })
        }
    };

    MouseHover = (index) => {
        this.setState({
            hoverNumber: index
        });
    };

    yearChange = (index) => {
        console.log("year", index);
        if (index == "ACTUAL" && this.state.polyIndex in this.state.polygons) {
            this.setState({
                zeroDate: new Date(this.state.polygons[this.state.polyIndex].sowing_date.seconds * 1000),
                endDate: new Date(),
            });
        }

    };

    MouseOut = (index) => {
        this.setState({
            hoverNumber: -1
        });
    };

    onDeletePolygonInfo = (polygon) => {

        this.setState({
            selectedDeletePolygonId: polygon.id
        }, () => {
            this.onDeletePolygonToggle();
        })
    };

    onDeletePolygon = () => {
        let selectedDeletePolygonId = this.state.selectedDeletePolygonId;

        let fieldsUrl = this.state.fieldsUrl;
        let fieldId = this.state.fieldId;

        if (selectedDeletePolygonId === "" || fieldsUrl === "" || fieldId === "") {
            this.onDeletePolygonToggle();
            return;
        }

        this.setState({
            deletePolygonStatus: true
        });

        const deletePolygon = async () => {
            let vm = this;
            let polygonUrl = fieldsUrl + "/" + fieldId + "/polygons";
            firestore()
                .collection(polygonUrl)
                .doc(selectedDeletePolygonId)
                .set({
                    deleted: true
                }, { merge: true })
                .then(function () {

                    let deletedPolygonPath = fieldId + "/polygons/" + selectedDeletePolygonId;
                    let deletedData = {
                        polygonPath: deletedPolygonPath,
                        date: new Date()
                    };

                    firestore().collection("field_delete/" + vm.state.currentUser.uid + "/deleted_polygon").doc(selectedDeletePolygonId).set(deletedData)
                        .then(function () {
                            vm.setState({
                                deletePolygonStatus: false,
                                showPolygonDeleteModal: false,
                                loadingTable: true,
                                polyIndex: 0
                            }, () => {
                                vm.fetchTable();
                            });
                        })
                        .catch(function (error) {
                            console.error("Error writing to field_delete: ", error);
                            vm.setState({
                                deletePolygonStatus: false,
                                showPolygonDeleteModal: false
                            });
                        })
                })
                .catch(function (error) {
                    console.error("Error removing document: ", error);

                    let deletedPolygonPath = fieldId + "/polygons/" + selectedDeletePolygonId;
                    let deletedData = {
                        polygonPath: deletedPolygonPath,
                        date: new Date()
                    };

                    firestore().collection(selectedDeletePolygonId).doc(fieldId).set(deletedData)
                        .then(function () {
                            vm.setState({
                                deletePolygonStatus: false,
                                showPolygonDeleteModal: false,
                                loadingTable: true,
                                polyIndex: 0
                            }, () => {
                                vm.fetchTable();
                            });
                        })
                        .catch(function (error) {
                            console.error("Error writing to field_delete: ", error);
                            vm.setState({
                                deletePolygonStatus: false,
                                showPolygonDeleteModal: false
                            });
                        })
                })
        };

        deletePolygon();

    };

    onCloseAddConfirmModal = () => {
        this.setState({
            createdCoords: [],
            createdPolygon: null,
            showAddConfirmModal: false,
            showAddModal: false
        })
    };

    onShowAddModal = () => {
        this.setState({
            polygonName: "",
            crop: null,
            variety: null,
            sowing_date: null,
            varietyArr: [],
            createdCoords: [],
            createdPolygon: null,
            showKmlModal: false,
            predecesor: null,
            predecesorVariety: null,
            predecesorVarietyArr: [],
            predecesorState: true,
            kmlPolygonData: [],
            polygonsArr: [],
            fromKml: false
        }, () => {
            this.setOtherPolygonsArr("", true);
            this.onAddModalToggle();
        });
    };

    onReUseRanchPressed = () => {
        if (this.allPolygonsYield) {
            this.setState({
                showReUseRanch: true,
            })
        } else {
            this.setState({
                showAllPoligonsYieldWarning: true,
            })
        }
    };

    onCloseYieldAlert = () => {
        this.setState({
            showAllPoligonsYieldWarning: false,
        })
    };



    onShowAddConfirmModalToggle = () => {
        this.setState({
            showAddConfirmModal: !this.state.showAddConfirmModal
        });
    };

    getPolygonArea = (polygonArr) => {

        let path = [];
        let result = 0;
        if (polygonArr) {
            for (let i = 0; i < polygonArr.length; i++) {
                let tempItem = [];
                tempItem[0] = polygonArr[i].latitude;
                tempItem[1] = polygonArr[i].longitude;

                path.push(tempItem);
            }

            let tempPolygon = L.polygon(path);
            let sqMeters = area(tempPolygon.toGeoJSON());
            result = (sqMeters / 10000).toFixed(1);
        }

        return result + " has.";
    };

    onShowUpdateModal = (polygon = null) => {
        let tempPolygonId = polygon.id;
        let tempPolygonName = polygon.name;

        var tempCrop = null;
        if (polygon.crop && this.state.cropArr.length > 0) {
            tempCrop = this.state.cropArr.filter(crop => crop.value == polygon.crop).pop();
        }

        let tempVariety = null;
        if (polygon.variety) {
            tempVariety = {
                value: polygon.variety,
                label: polygon.variety
            }
        }

        let tempSowingDate = null;
        if (polygon.sowing_date) {
            let date = new Date(polygon.sowing_date.seconds * 1000);
            let month = date.getMonth() > 8 ? date.getMonth() + 1 : ('0' + (date.getMonth() + 1));
            let day = date.getDate() > 9 ? date.getDate() : ('0' + (date.getDate()));
            let year = date.getFullYear();

            tempSowingDate = month + '/' + day + "/" + year;
        }

        this.setState({
            selectedPolygonId: tempPolygonId,
            selectedPolygon: polygon,
            polygonName: tempPolygonName,
            crop: tempCrop,
            variety: tempVariety,
            sowing_date: tempSowingDate
        }, () => {
            this.fetchVarieties(tempCrop);
            this.setOtherPolygonsArr(tempPolygonId, false);
            this.setInitGeoJson(polygon)
            this.onUpdateModalToggle();
        })
    };

    onCreatedPolygon = (geoJson) => {

        if (geoJson.features) {

            let tempGeoJson = [];

            let temp = geoJson.features[0].geometry;

            if (temp.type === 'Polygon' && temp.coordinates) {
                tempGeoJson = temp.coordinates[0];
            }

            this.setState({
                createdCoords: tempGeoJson
            }, () => {
                this.onShowAddConfirmModalToggle()
            })
        }
    };

    onUpdatedPolygon = (geoJson) => {

        if (geoJson.features) {

            let tempGeoJson = [];

            let temp = geoJson.features[0].geometry;

            if (temp.type === 'Polygon' && temp.coordinates) {
                tempGeoJson = temp.coordinates[0];
            }

            this.setState({
                changedCoords: tempGeoJson
            })
        }
    };

    setBounds = (polygons) => {
        let left = -180;
        let top = -90;
        let right = 180;
        let bottom = 90;

        let result = [
            [this.state.centerLocation.lng, this.state.centerLocation.lat],
            [this.state.centerLocation.lng, this.state.centerLocation.lat],
        ];

        if (polygons) {
            for (let j = 0; j < polygons.length; j++) {
                let coords = polygons[j].coords;

                if (coords) {
                    for (let k = 0; k < coords.length; k++) {
                        if (top === null) {
                            top = coords[0].latitude;
                        }
                        if (bottom === null) {
                            bottom = coords[0].latitude;
                        }

                        if (right === null) {
                            right = coords[0].longitude;
                        }

                        if (left === null) {
                            left = coords[0].longitude;
                        }

                        let latitude = coords[k].latitude;
                        let longitude = coords[k].longitude;
                        if (latitude > top) {
                            top = coords[k].latitude;
                        }

                        if (latitude < bottom) {
                            bottom = coords[k].latitude;
                        }

                        if (longitude > left) {
                            left = longitude;
                        }

                        if (longitude < right) {
                            right = longitude;
                        }
                    }
                }
            }

            result = [
                [top, left],
                [bottom, right]
            ]
        }

        this.setState({
            bounds: result
        })
    };

    onUpdateFieldName = () => {

        this.setState({
            showUpdateFieldNameModal: false
        });

        let fieldsUrl = this.state.fieldsUrl;
        let fieldId = this.state.fieldId;

        var vm = this;

        firestore().collection(fieldsUrl).doc(fieldId).set({
            name: this.state.tempFieldName
        }, { merge: true })
            .then(function () {
                vm.notify('tc', 2, "Actualizado!");
                vm.setState({
                    tempFieldName: "",
                    fieldInfo: {
                        ...vm.state.fieldInfo,
                        name: vm.state.tempFieldName
                    }
                })
            })
            .catch(function (error) {
                vm.notify('tc', 4, "Error!");
                console.error("Error removing document: ", error);
            })
    };

    closeReUseRanch() {
        this.setState({
            showReUseRanch: false
        })
    }

    onUpdateSuccess() {
        this.closeReUseRanch()
        this.fetchTable()
    }

    onDrawKmlData = () => {
        let kmlPolygonData = this.state.kmlPolygonData;
        let currentPolygonArr = this.state.polygonsArr;

        kmlPolygonData.map((item, index) => {
            let polygons = item.polygons;

            polygons.map((subItem, subIndex) => {
                let tempCoords = subItem.coords;
                let coords = [];
                let name = subItem.name;

                tempCoords.map(coord => {
                    let tempArr = [];
                    tempArr[0] = coord.lng;
                    tempArr[1] = coord.lat;

                    coords.push(tempArr);
                });

                let tempPolygonInfo = {};
                tempPolygonInfo.name = name;
                tempPolygonInfo.coordsArr = coords;

                currentPolygonArr.push(tempPolygonInfo);
            });
        });

        this.setState(() => ({
            polygonsArr: currentPolygonArr,
            showAddConfirmModal: true,
            fromKml: true
        }), () => {
            this.setState({
                coordsArr: [],
                showKmlModal: false,
                polygonName: "",
                kmlPolygonData: [],
            });
        })
    };

    onAddKmlPolygon = (polygonInfo) => {

        let kmlPolygonData = this.state.kmlPolygonData;

        let bOk = true;
        for (let i = 0; i < kmlPolygonData.length; i++) {
            if (kmlPolygonData[i].fileName === polygonInfo.fileName) {
                bOk = false;
                break;
            }
        }

        if (bOk === true) {
            kmlPolygonData.push(polygonInfo);
            this.setState({
                kmlPolygonData: kmlPolygonData
            })
        }
    };

    onRemoveKmlPolygon = (fileName) => {

        let kmlPolygonData = this.state.kmlPolygonData;

        const index = kmlPolygonData.findIndex(e => e.fileName === fileName);

        kmlPolygonData.splice(index, 1);

        this.setState({
            kmlPolygonData: kmlPolygonData
        })
    };

    onKmlToggle = () => {
        this.setState({
            showKmlModal: !this.state.showKmlModal
        })
    };

    render() {
        const { centerLocation, zoom } = this.state;

        const polygonDeleteModal = (
            <Modal
                isOpen={this.state.showPolygonDeleteModal}
                toggle={() => this.onDeletePolygonToggle()}
            >
                <div className="modal-header justify-content-center">
                    <h5 className="title title-up">Confirma eliminar el polígono?</h5>
                </div>
                <div className="modal-footer">
                    <div className="left-side">
                        <Button
                            className={this.state.deletePolygonStatus === true ? "btn-link disabled" : "btn-link"}
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.onDeletePolygonToggle()}
                        >
                            No
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                            color="primary"
                            className={this.state.deletePolygonStatus === true ? "btn-link container disabled" : "btn-link"}
                            type="button"
                            onClick={() => { this.onDeletePolygon() }}
                        >
                            Si
                        </Button>
                    </div>
                </div>
            </Modal>
        );

        const updateFieldNameModal = (
            <Modal
                isOpen={this.state.showUpdateFieldNameModal}
                toggle={() => {
                    this.setState({
                        showUpdateFieldNameModal: false
                    })
                }}
            >
                <div className="modal-header justify-content-center">
                    <h4 className="title title-up">Renombrar chacra</h4>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col sm={12}>
                            <Input placeholder="Field name ..." type="text"
                                value={this.state.tempFieldName}
                                onChange={(e) =>
                                    this.setState({
                                        tempFieldName: e.target.value
                                    })
                                } />
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <div className="left-side">
                        <Button
                            className="btn-link"
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => {
                                this.setState({
                                    tempFieldName: "",
                                    showUpdateFieldNameModal: false
                                })
                            }}
                        >
                            Cancelar
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                            className={this.state.tempFieldName !== "" ? "btn-link" : "btn-link disabled"}
                            color="primary"
                            type="button"
                            onClick={() => { this.onUpdateFieldName() }}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            </Modal>
        );

        const deleteModal = (
            <Modal
                isOpen={this.state.showDeleteModal}
                toggle={() => this.onDeleteToggle()}
            >
                <div className="modal-header justify-content-center">
                    <h4 className="title title-up">Confirmar eliminar</h4>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col sm={12}>
                            <label>Para confirmar la eliminación, escriba <span style={{ color: '#ff592a', fontWeight: 'bold', cursor: 'auto' }}>{this.state.fieldInfo.name}</span> (nombre de chacra).</label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="nc-icon nc-air-baloon" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Confirme nombre de chacra..." type="text"
                                    value={this.state.confirmValue}
                                    onChange={(e) =>
                                        this.setState({
                                            confirmValue: e.target.value
                                        })
                                    } />
                            </InputGroup>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <div className="left-side">
                        <Button
                            className="btn-link"
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.onDeleteToggle()}
                        >
                            No
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                            className={this.state.confirmValue === this.state.fieldInfo.name ? "btn-link" : "btn-link disabled"}
                            color="primary"
                            type="button"
                            onClick={() => { this.onDeleteField() }}
                        >
                            Si
                        </Button>
                    </div>
                </div>
            </Modal>
        );

        const polygonAddModal = (<Modal
            isOpen={this.state.showAddModal}
            toggle={() => this.onAddModalToggle()}
            keyboard={false}
            style={{ maxWidth: '1200px', transform: 'translate(0, 0)' }}
        >
            <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <h4 className="title title-up">Dibuje nuevo polígono</h4>
            </div>
            <div className="modal-body">
                <MapPolygonEdit
                    center={this.state.centerLocation}
                    zoom={12}
                    onChange={this.onCreatedPolygon}
                    bounds={this.getAddMapBounds()}
                    fieldList={this.state.fieldList}
                    addStatus={true}
                    backPolygonsColor={backPolygonsColor}
                    createdList={this.state.otherPolygonsArr}
                    createdPolygonsColor={createdPolygonsColor}
                    drawingPolygonsColor={drawingPolygonsColor}
                />
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.onAddModalToggle()}
                    >
                        Cancelar
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="btn-link"
                        color="primary"
                        type="button"
                        onClick={() => this.onKmlToggle()}
                    >
                        <span className="btn-label">
                            <i className="nc-icon nc-simple-add" />
                        </span>
                        Importar KML
                    </Button>
                </div>
            </div>
        </Modal>);

        const yieldModal = (
            <Modal
                isOpen={this.state.showAllPoligonsYieldWarning}
            >
                <div className="modal-header justify-content-center">
                    <h4 className="title title-up">Atención</h4>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col sm={12}>
                            <label className="text-center">Para poder reutilizar la chacra primero debe agregar el rendimiento de cada uno de los polígonos desde el Dashboard o la aplicación móvil.</label>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer" style={{justifyContent: 'center', paddingTop: '8px', paddingBottom: '8px'}}>
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        style = {{width: '150px'}}
                        onClick={() => this.onCloseYieldAlert()}
                    >
                        Aceptar
                    </Button>
                </div>
            </Modal>
        );

        const polygonAddConfirmModal = (<Modal
            isOpen={this.state.showAddConfirmModal}
            toggle={() => this.onShowAddConfirmModalToggle()}
            keyboard={false}
        >


            <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <h4 className="title title-up">Ingrese los datos del polígono</h4>

            </div>
            <div className="modal-body">
                <NotificationAlert ref="notificationAlert" />
                <Row className="justify-content-center">
                    {
                        !this.state.fromKml && <Col sm={12} md={10} lg={8}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="nc-icon nc-air-baloon" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Nombre del polígono" type="text"
                                    value={this.state.polygonName}
                                    onChange={(e) =>
                                        this.setState({
                                            polygonName: e.target.value
                                        })
                                    } />
                            </InputGroup>
                        </Col>
                    }
                    <Col sm={12} md={10} lg={8}>
                        {
                            this.state.cropArr.length > 0 &&
                            <FormGroup>
                                <label>Cultivo</label>
                                <Select
                                    name="crop"
                                    className="react-select"
                                    placeholder="Seleccione cultivo"
                                    classNamePrefix="react-select"
                                    value={this.state.crop}
                                    onChange={(value) => this.changeCrop(value, false)}
                                    options={this.state.cropArr}
                                />
                            </FormGroup>
                        }
                    </Col>
                    <Col sm={12} md={10} lg={8}>
                        {
                            this.state.varietyArr.length > 0 &&
                            <FormGroup>
                                <label>Variedad</label>
                                <Select
                                    name="variety"
                                    className="react-select"
                                    placeholder="Seleccione variedad"
                                    classNamePrefix="react-select"
                                    value={this.state.variety}
                                    onChange={(value) => this.setState({
                                        variety: value
                                    })}
                                    options={this.state.varietyArr}
                                />
                            </FormGroup>
                        }
                    </Col>
                    <Col sm="8">
                        {
                            this.state.predecessorArray.length > 0 &&
                            <FormGroup>
                                <label>Antecesor</label>
                                <Select
                                    name="crop"
                                    className="react-select"
                                    placeholder="Seleccionar cultivo"
                                    classNamePrefix="react-select"
                                    value={this.state.predecesor}
                                    onChange={(value) => this.changeCrop(value, true)}
                                    options={this.state.predecessorArray}

                                />
                                {this.state.predecesorState === false ? (
                                    <label style={{ color: '#ff0000' }}>Campo requerido</label>
                                ) : null}
                            </FormGroup>
                        }
                    </Col>
                    <Col sm="8">
                        {
                            this.state.predecesorVarietyArr.length > 0 &&
                            <FormGroup>
                                <label>Variedad antecesor</label>
                                <Select
                                    name="variety"
                                    className="react-select"
                                    placeholder="Seleccionar variedad"
                                    classNamePrefix="react-select"
                                    value={this.state.predecesorVariety}
                                    onChange={(value) => this.setState({
                                        predecesorVariety: value
                                    })}
                                    options={this.state.predecesorVarietyArr}
                                />
                            </FormGroup>
                        }
                    </Col>
                    <Col sm={12} md={10} lg={8}>
                        <FormGroup>
                            <label>Fecha de siembra</label>
                            <ReactDatetime
                                value={this.state.sowing_date}
                                onChange={(value) => {
                                    this.setState({
                                        sowing_date: value,
                                    })
                                }}
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Ingrese fecha de siembra",
                                }}
                                timeFormat={false}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.onCloseAddConfirmModal()}
                    >
                        Cancelar
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="btn-link"
                        color="primary"
                        type="button"
                        onClick={() => this.processCreatedInfo()}
                    >
                        Guardar
                    </Button>
                </div>
            </div>
        </Modal>);

        const polygonUpdateModal = (<Modal
            isOpen={this.state.showUpdateModal}
            toggle={() => this.onUpdateModalToggle()}
            keyboard={false}
            style={{ maxWidth: '1200px', transform: 'translate(0, 0)' }}
        >
            <div className="modal-header justify-content-center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <h4 className="title title-up">Modificar polígono</h4>

            </div>
            <div className="modal-body">
                <NotificationAlert ref="notificationAlert" />
                <MapPolygonEdit
                    center={this.state.centerLocation}
                    zoom={12}
                    initGeoJson={this.state.initGeoJson}
                    onChange={this.onUpdatedPolygon}
                    bounds={this.getUpdateMapBounds()}
                    fieldList={this.state.fieldList}
                    addStatus={false}
                    backPolygonsColor={backPolygonsColor}
                    createdList={this.state.otherPolygonsArr}
                    createdPolygonsColor={createdPolygonsColor}
                    drawingPolygonsColor={drawingPolygonsColor}
                />
                <br />
                <Row className="justify-content-center">
                    <Col sm={12} md={10} lg={8}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-air-baloon" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Nombre del polígono..." type="text"
                                value={this.state.polygonName}
                                onChange={(e) =>
                                    this.setState({
                                        polygonName: e.target.value
                                    })
                                } />
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={10} lg={8}>
                        {
                            this.state.cropArr.length > 0 &&
                            <FormGroup>
                                <label>Cultivo</label>
                                <Select
                                    name="crop"
                                    className="react-select"
                                    placeholder="Seleccione cultivo"
                                    classNamePrefix="react-select"
                                    value={this.state.crop}
                                    onChange={(value) => this.changeCrop(value, false)}
                                    options={this.state.cropArr}
                                />
                            </FormGroup>
                        }
                    </Col>
                    <Col sm={12} md={10} lg={8}>
                        {
                            this.state.varietyArr.length > 0 &&
                            <FormGroup>
                                <label>Variedad</label>
                                <Select
                                    name="variety"
                                    className="react-select"
                                    placeholder="Seleccione variedad"
                                    classNamePrefix="react-select"
                                    value={this.state.variety}
                                    onChange={(value) => this.setState({
                                        variety: value
                                    })}
                                    options={this.state.varietyArr}
                                />
                            </FormGroup>
                        }
                    </Col>
                    <Col sm={12} md={10} lg={8}>
                        <FormGroup>
                            <label>Fecha de siembra</label>
                            <ReactDatetime
                                value={this.state.sowing_date}
                                onChange={(value) => {
                                    this.setState({
                                        sowing_date: value,
                                    })
                                }}
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Seleccione fecha de siembra",
                                }}
                                timeFormat={false}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.onUpdateModalToggle()}
                    >
                        Cancelar
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="btn-link"
                        color="primary"
                        type="button"
                        onClick={() => this.processUpdatedInfo()}
                    >

                        Guardar

                    </Button>
                </div>
            </div>
        </Modal>);

        const polygonTables = (
            <LoadingOverlay style={{ height: '100%' }} active={this.state.loadingTable} spinner>
                <Table responsive hover={true} className="detail-table">
                    <thead className="text-primary">
                        <tr>
                            <th className="text-center">#</th>
                            <th>Polígono</th>
                            <th>Cultivo</th>
                            <th>Variedad</th>
                            <th>Area</th>
                            <th>Fecha de siembra</th>
                            <th className="text-center">Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.polygons && this.state.polygons.length > 0 ? this.state.polygons.map((polygon, index) => (
                                <tr key={index} onMouseOver={() => this.MouseHover(index)} onClick={() => this.setState({ polyIndex: index })} onMouseOut={() => this.MouseOut(index)}>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{polygon.name}</td>
                                    <td>{polygon.crop}</td>
                                    <td>{polygon.variety}</td>
                                    <td>{polygon.area}</td>
                                    <td>{polygon.sowing_date && new Date(polygon.sowing_date.seconds * 1000).toLocaleDateString()}</td>
                                    <td className="text-center">
                                        <Button
                                            className="btn-icon"
                                            color="success"
                                            size="sm"
                                            title={"Edit"}
                                            type="button"
                                            onClick={() => this.onShowUpdateModal(polygon)}

                                        >
                                            <i className="fa fa-edit" />
                                        </Button>
                                        <Button
                                            className="btn-icon"
                                            color="danger"
                                            size="sm"
                                            title={"Delete"}
                                            type="button"
                                            onClick={() => this.onDeletePolygonInfo(polygon)}

                                        >
                                            <i className="fa fa-trash-o" />
                                        </Button>{" "}
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="6" style={{ textAlign: 'center', fontSize: '1.8em' }}>No hay polígonos en esta chacra.</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </LoadingOverlay>
        );

        const polygonKmlModal = (<Modal
            isOpen={this.state.showKmlModal}
            backdrop={false}
            keyboard={false}
        >
            <div className="modal-header justify-content-center">
                <h4 className="title title-up">Importar polígonos desde KML</h4>
            </div>
            <div className="modal-body">
                <Dropzone
                    onAddKmlPolygon={this.onAddKmlPolygon}
                    onRemoveKmlPolygon={this.onRemoveKmlPolygon}
                />
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.onKmlToggle()}
                    >
                        Cancelar
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className={this.state.kmlPolygonData.length > 0 ? 'btn-link' : 'btn-link disabled'}
                        color="primary"
                        type="button"
                        onClick={() => { this.onDrawKmlData() }}
                    >
                        Importar
                    </Button>
                </div>
            </div>
        </Modal>);

        return (
            <>
                {
                    polygonAddModal
                }
                {
                    polygonAddConfirmModal
                }
                {
                    polygonUpdateModal
                }
                {
                    deleteModal
                }
                {
                    updateFieldNameModal
                }
                {
                    polygonDeleteModal
                }
                {
                    yieldModal
                }
                {
                    polygonKmlModal
                }
                <NotificationAlert ref="notify" />


                <div className="content">
                    {
                        !this.state.isLoading ? <div >
                            {
                                this.state.fieldInfo.name &&
                                <Row>
                                    <Col lg="10" md="9" sm="6" xs="12">
                                        <Card className="card-stats">
                                            <CardBody style={{ padding: '15px 15px 10px 15px' }}>
                                                <label onClick={() => {
                                                    this.setState({
                                                        tempFieldName: this.state.fieldInfo.name,
                                                        showUpdateFieldNameModal: true
                                                    })
                                                }} className="polygon-name-label" title="Click to update name.">{this.state.fieldInfo.name}</label>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col >
                                        <Button
                                            className="btn-btn btn-success btn-sm btn-block"
                                            color="success"
                                            title={"Índices"}
                                            type="button"
                                            href={this.state.fieldId && `/admin/indexmap/${this.state.fieldId}`}
                                        >
                                            <i className="fi flaticon-land" />
                                            Índices</Button>
                                        <Button
                                            style={{ marginLeft: "0px" }}
                                            className="btn-success btn-sm btn-block"
                                            color="success"
                                            title={"Indices"}
                                            type="button"
                                            disabled={this.state.polygons.length <= 0}
                                            onClick={() => this.onReUseRanchPressed()}
                                        >
                                            <i className="fi flaticon-leaf-1" />
                                            Reutilizar chacra</Button>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col lg={3} md={6} sm={12}>
                                    {
                                        this.state.bounds.length > 0 &&
                                        <Card className="card-stats">
                                            <CardBody style={{ height: '300px', padding: '8px' }}>
                                                <DetailMap bounds={this.state.bounds}
                                                    center={centerLocation}
                                                    zoom={zoom}
                                                    hoverNumber={this.state.hoverNumber}
                                                    polygons={this.state.polygons}
                                                />
                                            </CardBody>
                                        </Card>
                                    }
                                </Col>
                                <Col lg={9} md={6} sm={12}>
                                    {
                                        this.state.fieldInfo.name &&
                                        <Card className="card-stats">
                                            <CardBody style={{ height: '300px' }}>
                                                {polygonTables}
                                            </CardBody>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12}>
                                    {
                                        this.state.polygons.length > 0 &&
                                        <Card className="h-100 card-stats">
                                            <CardBody style={{ height: '300px' }}>
                                                <LoadingOverlay active={this.state.loadingTable} spinner>
                                                    <h6>Zafras</h6>
                                                    <Table responsive hover={true}>
                                                        <thead className="text-primary">
                                                            <tr>
                                                                <th>Año</th>
                                                                <th>Cultivo</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr key={new Date(this.state.polygons[this.state.polyIndex].sowing_date.seconds * 1000).getFullYear()} onClick={() => this.yearChange("ACTUAL")}>
                                                                <td>{new Date(this.state.polygons[this.state.polyIndex].sowing_date.seconds * 1000).getFullYear()}</td>
                                                                <td>{this.state.polygons[this.state.polyIndex].crop}</td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </LoadingOverlay>
                                            </CardBody>
                                        </Card>
                                    }
                                </Col>
                                <Col lg={9} md={6} sm={12}>
                                    {
                                        this.state.zeroDate != null &&
                                        <IndexChart
                                            fieldsUrl={this.state.fieldsUrl}
                                            fieldId={this.state.fieldId}
                                            polygons={this.state.polygons}
                                            zeroDate={this.state.zeroDate}
                                            endDate={this.state.endDate}
                                        />
                                    }
                                </Col>
                            </Row>

                            {
                                this.state.polygons.length > 0 &&
                                <TermicWidget polygons={this.state.polygons} url={this.state.fieldsUrl + "/" + this.state.fieldId} />
                            }

                            {
                                this.state.bounds.length > 0 &&
                                <Row className="align-items-center justify-content-center">
                                    <Col sm={3}>
                                        <Button
                                            onClick={() => this.onShowAddModal()}
                                            className="btn-round btn btn btn-info"
                                            style={{ float: 'right' }}
                                            color="info">
                                            <span className="btn-label">
                                                <i className="fa fa-plus" />
                                            </span>
                                            Crear polígono
                                        </Button>
                                    </Col>
                                    <Col sm={3}>
                                        <Button
                                            onClick={() => this.onDeleteToggle()}
                                            className={this.state.deleteStatus === true ? "btn-round  btn disabled" : "btn-round btn"}
                                            color="danger">
                                            {
                                                this.state.deleteStatus === true ? "Eliminando ..." : "Eliminar chacra"
                                            }
                                        </Button>
                                    </Col>

                                </Row>
                            }
                        </div> : <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                            <Spinner style={{ marginTop: '100px' }} animation="border" variant={"primary"}>
                            </Spinner>
                        </div>
                    }
                </div>
                <ReUseRanch
                    isOpen={this.state.showReUseRanch}
                    onClose={this.closeReUseRanch}
                    onUpdateSuccess={this.onUpdateSuccess}
                    polygons={this.state.polygons}
                    fieldId={this.state.fieldId}
                >
                </ReUseRanch>
            </>
        );
    }
}

export default MapDetail;
