/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

import { Button } from "reactstrap";
import HorizontalTimeline from '../timeline/Components/HorizontalTimeline';


class FixedPluginH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: "dropdown show",
      value: 0, previous: 0, initial: this.props.initial
    };
    this.handleClick = this.handleClick.bind(this);

  }
  handleClick() {
    if (this.state.classes === "dropdown") {
      this.setState({ classes: "dropdown show" });
    } else {
      this.setState({ classes: "dropdown" });
    }
  }
  render() {
     if(this.props.initial!=this.state.initial){
        this.setState({value: this.props.initial, initial: this.props.initial})
      }
    return (
      <div className="fixed-plugin-h">
        <div className={this.state.classes}>
          <ul className="dropdown-menu dropdown-h show">  {
           this.props.fechas.length > 0 && <li className="header-timeline" >
                  <HorizontalTimeline
                  index={this.state.value}
                  indexClick={(index) => {
                    this.props.handleFecha([index,this.props.fechas[index]]);
                    this.setState({ value: index, previous: this.state.value });
                  }}
                  values={ this.props.fechas } />
            </li>
            }
          </ul>
        </div>
      </div>
    );
  }
}

export default FixedPluginH;
