import React, {Component} from 'react';
import {FeatureGroup, Map,  WMSTileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import firebase from 'firebase';
import {config} from "../../config";
import CustomPolygons from "../../components/Maps/customPolygons";


firebase.initializeApp(config);

class MapPart extends Component{

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        let nextVal = JSON.stringify(nextProps.bounds);
        let currentVal = JSON.stringify(this.props.bounds);
        if (nextVal === currentVal) {
            return false;
        }

        return true;
    }

    render() {

        const PolygonComponents = this.props.fieldList.map((field, index) => (
            <FeatureGroup key={index} color={field.color ? field.color : 'lime'}>
                <CustomPolygons field={field} onShowSidebar={(polygon) => this.props.onShowSidebar(polygon, field)}/>
            </FeatureGroup>
        ));

        return (
            <Map animate={true} bounds={this.props.bounds} boundsOptions={{padding : [0, 0]}} style={{width : '100vw', height : '100vh'}} center={this.props.center} zoom={this.props.zoom}>
                <WMSTileLayer
                    layers='ortofoto_nacional'
                    url="https://mapas.ide.uy/WMS_Uruguay_IDEuy_Ortofoto_CoberturaNacional"
                />

                {
                    PolygonComponents
                }
            </Map>
        );
    }
}

export default MapPart;
